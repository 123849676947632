import * as React from 'react';
import { Card, CardActions, CardContent, CardHeader, Popover, IconButton, Tooltip } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import classes from 'classnames';
import css from './tile.module.scss';
import { ITileProps } from './interfaces/ITileProps';

export const Tile: React.FC<ITileProps> = (props) => {
  const { title, description, avatarChip, actions, children, className, bottomContent, bottomContentClassName } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleAppTileActionClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleAppTileActionClose = () => {
    setAnchorEl(null);
  };

  const action = !!actions && (
    <>
      <IconButton onClick={handleAppTileActionClick}>
        <MoreVert />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleAppTileActionClose}
        onClick={handleAppTileActionClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {actions}
      </Popover>
    </>
  );

  return (
    <div className={classes(css.tile)}>
      <Card className={classes(css.tileCard, className)}>
        {(avatarChip || actions) && (
          <CardHeader className={css.header} avatar={avatarChip} action={action}></CardHeader>
        )}
        <CardContent className={css.tileContent}>
          {title && <h4 className={css.title}>{title}</h4>}
          {description &&
            <Tooltip title={description}>
              <div className={css.desc}>
                {description}
              </div>
            </Tooltip>
          }
          {children}
        </CardContent>
        {bottomContent && (
          <CardActions className={classes(css.tileActions, bottomContentClassName)}>{bottomContent}</CardActions>
        )}
      </Card>
    </div>
  );
};
