import * as React from 'react';
import classes from 'classnames';
import { Card, CardContent } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import css from './action-tile.module.scss';
import { IActionTileProps } from './interfaces/IActionTileProps';

export const ActionTile: React.FC<IActionTileProps> = (props) => {
  return (
    <div className={classes(css.tile, { [css.standalone]: props.standalone === true })}>
      <Card className={classes(css.tileCard, props.className)}>
        <CardContent className={css.tileContent} onClick={props.action}>
          <div className={css.plusIcon}>
            <AddIcon />
          </div>
          <div className={css.label}>{props.label}</div>
        </CardContent>
      </Card>
    </div>
  );
};
