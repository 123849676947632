import * as React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { devLibrarySideMenuBuilder } from 'common/utils/devLibrarySideMenuBuilder';
import { IUser } from 'common/interfaces/IUser';
import { TLayoutType } from 'components/AppFrame/interfaces/TLayoutType';
import AppFrame from 'components/AppFrame';
import css from 'components/AppFrame/app-frame.module.scss';

const WhatIsFHIR: React.FC = (props) => {
  const history = useHistory();
  const currentUser = useSelector((state) => state.auth.user) as IUser;

  const layoutType: TLayoutType =
    currentUser?.user_type === 'developer'
      ? 'developerPortal'
      : currentUser?.user_type === 'payer'
      ? 'payerPortal'
      : 'adminPortal';

  const sideMenuItems = devLibrarySideMenuBuilder({ active: 'fhir', history });

  return (
    <AppFrame layoutType={layoutType} selectedMenuItem="DocLibrary" subMenuItems={sideMenuItems}>
      <Grid container>
        <Grid item xs={12}>
          <h2>What is FHIR?</h2>
          <p>
            Fast Healthcare Interoperability Resource (FHIR) is a next-generation framework for leveraging Health Level
            7 International (HL7) standards. HL7 is an accredited standards organization dedicated to providing a
            comprehensive framework and related standards for exchange of electronic health information. FHIR is a
            standard for electronic Healthcare Information Exchange (HIE) which takes advantage of the latest web
            standards with a focus on implementation by using REST. It was created because patient records are more
            commonly digitized, and it is now apparent there is a need for structure and standardization of data across
            the healthcare spectrum. FHIR can be used as a standalone data exchange standard but can also be used in
            partnership with existing widely used standards.
          </p>
          <hr className={css.docDivider} />
          <h2>Goal of FHIR</h2>
          <p>
            The goal of FHIR is to build a base set of resources that, when by themselves and combined, satisfy most of
            the common Use Cases regarding patients and their health. They define the information contents and structure
            for the core information that is shared by most implementations.
          </p>
          <hr className={css.docDivider} />
          <h2>Resources as Building Blocks</h2>
          <p>
            A resource is the basic building block required to create FHIR data. All the exchangeable content is defined
            as a resource and all resources share the following common characteristics:
          </p>
          <ul>
            <li>A common way to define and represent them.</li>
            <li>Building them from data types that define common reusable patterns of elements.</li>
            <li>A common set of metadata e.g., data that describes and gives information about the data.</li>
            <li>A human readable part.</li>
          </ul>
          <hr className={css.docDivider} />
          <h2>Standardizing Base Resources</h2>
          <p>
            The philosophy behind FHIR is to build a base set of resources that, either by themselves or when combined,
            satisfy most common use cases regarding patients and their health. Then, FHIR standardizes a base set of
            resources which can satisfy most of the common use cases. FHIR implements use cases using resource
            references for a specific purpose, type of data exchanged, and rules for interactions between people and
            systems.
          </p>
          <p>
            The Capability Statement and Structure Definition are two special resources used to describe how FHIR
            resources are defined and used.
          </p>
          <ul>
            <li>Capability Statement. The interfaces that an implementation exposes for exchange of data.</li>
            <li>
              Structure Definition. The additional rules that serve to constrain the optionality, cardinality,
              terminology bindings, data types, and extensions defined in the used resources
            </li>
          </ul>
          <p>The FHIR specification is broken up into a set of modules as noted below.</p>
          <ul>
            <li>Foundation. The basic definitional infrastructure on which the rest of the specification is built.</li>
            <li>Implementer Support. Services to help implementers make use of the specification.</li>
            <li>
              Security &amp; Privacy. Documentation and services to create and maintain security, integrity and privacy.
            </li>
            <li>Conformance. How to test conformance to the specification and define implementation guides.</li>
            <li>Terminology. Use and support of terminologies and related artifacts.</li>
            <li>Linked Data. Defined methods of exchange for resources</li>
            <li>
              Administration. Basic resources for tracking patients, practitioners, organizations, devices, substances,
              etc.
            </li>
            <li>
              Clinical. Core clinical content such as problems, allergies, and the care process i.e., care plans,
              referrals, etc.
            </li>
            <li>Medications. Medication management and immunization tracking.</li>
            <li>Diagnostics. Observations, Diagnostic reports and requests and related content</li>
            <li>Workflow. Managing the process of care, and technical artifacts to do with obligation management.</li>
            <li>Financial. Billing and Claiming support</li>
            <li>Clinical Reasoning. Clinical Decision Support and Quality Measures</li>
          </ul>
          <hr className={css.docDivider} />
          <h2>Additional Resources</h2>
          <p>
            Visit HL7 FHIR for more information at:{' '}
            <a href="https://www.hl7.org/fhir/overview.html" target="_blank">
              https://www.hl7.org/fhir/overview.html
            </a>
          </p>
        </Grid>
      </Grid>
    </AppFrame>
  );
};

export default WhatIsFHIR;
