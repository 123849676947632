import * as yup from 'yup';
import { mPhone, mPostalCode } from 'common/matchers';
import { IDemographicsRequest } from 'views/Registration/interfaces/IDemographicsRequest';

export const initialQuestionsValidationSchema = (includeRecovery) => yup.object<IDemographicsRequest>({
  first_name: yup.string().required('First Name is required.'),
  last_name: yup.string().required('Last Name is required.'),
  date_of_birth: yup.date().required('Date of Birth is required.'),
  ssn_4: yup.string().required('SSN last 4 is required.'),
  gender: yup.string().required('Gender is required.'),
  street: yup.string().required('Street is required.'),
  city: yup.string().required('City is required.'),
  state: yup.string().required('State is required.'),
  zip_code: yup.string().matches(mPostalCode, 'ZIP Code is not valid').required('ZIP Code is required.'),
  phone: yup.string().matches(mPhone, 'Phone number is not valid').required('Phone is required.'),
  email: yup.string().trim().email('Email is not valid').required('Email is required.'),
  recovery_question: includeRecovery ? yup.string().required('Recovery question is required.') : undefined,
  recovery_answer: includeRecovery ? yup.string().required('Recovery answer is required.') : undefined
});

export default initialQuestionsValidationSchema;
