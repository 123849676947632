import * as React from 'react';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { Grid, FormHelperText, CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Formik, ErrorMessage } from 'formik';
import { CheckCircle, Error } from '@material-ui/icons';
import { Button } from 'components/controls/Button';
import { completeRegistration, setRegistrationError } from 'views/Registration/redux/actions';
import appCSS from 'components/AppFrame/app-frame.module.scss';
import css from 'views/Registration/registration.module.scss';
import { IIdentityResultProps } from 'views/Registration/interfaces/IIdentityResultProps';
import { INewAccount } from 'views/Registration/interfaces/INewAccount';
import { newAccountValidationSchema } from 'views/Registration/schemas/newAccountValidationSchema';
import HelpDeskContact from '../HelpDeskContact';

const IdentityResult: React.FC<IIdentityResultProps> = ({ session, org, submitResponse }) => {
  const dispatch = useDispatch();
  const [registrationComplete, setRegistrationComplete] = React.useState(false);
  const [loginConflict, setLoginConflict] = React.useState(false);
  const [passwordIssue, setPasswordIssue] = React.useState(false);

  const initialValues: INewAccount = {
    email: session.demographics?.email,
    confirm_email: '',
    password: '',
    confirm_password: '',
    demographics: { ...session.demographics, ck: submitResponse.ck },
  };

  const handleSubmit = (values: INewAccount, setSubmitting: Function) => {
    setSubmitting(true);
    dispatch(completeRegistration(values)).then((resp) => {
      if (resp.error) {
        if (resp.error.message === 'LoginConflict') {
          setLoginConflict(true);
          setSubmitting(false);
        } else if (resp.error.message === 'PasswordError') {
          setPasswordIssue(true);
          setSubmitting(false);
        } else {
          dispatch(setRegistrationError({ session_id: session.session_id, code: resp.error.message }));
        }
      } else {
        setRegistrationComplete(true);
      }
    });
  };

  return (
    <Grid container>
      {submitResponse.status === 'ACCEPT' && (
        <>
          <Grid item xs={12} className="alignTextCenter">
            <CheckCircle className={css.acceptIcon} />
            <h2>Thank you, now let's complete your registration</h2>
          </Grid>
          <Grid item xs={12}>
            <address>
              <strong>
                {initialValues.demographics?.first_name} {initialValues.demographics?.last_name}
              </strong>
              <br />
              {initialValues.demographics?.street} {initialValues.demographics?.street2}
              <br />
              {initialValues.demographics?.city}, {initialValues.demographics?.state}{' '}
              {initialValues.demographics?.zip_code}
              <br />
              {initialValues.demographics?.phone}
              <br />
              {initialValues.demographics?.email}
            </address>
          </Grid>
          <Grid item xs={12}>
            <hr />
          </Grid>
          <Grid item xs={12}>
            <Formik
              initialValues={initialValues}
              enableReinitialize={true}
              onSubmit={(values, { setSubmitting }) => handleSubmit(values, setSubmitting)}
              validationSchema={newAccountValidationSchema(org.feature_enable_user_registration_email_validation)}
            >
              {({ values, handleChange, handleBlur, handleSubmit, isSubmitting }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    {isSubmitting ? (
                      <Grid container>
                        <Grid item xs={12}>
                          {registrationComplete && (
                            <div className="alignTextCenter">
                              <h3>Your account has been successfully created and you can now log in.</h3>
                            </div>
                          )}
                          {!registrationComplete && (
                            <h4 className="alignTextCenter">
                              <CircularProgress size={'8rem'} />
                              <br /> Creating your account...
                            </h4>
                          )}
                        </Grid>
                      </Grid>
                    ) : (
                      <>
                        {loginConflict && (
                          <Grid item xs={12}>
                            <Alert severity={'error'}>
                              There is already someone with that email in our system.
                              <HelpDeskContact org={org} />
                            </Alert>
                          </Grid>
                        )}

                        {org.feature_enable_user_registration_email_validation === true ? (
                          <>
                            <Grid item xs={12} className={appCSS.formRow}>
                              <label>Your Email Address</label>
                              <input
                                name="email"
                                type="email"
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <ErrorMessage name="email">
                                {(msg) => <div className="input-feedback">{msg}</div>}
                              </ErrorMessage>
                            </Grid>
                            <Grid item xs={12} className={appCSS.formRow}>
                              <label className={appCSS.passwordLabel} htmlFor="confirm_password">
                                Confirm Email Address
                              </label>
                              <input
                                name="confirm_email"
                                placeholder="Confirm email address"
                                type="email"
                                value={values.confirm_email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <ErrorMessage name="confirm_email">
                                {(msg) => <div className="input-feedback">{msg}</div>}
                              </ErrorMessage>
                            </Grid>
                          </>
                        ) : (
                          <Grid item xs={12} className={appCSS.formRow}>
                            <label>Your Email Address</label>
                            <p className={css.newAccountEmailLabel}>{initialValues.demographics?.email}</p>
                          </Grid>
                        )}

                        {passwordIssue && (
                          <Grid item xs={12}>
                            <Alert severity={'error'}>
                              Password requirements were not met. It must be at least 8 characters, a lowercase letter,
                              an uppercase letter, a number, no parts of your username. Your password cannot be any of
                              your last 4 passwords.
                            </Alert>
                          </Grid>
                        )}
                        <Grid item xs={12} className={appCSS.formRow}>
                          <label className={appCSS.passwordLabel} htmlFor="password">
                            Create Password
                          </label>
                          <input
                            name="password"
                            className={appCSS.passwordInput}
                            placeholder="Enter password"
                            type="password"
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <FormHelperText className={appCSS.passwordRequirementsText}>
                            Password requirements: at least 8 characters, a lowercase letter, an uppercase letter, a
                            number, no parts of your username.
                          </FormHelperText>
                          <ErrorMessage name="password">
                            {(msg) => <div className="input-feedback">{msg}</div>}
                          </ErrorMessage>
                        </Grid>
                        <Grid item xs={12} className={appCSS.formRow}>
                          <label className={appCSS.passwordLabel} htmlFor="confirm_password">
                            Confirm Password
                          </label>
                          <input
                            name="confirm_password"
                            placeholder="Confirm password"
                            type="password"
                            value={values.confirm_password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <ErrorMessage name="confirm_password">
                            {(msg) => <div className="input-feedback">{msg}</div>}
                          </ErrorMessage>
                        </Grid>
                        <Grid item xs={12} className={appCSS.formRow}>
                          <Button type="submit" disabled={isSubmitting} className={css.submitButton}>
                            Complete My Registration
                          </Button>
                        </Grid>
                      </>
                    )}
                  </form>
                );
              }}
            </Formik>
          </Grid>
        </>
      )}
      {submitResponse.status === 'DENIED' && (
        <Grid item xs={12} className="alignTextCenter">
          <h2>Oops, there was a problem!</h2>
          <p>
            <HelpDeskContact org={org} />
          </p>
          <Error className={css.deniedIcon} />
        </Grid>
      )}
    </Grid>
  );
};

export default IdentityResult;
