import * as React from 'react';
import { Card, CardHeader, CardContent } from '@material-ui/core';
import css from './text-callout.module.scss';
import { ITextCalloutProps } from './interfaces/ITextCalloutProps';

export const TextCallout: React.FC<ITextCalloutProps> = (props) => {
  return (
    <Card className={css.callout}>
      {props.title && <CardHeader className={css.calloutTitle} title={props.title} />}
      <CardContent className={css.calloutContent}>{props.children}</CardContent>
    </Card>
  );
};
