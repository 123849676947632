import * as React from 'react';
import { Button, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import css from './mui-modal.module.scss';

interface IModalProps {
  open: boolean;
  allowClose: boolean;
  handleClose?: any;
  width?: 'sm' | 'md' | 'lg';
}

export const MUIModal: React.FC<IModalProps> = (props) => {
  const { children, handleClose, open, width, allowClose } = props;

  return (
    <Dialog
      className={css.modal}
      open={open}
      onClose={() => {
        allowClose && handleClose();
      }}
      scroll="paper"
      maxWidth={width}
    >
      {allowClose && (
        <DialogTitle className={css.header}>
          <Button onClick={handleClose} className={css.closeButton}>
            <Close />
          </Button>
        </DialogTitle>
      )}
      <DialogContent className={css.paper}>{children}</DialogContent>
    </Dialog>
  );
};
