import * as React from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { Visibility } from '@material-ui/icons';
import { IListDataItem } from 'views/OAuthManagement/interfaces/IListDataItem';
import { IDeveloperAttestation } from 'views/DeveloperAttestation/interfaces/IDeveloperAttestation';
import css from 'views/Dashboard/dashboard.module.scss';
import { Button } from 'components/controls/Button';
import AppTile from 'views/Dashboard/components/AppTile';
import { Tile } from 'components/controls/Tile';
import AttestationTile from 'views/Dashboard/components/AttestationTile';
import { listDataBuilder } from 'common/utils/listDataBuilder';

const AdminDashboard: React.FC = () => {
  const history = useHistory();
  const oauthApps = useSelector((state) => state.oauthMngmt.oauthApps);
  const oauthAppCredentials = useSelector((state) => state.oauthMngmt.oauthAppCredentials);
  const authClients = useSelector((state) => state.oauthMngmt.authClients);
  const devAttestations = useSelector((state) => state.developerAttestations.attestations);
  const oauthAppDevelopers = useSelector((state) => state.oauthMngmt.oauthAppDevelopers);
  const [listData, setListData] = React.useState<IListDataItem[]>([]);

  React.useEffect(() => {
    if (oauthApps.length > 0 && oauthAppCredentials.length > 0 && authClients.length > 0) {
      const appData = listDataBuilder({
        credentials: oauthAppCredentials,
        applications: oauthApps,
        clients: authClients,
        attestations: devAttestations,
        appDevelopers: oauthAppDevelopers,
      });
      setListData(appData);
    }
  }, [oauthApps, oauthAppCredentials, authClients, devAttestations, oauthAppDevelopers]);

  const handleAllAttestationsClick = () => {
    history.push('/approve-attestations');
  };

  const handleAllAppsClick = () => {
    history.push('/applications');
  };

  const handleGoToDocLibrary = () => {
    console.log('Not Implemented Yet');
  };

  const renderAttestationTile = (attestation: IDeveloperAttestation) => {
    const secondaryData: IListDataItem | undefined = _.find(
      listData,
      (listItem) => listItem.app?.id === attestation.app_id,
    );
    return (
      !!attestation &&
      !!secondaryData &&
      !!secondaryData.client &&
      !!secondaryData.app &&
      !!secondaryData.user && (
        <AttestationTile
          key={`attestation-${attestation.id}`}
          attestation={attestation}
          secondaryData={secondaryData}
        />
      )
    );
  };

  const renderAppTile = (listItem: IListDataItem) => {
    return (
      !!listItem &&
      !!listItem.client &&
      !!listItem.app &&
      !!listItem.user && <AppTile key={`oauth-cred-${listItem.app.id}`} item={listItem} />
    );
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container className={css.sectionHeader}>
          <Grid item xs={8}>
            <h4>Attestation Requests</h4>
            <p>Below are recent developer attestation requests for the applications you’ve created.</p>
          </Grid>
          <Grid item xs={4} className="alignTextRight">
            <Button onClick={handleAllAttestationsClick}>All Attestation Requests</Button>
          </Grid>
        </Grid>
        <Grid container>
          {devAttestations.length === 0 && (
            <h4>No applications have been submitted for attestation approval at this time.</h4>
          )}
          {devAttestations && listData && (
            <Grid item xs={12}>
              {_.map(_.orderBy(devAttestations, ['submission_date'], 'desc'), renderAttestationTile)}
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container className={css.sectionHeader}>
          <Grid item xs={8}>
            <h4>Recent Applications</h4>
            <p>
              Each applications provides a unique set of API keys and configuration settings needed for making requests
              to the system.
            </p>
          </Grid>
          <Grid item xs={4} className="alignTextRight">
            <Button onClick={handleAllAppsClick}>All Apps</Button>
          </Grid>
        </Grid>
        <Grid container>
          {listData.length === 0 && <h4>No applications have been created yet.</h4>}
          {listData && (
            <Grid item xs={12}>
              {_.map(listData, renderAppTile)}
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container className={css.sectionHeader}>
          <Grid item xs={8}>
            <h4>Document Library</h4>
          </Grid>
          <Grid item xs={4} className="alignTextRight">
            <Button onClick={handleGoToDocLibrary}>Document Library</Button>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <Tile
              title="Setting your application's privacy policy"
              bottomContent={
                <Button tertiary>
                  <Visibility /> Read More
                </Button>
              }
            />
            <Tile
              title="Resolving attestation issues"
              bottomContent={
                <Button tertiary>
                  <Visibility /> Read More
                </Button>
              }
            />
            <Tile
              title="Registering an application for production"
              bottomContent={
                <Button tertiary>
                  <Visibility /> Read More
                </Button>
              }
            />
            <Tile
              title="Editing a production application registration"
              bottomContent={
                <Button tertiary>
                  <Visibility /> Read More
                </Button>
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AdminDashboard;
