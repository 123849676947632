//NOTE: Redux is not currently being used for views/monitoring -> Log Viewer (using regular react state instead).
//This code can be made to work with minimal changes if redux store is desired in the future.
import { createAsyncThunk, createSlice, unwrapResult, createEntityAdapter } from '@reduxjs/toolkit';
import { IOrganization } from 'common/interfaces/IOrganization';
import { IUser } from 'common/interfaces/IUser';
import { DynamoScanRows, FPLog } from '../interfaces/IDynamoDB';
import * as authActions from './actions';

const userAPI = {
  fetchUserById: (userId) => userId,
};

export interface ILogMgmntState {
  logs: DynamoScanRows;
  currentOrganization?: IOrganization;
  loading: string;
  currentRequestId?: number;
  error?: string | null;
}

const initialState: ILogMgmntState = {
  logs: { 'Items': [{ 'uuid': {'S':'test'}, 'timestamp': {'S':'time'}}], 'Count': 0, 'ScannedCount': 0, 'ResponseMetadata': {}, 'Rows': [['test']] },
  currentOrganization: undefined,
  loading: 'idle',
  currentRequestId: undefined,
  error: null,
};

const monitorSlice = createSlice({
  name: 'Log',
  initialState: initialState,
  reducers: {},
  // reducers: authReducers,
  extraReducers: (builder) => {
    builder.addCase(authActions.getFPLogsByPayer.fulfilled, (state, { payload }) => {
      state.logs = payload;
      console.dir(payload);
      state.loading = 'idle';
    });
    builder.addCase(authActions.getFPLogsByPayer.pending, (state, { payload }) => {
      state.loading = 'pending';
    });
    builder.addCase(authActions.getFPLogsByPayer.rejected, (state, { payload }) => {
      state.loading = 'idle';
    });

    builder.addCase(authActions.getOrgById.fulfilled, (state, { payload }) => {
      state.currentOrganization = payload;
      state.loading = 'idle';
    });
    builder.addCase(authActions.getOrgById.pending, (state, { payload }) => {
      state.loading = 'pending';
    });
    builder.addCase(authActions.getOrgById.rejected, (state, { payload }) => {
      state.loading = 'idle';
    });

  },
});

export default monitorSlice;
