import _ from 'lodash';
import { IListDataItem } from 'views/OAuthManagement/interfaces/IListDataItem';
import { IDeveloperAttestation } from 'views/DeveloperAttestation/interfaces/IDeveloperAttestation';
import { IAuthClient } from 'views/OAuthManagement/interfaces/IAuthClient';
import { IOAuthApp } from 'views/OAuthManagement/interfaces/IOAuthApp';
import { IOAuthAppCredential } from 'views/OAuthManagement/interfaces/IOAuthAppCredential';
import { IUser } from 'common/interfaces/IUser';

interface IListDataBuilderParams {
  credentials: IOAuthAppCredential[];
  applications: IOAuthApp[];
  clients: IAuthClient[];
  attestations?: IDeveloperAttestation[];
  appDevelopers?: IUser[];
}

/**
 * listDataBuilder builds up a unified list of data elements needed for various views.
 * It keys off the credentials since that's what links the applications to the auth clients.
 */
export const listDataBuilder = (params: IListDataBuilderParams): IListDataItem[] => {
  let appData = params.credentials.map((credential) => {
    return {
      credential: credential,
      app: _.find(params.applications, (app) => {
        return app.id === credential.application_id;
      }),
      client: _.find(params.clients, (client) => {
        return client.id === credential.auth_client_id;
      }),
      attestation: !!params.attestations
        ? _.find(params.attestations, (attestation) => {
            return attestation.app_id === credential.application_id;
          })
        : null,
    };
  });

  // Developers rely on the app usernames, hence we have to wait until
  // the full list has been put together before we can access the app data specifically
  if (!!params.appDevelopers) {
    appData = appData.map((data) => {
      return {
        ...data,
        user: _.find(params.appDevelopers, (dev) => {
          return dev.username === data.app?.username;
        }),
      };
    });
  }

  return appData as IListDataItem[];
};
