import React from 'react';
import { getMeasureReportsByDate } from './fhir.service';
import moment from 'moment';
import { IMeasureReport } from '@ahryman40k/ts-fhir-types/lib/R4';
import DEQMPatientListView from './PatientList';
import DEQMPatientDetailView from './PatientDetail';
import { withStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Button } from '@material-ui/core';

interface IDEQMOverviewProps {
  endOfPeriod: string;
  startOfPeriod: string;
}
let startString, endString: string;

const DEQMOverviewView: React.FC<IDEQMOverviewProps> = ({ endOfPeriod, startOfPeriod }) => {
  const [showPatientList, setShowPatientList] = React.useState(false);
  const [showPatientDetail, setShowPatientDetail] = React.useState(false);
  const [showAggregateView, setShowAggregateView] = React.useState(true);
  const [reports, setReports] = React.useState<IMeasureReport[]>([]);
  const [reportCount, setReportCount] = React.useState(0);
  const [complianceCount, setComplianceCount] = React.useState(0);
  const [patientCount, setPatientCount] = React.useState(0);
  const [report, setReport] = React.useState({});
  const [reportException, setReportException] = React.useState();

  startString = moment(startOfPeriod).format('yyyy-MM-DD');
  endString = moment(endOfPeriod).format('yyyy-MM-DD');
  // const fhirService: FhirService = new FhirService();
  const toggleShowPatientList = () => {
    setShowPatientList(true);
    setShowAggregateView(false);
    setShowPatientDetail(false);
  };
  const toggleShowAggregateView = () => {
    setShowAggregateView(true);
    setShowPatientList(false);
    setShowPatientDetail(false);
  };

  React.useEffect(() => {
    const getReports = async () =>
      await getMeasureReportsByDate(startString, endString)
        .then((res) => {
          if (res.entry) {
            setReports(res.entry);
          }
          setReportCount(res.total);
          setComplianceCount(0);
          if (reportCount > 0) {
            const patientList = reports.map((r) => {
              if (r.subject !== null && r.subject !== undefined) {
                return r.subject.reference;
              }
              return r;
            });
            setPatientCount(patientList.filter((value, index, self) => self.indexOf(value) === index).length);
          }
        })
        .catch((e) => {
          setReportException(e);
        });
    getReports();
  }, [startString, endString, reportCount, report, patientCount]);

  const getDetail = (detailReport: IMeasureReport) => {
    setShowPatientDetail(true);
    setShowPatientList(false);
    setReport(detailReport);
  };

  const StyledTableCell = withStyles((theme: Theme) => ({
    head: {
      backgroundColor: '#4473c3',
      color: '#d9e2f2',
      fontSize: 16,
    },
    body: {
      fontSize: 20,
    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme: Theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

  const useStyles = makeStyles({
    table: {
      minWidth: 500,
    },
  });

  const classes = useStyles();

  return (
    <div>
      <div className="button__container my-4">
        <h5>Measure Type: MRP</h5>
        {reportCount > 0 && showAggregateView ? (
          <Button variant="contained" onClick={toggleShowPatientList}>
            View Measures and Data by Patient
          </Button>
        ) : (
          ''
        )}
        {!showAggregateView && showPatientList ? (
          <Button variant="contained" onClick={toggleShowAggregateView}>
            Back to Aggregate Measure Data
          </Button>
        ) : (
          ''
        )}
        {!showAggregateView && !showPatientList && showPatientDetail ? (
          <Button variant="contained" onClick={toggleShowPatientList}>
            Back to Measures and Data by Patient
          </Button>
        ) : (
          ''
        )}
      </div>
      {showAggregateView && reportCount > 0 ? (
        <TableContainer component={Paper}>
          <h5>Measure Reports Results</h5>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell># Unique Patients</StyledTableCell>
                <StyledTableCell># Measures Calculated</StyledTableCell>
                <StyledTableCell># Compliant Measures</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            {reportCount > 0 ? (
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell align="center">{patientCount}</StyledTableCell>
                  <StyledTableCell align="center">{reportCount ? reportCount : 0}</StyledTableCell>
                  <StyledTableCell align="center">{complianceCount}</StyledTableCell>
                </StyledTableRow>
              </TableBody>
            ) : (
              <TableBody></TableBody>
            )}
          </Table>
        </TableContainer>
      ) : (
        ''
      )}
      {reportCount < 1 ? <div> No data available for date range. </div> : ''}
      {showPatientList && reportCount > 0 ? (
        <DEQMPatientListView reportList={reports} getDetail={(e) => getDetail(e)} />
      ) : (
        ''
      )}
      {showPatientDetail ? <DEQMPatientDetailView report={report}></DEQMPatientDetailView> : ''}
    </div>
  );
};

export default DEQMOverviewView;
