import React, { useState, useRef } from 'react';
import { Grid, CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { Formik, Form, ErrorMessage, Field, useFormikContext } from 'formik';
import { IOAuthModalProps } from 'views/OAuthManagement/interfaces/IOAuthModalProps';
import { trimAllStringProperties } from 'common/utils/trimAllStringProperties';
import { IOrganization } from 'common/interfaces/IOrganization';
import newOrganizationFormSchema from './schemas/NewOrganizationFormSchema';
import classes from 'classnames';
import css from 'components/AppFrame/app-frame.module.scss';
import { StateSelector } from 'components/controls/StateSelector';
import { snakeCase } from 'change-case';
import { Button } from 'components/controls/Button';

import { createOrg } from 'views/OrganizationManagement/redux/actions';
import { useDispatch } from 'react-redux';

const initialValues: IOrganization = {
  name: '',
  descr: '',
  code: '',
  contact_name: '',
  contact_email: '',
  contact_phone: '',
  address: '',
  city: '',
  postal_code: '',
  state: '',
  requires_prod_credentials: false,
};

const NewOrganizationForm: React.FC<IOAuthModalProps> = ({ bindComplete }) => {
  const dispatch = useDispatch();
  const [formSubmitError, setFormSubmitError] = useState(null);
  const formContainerRef = useRef<HTMLElement>();

  const handleSubmit = async (values: IOrganization, setSubmitting: any, ...props) => {
    const trimmedValues = trimAllStringProperties(values);
    dispatch(createOrg(trimmedValues)).then((response) => {
      if (response.error?.message) {
        setFormSubmitError(response.error.message);
        if (formContainerRef.current) {
          formContainerRef.current.scrollIntoView();
        }
      } else {
        bindComplete();
      }
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={newOrganizationFormSchema}
      validateOnChange={false}
    >
      {({ values, handleChange, handleBlur, handleSubmit, setValues, setErrors, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <Grid container>
            <Grid item xs={12}>
              <h3 className={css.modalHeader}>Create a new organization</h3>
            </Grid>
            <Grid container>
              {!!formSubmitError && (
                <Grid item xs={12}>
                  <Alert severity="error" ref={formContainerRef}>
                    {formSubmitError}
                  </Alert>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} className={css.formRow}>
              <label htmlFor="name">Organization Name</label>
              <Field name="name" placeholder="Enter a Name" type="text" />
              <ErrorMessage name="name">{(msg) => <div className="input-feedback">{msg}</div>}</ErrorMessage>
            </Grid>
            <Grid item xs={12} className={css.formRow}>
              <label htmlFor="code">Code</label>
              <Field name="code" placeholder="Enter a unique code" type="text" />
              <ErrorMessage name="code">{(msg) => <div className="input-feedback">{msg}</div>}</ErrorMessage>
            </Grid>
            <Grid item xs={12} className={css.formRow}>
              <label htmlFor="descr">Description</label>
              <Field name="descr" placeholder="Description" as="textarea" />
              <ErrorMessage name="descr">{(msg) => <div className="input-feedback">{msg}</div>}</ErrorMessage>
            </Grid>

            <Grid item xs={12}>
              <h4 className={css.modalHeader}>Contact Information</h4>
            </Grid>
            <Grid item xs={12} className={css.formRow}>
              <label htmlFor="contact_name">Name</label>
              <Field name="contact_name" placeholder="Primary Contact" type="text" />
              <ErrorMessage name="contact_name">{(msg) => <div className="input-feedback">{msg}</div>}</ErrorMessage>
            </Grid>
            <Grid item xs={6} className={css.formRow}>
              <label htmlFor="contact_email">Email</label>
              <Field name="contact_email" placeholder="Email" type="text" />
              <ErrorMessage name="contact_email">{(msg) => <div className="input-feedback">{msg}</div>}</ErrorMessage>
            </Grid>
            <Grid item xs={6} className={css.formRow}>
              <label htmlFor="contact_phone">Phone</label>
              <Field name="contact_phone" placeholder="Phone Number" type="text" />
              <ErrorMessage name="contact_phone">{(msg) => <div className="input-feedback">{msg}</div>}</ErrorMessage>
            </Grid>

            <Grid item xs={12}>
              <h4 className={css.modalHeader}>Address</h4>
            </Grid>
            <Grid item xs={12} className={css.formRow}>
              <label htmlFor="address">Street Address</label>
              <Field name="address" placeholder="Street" type="text" />
              <ErrorMessage name="address">{(msg) => <div className="input-feedback">{msg}</div>}</ErrorMessage>
            </Grid>
            <Grid item xs={12} className={css.formRow}>
              <label htmlFor="city">City</label>
              <Field name="city" placeholder="City" type="text" />
              <ErrorMessage name="city">{(msg) => <div className="input-feedback">{msg}</div>}</ErrorMessage>
            </Grid>
            <Grid item xs={6} className={css.formRow}>
              <label htmlFor="state">State</label>
              <StateSelector
                name="state"
                onChange={(v) => {
                  handleChange(v);
                }}
                multipleSelect={false}
              />
              <ErrorMessage name="state">{(msg) => <div className="input-feedback">{msg}</div>}</ErrorMessage>
            </Grid>
            <Grid item xs={6}>
              <label htmlFor="postal_code">Zip Code</label>
              <Field name="postal_code" placeholder="Zip code" type="text" />
              <ErrorMessage name="postal_code">{(msg) => <div className="input-feedback">{msg}</div>}</ErrorMessage>
            </Grid>

            <Grid item xs={12} className={css.formRow}>
              <label htmlFor="requires_prod_credentials">Requires Production Credentials</label>
              <Field name="requires_prod_credentials" type="checkbox" />
              <ErrorMessage name="requires_prod_credentials">
                {(msg) => <div className="input-feedback">{msg}</div>}
              </ErrorMessage>
            </Grid>

            <Grid item xs={12} className={classes('alignTextRight', css.modalActionButtons)}>
              <Button type="submit" disabled={isSubmitting}>
                {isSubmitting ? (
                  <span>
                    <CircularProgress size="15px" /> Saving...
                  </span>
                ) : (
                  <span>Submit</span>
                )}
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default NewOrganizationForm;
