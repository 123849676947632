import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RouteComponentProps } from 'react-router-dom';
import AppFrame from 'components/AppFrame';
import SettingsIcon from '@material-ui/icons/Settings';
import { ISubMenuItem } from 'components/controls/SideMenu/interfaces/ISubMenuItem';
import { IOAuthListingRouteProps } from 'views/OAuthManagement/interfaces/IOAuthListingRouteProps';

const OAuthFrame: React.FC<RouteComponentProps<IOAuthListingRouteProps>> = (props) => {
  const listView = props.match.params.view || 'dev';
  const history = useHistory();
  const oauthAppCredentials = useSelector((state) => state.oauthMngmt.oauthAppCredentials);

  const [subMenuItems, setSubMenuItems] = useState<ISubMenuItem[]>([]);

  useEffect(() => {
    if (oauthAppCredentials.length > 0) {
      setSubMenuItems([
        {
          label: `Development Clients (${
            oauthAppCredentials.filter((data: { credential_type: number }) => {
              return data.credential_type === 1;
            }).length
          })`,
          active: listView === 'dev',
          action: () => history.push('/applications/dev'),
          icon: <SettingsIcon />,
        },
        {
          label: `Production Clients (${
            oauthAppCredentials.filter((data) => {
              return data.credential_type === 2;
            }).length
          })`,
          active: listView === 'prod',
          action: () => history.push('/applications/prod'),
          icon: <SettingsIcon />,
        },
      ]);
    }
  }, [oauthAppCredentials, listView]);

  return (
    <AppFrame layoutType="developerPortal" selectedMenuItem="OAuthManagement" subMenuItems={subMenuItems}>
      {props.children}
    </AppFrame>
  );
};

export default OAuthFrame;
