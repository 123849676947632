import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Card, CardContent } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Button } from 'components/controls/Button';
import css from 'views/PayerInformation/PayerDetail/payer-detail.module.scss';
import classes from 'classnames';
import { IAppInfoFormProps } from 'views/OAuthManagement/interfaces/IAppInfoFormProps';
import {
  getOAuthApp as getOAuthAppAction,
  updateOAuthApp as updateOAuthAppAction,
} from 'views/OAuthManagement/redux/actions';
import { Save, Edit } from '@material-ui/icons';

const AppInfoForm: React.FC<IAppInfoFormProps> = ({ currentApp, currentCredential }) => {
  const dispatch = useDispatch();
  const [openOptionalEdit, setOpenOptionalEdit] = React.useState(false);
  const [sendError, setSendError] = React.useState('');
  const [appOrgWebsite, setOrgWebsite] = React.useState<string>('');
  const [appDescr, setDescr] = React.useState<string>('');
  const [appContacts, setContacts] = React.useState<string>('');
  const [appSupportEmail, setSupportEmail] = React.useState<string>('');

  React.useEffect(() => {
    setOrgWebsite(currentApp.org_website || '');
    setDescr(currentApp.app_descr || '');
    setContacts(currentApp.app_contacts || '');
    setSupportEmail(currentApp.support_email || '');
  }, [currentApp]);

  const toggleOptionalEdit = () => {
    setOpenOptionalEdit(!openOptionalEdit);
  };

  const handleOptionalSave = () => {
    dispatch(
      updateOAuthAppAction({
        ...currentApp,
        org_website: appOrgWebsite,
        app_descr: appDescr,
        app_contacts: appContacts,
        support_email: appSupportEmail,
      }),
    ).then((response) => {
      if (response.error) {
        setSendError(response.error.message);
      } else {
        dispatch(getOAuthAppAction(currentCredential.application_id));
        toggleOptionalEdit();
      }
    });
  };

  return (
    <Grid item xs={6} className={css.detailRegion}>
      <Card className={classes(css.detailCard, { [css.focused]: openOptionalEdit })}>
        <CardContent>
          {!!sendError && <Alert severity="error">{sendError}</Alert>}
          <div className={css.detailActions}>
            {openOptionalEdit ? (
              <>
                <Button type="link" className={css.cancel} onClick={toggleOptionalEdit}>
                  Cancel
                </Button>
                <Button tertiary onClick={handleOptionalSave}>
                  <Save /> Save
                </Button>
              </>
            ) : (
              <Button type="link" onClick={toggleOptionalEdit}>
                <Edit className={css.detailsEditLink} />
              </Button>
            )}
          </div>
          <h3>Optional App Information</h3>
          <div className={css.infoMessage}>
            This information can be used to more fully emulate elements of having an application in production. This
            information is only used for sample purposes.
          </div>

          <label>Organization Website</label>
          {openOptionalEdit ? (
            <input
              name="org_website"
              type="text"
              value={appOrgWebsite}
              onChange={(e) => setOrgWebsite(e.target.value)}
            />
          ) : (
            <div className={css.value}>
              {currentApp.org_website ? currentApp.org_website : 'No website has been entered.'}
            </div>
          )}

          <label>Application Description</label>
          {openOptionalEdit ? (
            <input name="app_descr" type="text" value={appDescr} onChange={(e) => setDescr(e.target.value)} />
          ) : (
            <div className={css.value}>
              {currentApp.app_descr ? currentApp.app_descr : 'No description has been given for your application.'}
            </div>
          )}

          <label>Application Contacts</label>
          {openOptionalEdit ? (
            <input name="app_contacts" type="text" value={appContacts} onChange={(e) => setContacts(e.target.value)} />
          ) : (
            <div className={css.value}>
              {currentApp.app_contacts
                ? currentApp.app_contacts
                : 'No contacts have been assigned for this application.'}
            </div>
          )}

          <label>Support Email</label>
          {openOptionalEdit ? (
            <input
              name="support_email"
              type="text"
              value={appSupportEmail}
              onChange={(e) => setSupportEmail(e.target.value)}
            />
          ) : (
            <div className={css.value}>
              {currentApp.support_email
                ? currentApp.support_email
                : 'No support email has been entered for this application.'}
            </div>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
};

export default AppInfoForm;
