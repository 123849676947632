import * as React from 'react';
import { Route, Switch } from 'react-router';
import { HasLoggedIn } from './HasLoggedIn';
import { IsUserOfType } from './IsUserOfType';

import Login from 'views/Auth/Login';
import Signup from 'views/Auth/Signup';
import ResetPassword from 'views/Auth/ResetPassword';

import Dashboard from 'views/Dashboard';
import Organization from 'views/Organization';
import OrganizationManagement from 'views/OrganizationManagement';
import PayerDetail from 'views/PayerInformation/PayerDetail';
import OAuthManagement from 'views/OAuthManagement';
import OAuthAppDetail from 'views/OAuthManagement/OAuthAppDetail';
import DeveloperAttestation from 'views/DeveloperAttestation/AttestationList';
import ApproveAttestation from 'views/ApproveAttestation';
import AccountSettings from 'views/AccountSettings';

import MonitoringOverview from 'views/Monitoring/MonitoringOverview';
import Logs from 'views/Monitoring/Logs';

import Overview from 'views/DocumentLibrary/Overview';
import WhatIsFHIR from 'views/DocumentLibrary/WhatIsFHIR';
import CDAComparedToFHIR from 'views/DocumentLibrary/CDAComparedToFHIR';
import Endpoints from 'views/DocumentLibrary/Endpoints';

import DEQMDashboard from 'views/DEQMDashboard';
import PatientDetail from 'views/DEQMDashboard/PatientDetail';
import Registration from 'views/Registration';

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/" component={HasLoggedIn(Dashboard)} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/sign-up" component={Signup} />
      <Route exact path="/sign-up/complete" component={Signup} />
      <Route exact path="/reset-password" component={ResetPassword} />

      <Route exact path="/attestation" component={HasLoggedIn(DeveloperAttestation)} />

      <Route exact path="/orgs" component={HasLoggedIn(IsUserOfType(OrganizationManagement, ['mihin-admin']))} />
      <Route exact path="/orgs/:id" component={HasLoggedIn(IsUserOfType(Organization, ['mihin-admin', 'payer']))} />

      <Route exact path="/monitoring" component={HasLoggedIn(MonitoringOverview)} />
      <Route exact path="/monitoring/logs" component={HasLoggedIn(Logs)} />

      <Route exact path="/dashboard" component={Dashboard} />
      <Route exact path="/external-payer" component={HasLoggedIn(IsUserOfType(PayerDetail, ['external-payer']))} />
      <Route exact path="/applications/:view?" component={HasLoggedIn(OAuthManagement)} />
      <Route exact path="/applications/:id/edit" component={IsUserOfType(HasLoggedIn(OAuthAppDetail), ['developer'])} />
      <Route
        exact
        path="/approve-attestations"
        component={HasLoggedIn(IsUserOfType(ApproveAttestation, ['mihin-admin']))}
      />
      <Route exact path="/account-settings" component={HasLoggedIn(AccountSettings)} />

      <Route exact path="/document-library" component={HasLoggedIn(Overview)} />
      <Route exact path="/document-library/fhir" component={HasLoggedIn(WhatIsFHIR)} />
      <Route exact path="/document-library/cda-compared-to-fhir" component={HasLoggedIn(CDAComparedToFHIR)} />
      <Route exact path="/document-library/endpoints" component={HasLoggedIn(Endpoints)} />

      <Route exact path={['/deqm', '/deqm/patients/:id?']} component={DEQMDashboard} />
      <Route exact path={['/deqm/patients/:id?']} component={PatientDetail} />
      <Route exact path="/:code/registration" component={Registration} />
    </Switch>
  );
};

export default Routes;
