import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RouteComponentProps } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import _ from 'lodash';
import OAuthFrame from 'views/OAuthManagement/components/OAuthFrame';
import { Button } from 'components/controls/Button';
import OAuthAppListItem from 'views/OAuthManagement/components/OAuthAppListItem';
import { MUIModal } from 'components/controls/MUI/MUIModal';
import NewApp from 'views/OAuthManagement/components/NewApp';
import { IOAuthListingRouteProps } from 'views/OAuthManagement/interfaces/IOAuthListingRouteProps';
import { IListDataItem } from 'views/OAuthManagement/interfaces/IListDataItem';
import { credential_type_map } from 'views/OAuthManagement/interfaces/IAuthClient';
import { IUser } from 'common/interfaces/IUser';
import { listDataBuilder } from 'common/utils/listDataBuilder';

const OAuthAppListing: React.FC<RouteComponentProps<IOAuthListingRouteProps>> = (props) => {
  const listView: string = props.match.params.view || 'dev';
  const history = useHistory();
  const currentUser = useSelector((state) => state.auth.user) as IUser;
  const oauthApps = useSelector((state) => state.oauthMngmt.oauthApps);
  const oauthAppCredentials = useSelector((state) => state.oauthMngmt.oauthAppCredentials);
  const oauthAppDevelopers = useSelector((state) => state.oauthMngmt.oauthAppDevelopers);
  const authClients = useSelector((state) => state.oauthMngmt.authClients);
  const devAttestations = useSelector((state) => state.developerAttestations.attestations);
  const [listData, setListData] = React.useState<IListDataItem[]>([]);
  const [newAppModal, setNewAppModal] = React.useState(false);

  if(currentUser.user_type === 'developer') {
    React.useEffect(() => {
      if (oauthApps.length > 0 && oauthAppCredentials.length > 0 && authClients.length > 0) {
        const appData = listDataBuilder({
          credentials: oauthAppCredentials,
          applications: oauthApps,
          clients: authClients,
          attestations: devAttestations,
          appDevelopers: [currentUser],
        });
        setListData(appData);
      }
    }, [oauthApps, oauthAppCredentials, authClients, devAttestations, oauthAppDevelopers]);
  } else {
    React.useEffect(() => {
      if (oauthApps.length > 0 && oauthAppCredentials.length > 0 && authClients.length > 0) {
        const appData = listDataBuilder({
          credentials: oauthAppCredentials,
          applications: oauthApps,
          clients: authClients,
          attestations: devAttestations,
          appDevelopers: oauthAppDevelopers,
        });
        setListData(appData);
      }
    }, [oauthApps, oauthAppCredentials, authClients, devAttestations, oauthAppDevelopers]);
  }

  const toggleNewApp = () => {
    setNewAppModal(!newAppModal);
  };
  const handleNewAppComplete = () => {
    toggleNewApp();
  };

  const renderListItem = (item: IListDataItem, index) => {
    return (
      !!item &&
      !!item.app &&
      !!item.credential &&
      !!item.client &&
      !!item.user && (
        <OAuthAppListItem
          key={`app-line-item-${index}`}
          type={item.credential.credential_type === credential_type_map['DEV'] ? 'dev' : 'prod'}
          oauthApp={item.app}
          oauthCredential={item.credential}
          authClient={item.client}
          developer={item.user}
          attestation={item.attestation}
        />
      )
    );
  };

  return (
    <OAuthFrame {...props}>
      <Grid container>
        <Grid item xs={6}>
          <h2 className="section-title">
            {currentUser?.user_type === 'developer' ? 'OAuth Management' : 'Applications'}
          </h2>
        </Grid>
        <Grid item xs={6} style={{ textAlign: 'right' }}>
          {currentUser?.user_type === 'developer' && <Button onClick={toggleNewApp}>Register an App</Button>}
        </Grid>
      </Grid>
      <Grid container>
        <ul className="tabBar">
          <li>
            <button className={listView === 'dev' ? 'active' : ''} onClick={() => history.push('/applications/dev')}>
              Development Clients
            </button>
          </li>
          <li>
            <button className={listView === 'prod' ? 'active' : ''} onClick={() => history.push('/applications/prod')}>
              Production Clients
            </button>
          </li>
        </ul>
      </Grid>
      {_.map(
        listData.filter((data) => {
          return (
            data.credential.credential_type ===
            (listView === 'dev' ? credential_type_map['DEV'] : credential_type_map['PROD'])
          );
        }),
        renderListItem,
      )}

      <MUIModal width="md" allowClose open={newAppModal} handleClose={toggleNewApp}>
        <NewApp bindComplete={handleNewAppComplete} bindCancel={handleNewAppComplete} />
      </MUIModal>
    </OAuthFrame>
  );
};

export default OAuthAppListing;
