import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Card, CardContent, CardActions, CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import moment from 'moment';
import { Button } from 'components/controls/Button';
import css from 'views/OAuthManagement/OAuthAppDetail/oauth-app-detail.module.scss';
import classes from 'classnames';
import { IAppPrivacyPolicyProps } from 'views/OAuthManagement/interfaces/IAppPrivacyPolicyProps';
import { credential_type_map } from 'views/OAuthManagement/interfaces/IAuthClient';
import { IAppPrivacyPolicyAttestation } from 'views/OAuthManagement/interfaces/IAppPrivacyPolicyAttestation';
import AppPrivacyPolicyAttestation from 'views/OAuthManagement/components/AppPrivacyPolicyAttestation';
import { MUIModal } from 'components/controls/MUI/MUIModal';
import { Publish, HighlightOff, CheckCircleOutline, Description, Forward } from '@material-ui/icons';
import {
  getOAuthApp as getOAuthAppAction,
  addOAuthAppPrivacyPolicy as addOAuthAppPrivacyPolicyAction,
  deleteOAuthAppPrivacyPolicy as deleteOAuthAppPrivacyPolicyAction,
  getOAuthAppPrivacyPolicy as getOAuthAppPrivacyPolicyAction,
  getOAuthAppPrivacyPolicyAttestation as getOAuthAppPrivacyPolicyAttestationAction,
} from 'views/OAuthManagement/redux/actions';

const AppPrivacyPolicy: React.FC<IAppPrivacyPolicyProps> = ({ currentApp, currentCredential }) => {
  const dispatch = useDispatch();
  const currentPrivacyPolicyAttestation = useSelector(
    (state) => state.oauthMngmt.currentPrivacyPolicyAttestation,
  ) as IAppPrivacyPolicyAttestation;

  const [isSending, setIsSending] = React.useState(false);
  const [sendError, setSendError] = React.useState('');
  const [attestationModalVisible, setAttestationModalVisible] = React.useState(false);

  const toggleAttestationSubmitModal = () => setAttestationModalVisible(!attestationModalVisible);

  React.useEffect(() => {
    if (currentApp?.id) {
      dispatch(getOAuthAppPrivacyPolicyAttestationAction(currentApp.id));
    }
  }, [dispatch, currentApp.id]);

  const handleViewPrivacyPolicyClick = () => {
    if (currentApp && currentApp.id) {
      dispatch(getOAuthAppPrivacyPolicyAction(currentApp.id)).then((response) => {
        if (response.error) {
          setSendError(response.error.message);
        } else {
          window.open(response.payload);
        }
      });
    }
  };
  const handleUploadPrivacyPolicyChange = (event) => {
    setIsSending(true);
    dispatch(
      addOAuthAppPrivacyPolicyAction({ authApp: currentApp, authAppPrivacyPolicyFile: event.target.files[0] }),
    ).then(() => {
      setIsSending(false);
      dispatch(getOAuthAppAction(currentCredential.application_id));
    });
  };
  const handleRemovePrivacyPolicyClick = () => {
    if (currentApp && currentApp.id) {
      dispatch(deleteOAuthAppPrivacyPolicyAction(currentApp.id)).then(() => {
        dispatch(getOAuthAppAction(currentCredential.application_id));
      });
    }
  };

  return (
    <Grid item xs={3} className={css.detailRegion}>
      <Card
        className={classes(css.detailCard, {
          [css.success]: !!currentApp.privacy_policy_url && !!currentPrivacyPolicyAttestation,
        })}
      >
        <CardContent className={css.detailBody}>
          {!!sendError && <Alert severity="error">{sendError}</Alert>}
          <h3>Privacy Policy</h3>
          {!!currentApp.privacy_policy_url ? (
            <div className={css.successMessage}>
              <div>
                <CheckCircleOutline /> Uploaded
              </div>
              <div>
                <span>UPLOADED ON:</span>
                <br />
                {moment(currentApp.privacy_policy_upload_date).format('MMM DD, YYYY')}
              </div>
            </div>
          ) : (
            <div className={css.warningMessage}>
              <HighlightOff /> Not Available (PDF files only.)
            </div>
          )}
          {!!currentPrivacyPolicyAttestation ? (
            <div className={css.successMessage}>
              <div>
                <CheckCircleOutline /> Your attestation has been submitted.
              </div>
            </div>
          ) : (
            <div className={css.warningMessage}>
              <HighlightOff /> Your attestation has not yet been submitted
            </div>
          )}
        </CardContent>
        <CardActions className={classes(css.detailActions, css.policyAndSecurityActions)}>
          {!!currentApp.privacy_policy_url ? (
            <>
              <Button tertiary onClick={handleViewPrivacyPolicyClick}>
                <Description /> View
              </Button>
              {currentCredential.credential_type === credential_type_map['DEV'] && (
                <Button tertiary className={css.removeButton} onClick={handleRemovePrivacyPolicyClick}>
                  <HighlightOff /> Remove
                </Button>
              )}
              <Button tertiary onClick={toggleAttestationSubmitModal}>
                <Forward /> Submit
              </Button>
            </>
          ) : (
            <>
              <label className={css.uploadButton} htmlFor="policy-upload">
                {isSending ? (
                  <span>
                    <CircularProgress size="15" /> Uploading
                  </span>
                ) : (
                  <span>
                    <Publish /> Upload
                  </span>
                )}
              </label>
              <input
                id="policy-upload"
                placeholder="Upload"
                type="file"
                accept=".pdf, application/pdf"
                onChange={handleUploadPrivacyPolicyChange}
                style={{ display: 'none' }}
              />
              <Button tertiary onClick={toggleAttestationSubmitModal}>
                <Forward /> Submit
              </Button>
            </>
          )}
        </CardActions>
      </Card>
      <MUIModal width="lg" allowClose open={attestationModalVisible} handleClose={toggleAttestationSubmitModal}>
        <AppPrivacyPolicyAttestation
          bindComplete={toggleAttestationSubmitModal}
          bindCancel={toggleAttestationSubmitModal}
        />
      </MUIModal>
    </Grid>
  );
};

export default AppPrivacyPolicy;
