import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import {
  getOAuthApps as getOAuthAppsAction,
  getOAuthAppCredentials as getOAuthAppCredentialsAction,
  getAuthClients as getAuthClientsAction,
  getOAuthAppDevelopers as getOAuthAppDevelopersAction,
} from 'views/OAuthManagement/redux/actions';
import { getAttestations as getAttestationsAction } from 'views/DeveloperAttestation/redux/actions';
import { IOAuthListingRouteProps } from './interfaces/IOAuthListingRouteProps';
import OAuthAppListing from './OAuthAppListing';
import { IUser } from 'common/interfaces/IUser';

const OAuthManagement: React.FC<RouteComponentProps<IOAuthListingRouteProps>> = (props) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.user) as IUser;

  React.useEffect(() => {
    dispatch(getOAuthAppCredentialsAction()).then(() => {
      dispatch(getOAuthAppsAction()).then((apps) => {
        if (currentUser.user_type !== 'developer') {
          dispatch(getOAuthAppDevelopersAction(apps.payload));
        }
      });
      dispatch(getAuthClientsAction());
      dispatch(getAttestationsAction());
    });
  }, [dispatch, currentUser]);

  return <OAuthAppListing {...props} />;
};

export default OAuthManagement;
