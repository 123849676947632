import * as yup from 'yup';
import { ISignupValidationSchema } from 'views/Auth/Signup/interfaces/ISignupValidationSchema';
import { passwordSchema } from 'views/Auth/schemas/password';

export const signupValidationSchema = yup.object<ISignupValidationSchema>({
  email: yup.string().email('Email is not valid').required('Email Required'),
  username: yup.string().required('Username Required'),
  password: passwordSchema(),
  confirm_password: yup
    .string()
    .required('Confirm Password Required')
    .oneOf([yup.ref('password'), ''], 'Passwords must match'),
  terms_accepted: yup.mixed().oneOf(['true', '1', true], 'Please accept the Terms of Service').required(),
});

export default signupValidationSchema;
