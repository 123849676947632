import { createSlice } from '@reduxjs/toolkit';
import { IDemographicsResponse } from '../interfaces/IDemographicsResponse';
import { ISubmitResponse } from '../interfaces/ISubmitResponse';
import { IOrganization } from 'common/interfaces/IOrganization';
import * as registrationActions from './actions';
import { IRegistrationError } from '../interfaces/IRegistrationError';
import { IDemographicsRequest } from '../interfaces/IDemographicsRequest';

export interface IRegistrationState {
  currentRequest?: IDemographicsRequest | null;
  currentSession?: IDemographicsResponse | null;
  currentOrganization?: IOrganization | null;
  currentSubmit?: ISubmitResponse | null;
  registrationError: IRegistrationError | null;
  loading: string;
  error?: string | null;
}

const initialState: IRegistrationState = {
  currentRequest: null,
  currentSession: null,
  currentOrganization: null,
  currentSubmit: null,
  registrationError: null,
  loading: 'idle',
  error: null,
};

const registrationSlice = createSlice({
  name: 'Registration',
  initialState: initialState,
  reducers: {},
  // reducers: authReducers,
  extraReducers: (builder) => {
    builder.addCase(registrationActions.submitSessionRequest.fulfilled, (state, { payload }) => {
      state.currentSession = payload;
      state.loading = 'idle';
    });
    builder.addCase(registrationActions.submitSessionRequest.pending, (state, { payload }) => {
      state.loading = 'pending';
    });
    builder.addCase(registrationActions.submitSessionRequest.rejected, (state, { payload }) => {
      state.loading = 'idle';
    });

    builder.addCase(registrationActions.submit.fulfilled, (state, { payload }) => {
      state.currentSubmit = payload;
      state.loading = 'idle';
    });
    builder.addCase(registrationActions.submit.pending, (state, { payload }) => {
      state.loading = 'pending';
    });
    builder.addCase(registrationActions.submit.rejected, (state, { payload }) => {
      state.loading = 'idle';
    });

    builder.addCase(registrationActions.getOrgByCode.fulfilled, (state, { payload }) => {
      state.currentOrganization = payload;
      state.loading = 'idle';
    });
    builder.addCase(registrationActions.getOrgByCode.pending, (state, { payload }) => {
      state.loading = 'pending';
    });
    builder.addCase(registrationActions.getOrgByCode.rejected, (state, { payload }) => {
      state.loading = 'idle';
    });

    builder.addCase(registrationActions.setRegistrationError.fulfilled, (state, { payload }) => {
      state.registrationError = payload;
      state.loading = 'idle';
    });
    builder.addCase(registrationActions.setRegistrationError.pending, (state, { payload }) => {
      state.loading = 'pending';
    });
    builder.addCase(registrationActions.setRegistrationError.rejected, (state, { payload }) => {
      state.loading = 'idle';
    });

    builder.addCase(registrationActions.clearSession.fulfilled, (state, { payload }) => {
      state.currentSession = payload;
      state.currentSubmit = payload;
      state.registrationError = payload;
      state.loading = 'idle';
    });
    builder.addCase(registrationActions.clearSession.pending, (state, { payload }) => {
      state.loading = 'pending';
    });
    builder.addCase(registrationActions.clearSession.rejected, (state, { payload }) => {
      state.loading = 'idle';
    });
  },
});

export default registrationSlice;
