import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Grid, Card, CardContent, ClickAwayListener, Tooltip } from '@material-ui/core';
import { Visibility, FileCopy } from '@material-ui/icons';
import moment from 'moment';
import { MUIChip } from 'components/controls/MUI/MUIChip';
import { Button } from 'components/controls/Button';
import { MUIModal } from 'components/controls/MUI/MUIModal';
import { IOAuthApp } from 'views/OAuthManagement/interfaces/IOAuthApp';
import { IOAuthAppCredential } from 'views/OAuthManagement/interfaces/IOAuthAppCredential';
import { IAuthClient } from 'views/OAuthManagement/interfaces/IAuthClient';
import { IDeveloperAttestation } from 'views/DeveloperAttestation/interfaces/IDeveloperAttestation';
import css from 'views/OAuthManagement/oauth-management.module.scss';
import { clipboardCopy } from 'common/utils/clipboardCopy';
import {
  getOAuthApps as getOAuthAppsAction,
  getOAuthAppCredentials as getOAuthAppCredentialsAction,
  getAuthClients as getAuthClientsAction,
  deleteOAuthAppCredential as deleteOAuthAppCredentialAction,
  deleteAuthClient as deleteAuthClientAction,
} from 'views/OAuthManagement/redux/actions';
import DeleteAppConfirm from 'views/OAuthManagement/components/DeleteAppConfirm';
import AppInfo from 'views/Dashboard/components/AppInfo';
import { IUser } from 'common/interfaces/IUser';
import { getUserDisplayName } from 'common/utils/getUserDisplayName';

interface IOAuthAppListItemProps {
  type: 'dev' | 'prod';
  oauthApp: IOAuthApp;
  oauthCredential: IOAuthAppCredential;
  authClient: IAuthClient;
  developer?: IUser;
  attestation?: IDeveloperAttestation;
}

const OAuthAppListItem: React.FC<IOAuthAppListItemProps> = ({
  type,
  oauthApp,
  oauthCredential,
  authClient,
  developer,
  attestation,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentUser = useSelector((state) => state.auth.user) as IUser;

  const [showTokens, setShowTokens] = React.useState(false);
  const [openIDToolTop, setOpenIDToolTip] = React.useState(false);
  const [openSecretToolTop, setOpenSecretToolTip] = React.useState(false);
  const [deleteConfirmModal, setDeleteConfirmModal] = React.useState(false);
  const [appInfoModal, setAppInfoModal] = React.useState(false);

  const handleIDTooltipClose = () => {
    setOpenIDToolTip(false);
  };
  const handleSecretTooltipClose = () => {
    setOpenSecretToolTip(false);
  };
  const toggleTokenVisibility = () => {
    setShowTokens(!showTokens);
  };
  const handleEditClick = () => {
    history.push(`/applications/${oauthCredential.id}/edit`);
  };
  const handleDeleteClick = () => {
    dispatch(deleteOAuthAppCredentialAction(oauthCredential)).then(() => {
      dispatch(deleteAuthClientAction(authClient)).then(() => {
        dispatch(getOAuthAppCredentialsAction()).then(() => {
          dispatch(getOAuthAppsAction());
          dispatch(getAuthClientsAction());
          setDeleteConfirmModal(!deleteConfirmModal);
        });
      });
    });
  };
  const toggleAppInfo = () => {
    setAppInfoModal(!appInfoModal);
  };

  const toggleDeleteConfirmation = () => {
    setDeleteConfirmModal(!deleteConfirmModal);
  };

  const copyID = () => {
    clipboardCopy(authClient.client_id).then(() => {
      setOpenIDToolTip(true);
    });
  };
  const copySecret = () => {
    clipboardCopy(authClient.client_secret).then(() => {
      setOpenSecretToolTip(true);
    });
  };

  const copyIDAction = (
    <ClickAwayListener onClickAway={handleIDTooltipClose}>
      <div className={css.copyButton}>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleIDTooltipClose}
          open={openIDToolTop}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          arrow
          title="Copied!"
        >
          <FileCopy onClick={copyID} />
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
  const copySecretAction = (
    <ClickAwayListener onClickAway={handleSecretTooltipClose}>
      <div className={css.copyButton}>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleSecretTooltipClose}
          open={openSecretToolTop}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          arrow
          title="Copied!"
        >
          <FileCopy onClick={copySecret} />
        </Tooltip>
      </div>
    </ClickAwayListener>
  );

  return (
    <Card className={css.oauthListItem}>
      <CardContent>
        <Grid container>
          <Grid item xs={1}>
            <MUIChip type={type} />
          </Grid>
          <Grid item xs={5}>
            <h4>{authClient?.name}</h4>
            <div className={css.description}>{oauthApp?.app_descr}</div>
          </Grid>
          <Grid item xs={3}>
            {currentUser.user_type === 'developer' && (
              <>
                <h4>Redirect URI</h4>
                <a href={`${authClient?.default_redirect_uri}`}>{authClient?.default_redirect_uri}</a>
              </>
            )}
            {currentUser.user_type !== 'developer' && developer && (
              <>
                <h4>Author</h4>
                <div className={css.description}>{getUserDisplayName(developer)}</div>
                <a href={`mailto:${developer.email}`}>{developer.email}</a>
              </>
            )}
          </Grid>
          <Grid item xs={3} className="alignTextRight">
            {currentUser.user_type === 'developer' ? (
              <>
                <p>
                  <Button secondary type="button" onClick={handleEditClick}>
                    Edit
                  </Button>
                </p>
                <p>
                  <Button notice type="button" onClick={toggleDeleteConfirmation}>
                    Delete
                  </Button>
                </p>
              </>
            ) : (
              <>
                <p>
                  <Button type="button" onClick={toggleAppInfo}>
                    View App
                  </Button>
                </p>
                <p>
                  <strong>Last Updated</strong>
                  <br />
                  {moment(oauthApp?.updated_date || oauthApp?.created_date).format('MMMM DD, YYYY')}
                </p>
              </>
            )}
          </Grid>
        </Grid>
        {currentUser.user_type !== 'payer' && (
          <>
            <Grid container>
              <Grid item xs={1}></Grid>
              <Grid item xs={8}>
                <hr />
              </Grid>
              <Grid item xs={3}></Grid>
            </Grid>
            <Grid container>
              <Grid item xs={1}>
                <Button tertiary onClick={toggleTokenVisibility}>
                  <Visibility /> {showTokens ? 'Hide' : 'Show'}
                </Button>
              </Grid>
              <Grid item xs={4}>
                <div className={css.clientToken}>
                  <span>Client ID:</span>
                  {showTokens ? (
                    <input type="text" value={authClient?.client_id} disabled />
                  ) : (
                    <pre>********************</pre>
                  )}
                  {copyIDAction}
                </div>
              </Grid>
              <Grid item xs={4}>
                <div className={css.clientToken}>
                  <span>Client Secret:</span>
                  {showTokens ? (
                    <input type="text" value={authClient?.client_secret} disabled />
                  ) : (
                    <pre>******************************</pre>
                  )}
                  {copySecretAction}
                </div>
              </Grid>
              <Grid item xs={3}></Grid>
            </Grid>
          </>
        )}

        <MUIModal width="md" allowClose open={deleteConfirmModal} handleClose={toggleDeleteConfirmation}>
          <DeleteAppConfirm bindComplete={handleDeleteClick} bindCancel={toggleDeleteConfirmation} />
        </MUIModal>
        <MUIModal width="md" allowClose open={appInfoModal} handleClose={toggleAppInfo}>
          <AppInfo
            item={{
              app: oauthApp,
              credential: oauthCredential,
              client: authClient,
              user: developer,
              attestation: attestation,
            }}
            bindClose={toggleAppInfo}
          />
        </MUIModal>
      </CardContent>
    </Card>
  );
};

export default OAuthAppListItem;
