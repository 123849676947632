import { object, string } from 'yup';
import { IUserUpdateValidationSchema } from 'views/AccountSettings/interfaces/IUserUpdateValidationSchema';

export const userUpdateValidationSchema = object<IUserUpdateValidationSchema>({
  given_name: string().required('First Name is Required'),
  family_name: string().required('Last Name is Required'),
  preferred_username: string().required('Username is Required'),
  email: string().required('Email is Required'),
});

export default userUpdateValidationSchema;
