import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, CircularProgress } from '@material-ui/core';
import AppFrame from 'components/AppFrame';
import { AppBanner } from 'components/AppBanner';
import { IUser } from 'common/interfaces/IUser';
import { TLayoutType } from 'components/AppFrame/interfaces/TLayoutType';
import { getUserDisplayName } from 'common/utils/getUserDisplayName';
import {
  getOrgById as getOrgByIdAction,
  completeCurrentOrg as completeCurrentOrgAction,
} from 'views/Organization/redux/actions';
import {
  getOAuthApps as getOAuthAppsAction,
  getOAuthAppCredentials as getOAuthAppCredentialsAction,
  getAuthClients as getAuthClientsAction,
  getOAuthAppDevelopers as getOAuthAppDevelopersAction,
} from 'views/OAuthManagement/redux/actions';
import { getAttestations as getAttestationsAction } from 'views/DeveloperAttestation/redux/actions';
import { IOrganization } from 'common/interfaces/IOrganization';
import DeveloperDashboard from './DeveloperDashboard';
import PayerDashboard from './PayerDashboard';
import AdminDashboard from './AdminDashboard';
import ExternalPayerDashboard from './ExternalPayerDashboard';
import css from 'views/Dashboard/dashboard.module.scss';

const Dashboard: React.FC = (props) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.user) as IUser;
  const currentOrg = useSelector((state) => state.org.currentOrganization) as IOrganization;
  const currentUserDisplayName = getUserDisplayName(currentUser);

  const layoutType: TLayoutType =
    currentUser?.user_type === 'developer'
      ? 'developerPortal'
      : currentUser?.user_type === 'payer'
      ? 'payerPortal'
      : 'adminPortal';

  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    dispatch(getAttestationsAction()).then(() => {
      dispatch(getOAuthAppCredentialsAction()).then(() => {
        dispatch(getOAuthAppsAction()).then((apps) => {
          if (currentUser?.user_type !== 'developer') {
            dispatch(getOAuthAppDevelopersAction(apps.payload));
          }
          dispatch(getAuthClientsAction()).then(() => {
            setLoading(false);
          });
        });
      });
    });
  }, [dispatch]);

  React.useEffect(() => {
    if (!!currentUser) {
      dispatch(getOrgByIdAction(currentUser.organization_id || 0));
    }
  }, [currentUser, dispatch]);

  React.useEffect(() => {
    if (!!currentOrg) {
      if (currentOrg.status !== 'complete') {
        dispatch(completeCurrentOrgAction());
      }
    }
  }, [currentOrg, dispatch]);

  return (
    <AppFrame layoutType={layoutType}>
      {!!currentUser && (
        <AppBanner>
          {currentUser.user_type === 'developer' && (
            <>
              <h1>Welcome {currentUserDisplayName}</h1>
              <h3>Welcome to InterOp Station&trade;. Register your application below.</h3>
            </>
          )}
          {currentUser.user_type === 'mihin-admin' && (
            <>
              <h1>Welcome {currentUserDisplayName}</h1>
              <h3>Welcome to InterOp Station&trade;. View registered applications below.</h3>
            </>
          )}
          {currentUser.user_type === 'payer' && currentOrg && (
            <>
              <h1>{currentOrg.name}</h1>
              <h3>Welcome {currentUserDisplayName}</h3>
            </>
          )}
          {currentUser.user_type === 'external-payer' && currentOrg && (
            <>
              <h1>Welcome to the Payer Dashboard</h1>
              <h3>Welcome to InterOp Station&trade;. Register as a payer below.</h3>
            </>
          )}
        </AppBanner>
      )}
      {loading ? (
        <Grid container>
          <Grid item xs={12} className={css.loaderPanel}>
            <CircularProgress size="25px" /> <span>Loading....</span>
          </Grid>
        </Grid>
      ) : (
        <>
          {currentUser && currentUser.user_type === 'developer' && <DeveloperDashboard />}
          {currentUser && currentUser.user_type === 'payer' && <PayerDashboard />}
          {currentUser && currentUser.user_type === 'mihin-admin' && <AdminDashboard />}
          {currentUser && currentUser.user_type === 'external-payer' && <ExternalPayerDashboard />}
        </>
      )}
    </AppFrame>
  );
};

export default Dashboard;
