import * as React from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { Visibility } from '@material-ui/icons';
import classes from 'classnames';
import { IUser } from 'common/interfaces/IUser';
import { IListDataItem } from 'views/OAuthManagement/interfaces/IListDataItem';
import { IDeveloperAttestation } from 'views/DeveloperAttestation/interfaces/IDeveloperAttestation';
import css from 'views/Dashboard/dashboard.module.scss';
import { ActionTile } from 'components/controls/ActionTile';
import { Tile } from 'components/controls/Tile';
import { MUIModal } from 'components/controls/MUI/MUIModal';
import { Button } from 'components/controls/Button';
import NewApp from 'views/OAuthManagement/components/NewApp';
import AppTile from 'views/Dashboard/components/AppTile';
import AttestationTile from 'views/Dashboard/components/AttestationTile';
import { listDataBuilder } from 'common/utils/listDataBuilder';

const DeveloperDashboard: React.FC = () => {
  const history = useHistory();
  const currentUser = useSelector((state) => state.auth.user) as IUser;
  const oauthApps = useSelector((state) => state.oauthMngmt.oauthApps);
  const oauthAppCredentials = useSelector((state) => state.oauthMngmt.oauthAppCredentials);
  const authClients = useSelector((state) => state.oauthMngmt.authClients);
  const devAttestations = useSelector((state) => state.developerAttestations.attestations);
  const [listData, setListData] = React.useState<IListDataItem[]>([]);
  const [newAppModal, setNewAppModal] = React.useState(false);

  React.useEffect(() => {
    if (oauthApps.length > 0 && oauthAppCredentials.length > 0 && authClients.length > 0) {
      const appData = listDataBuilder({
        credentials: oauthAppCredentials,
        applications: oauthApps,
        clients: authClients,
        attestations: devAttestations,
        appDevelopers: [currentUser],
      }).map((item) => {
        return {
          ...item,
          sortBy: item.app.updated_date
            ? new Date(item.app.updated_date)
            : new Date(item.app.created_date || new Date()),
        };
      });
      setListData(appData);
    }
  }, [oauthApps, oauthAppCredentials, authClients]);

  const toggleNewApp = () => {
    setNewAppModal(!newAppModal);
  };
  const handleNewAppComplete = () => {
    toggleNewApp();
  };

  const handleGoToAppLibrary = () => {
    history.push('/applications');
  };
  const handleGoToDocLibrary = () => {
    console.log('Not Implemented Yet');
  };
  const handleGoToAttestations = () => {
    history.push('/attestation');
  };

  const renderAttestationTile = (attestation: IDeveloperAttestation) => {
    const secondaryData: IListDataItem | undefined = _.find(
      listData,
      (listItem) => listItem.app?.id === attestation.app_id,
    );
    return (
      <AttestationTile key={`attestation-${attestation.id}`} attestation={attestation} secondaryData={secondaryData} />
    );
  };

  return (
    <Grid container>
      {listData.length === 0 && (
        <Grid item xs={12} className={classes('alignTextCenter', css.emptyPanel)}>
          <h3>Register your first application</h3>
          <p>
            Each application is provided a unique set of API keys and configuration settings needed for making requests
            to the system.
          </p>
          <ActionTile standalone action={toggleNewApp} label="Register with an OAuth API" />
        </Grid>
      )}
      {listData.length > 0 && (
        <Grid item xs={12}>
          <Grid container className={css.sectionHeader}>
            <Grid item xs={8}>
              <h4>OAuth Credentials</h4>
              <p>
                Each application is provided a unique set of API keys and configuration settings needed for making
                requests to the system.
              </p>
            </Grid>
            <Grid item xs={4} className="alignTextRight">
              <Button onClick={handleGoToAppLibrary}>OAuth Management</Button>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <ActionTile
                className={classes(css.tile, css.appTile)}
                action={toggleNewApp}
                label="Register with an OAuth API"
              />
              {_.map(
                _.orderBy(listData, ['sortBy'], ['desc']),
                (listItem, index) =>
                  listItem.app &&
                  listItem.client &&
                  listItem.credential && <AppTile key={`oauth-cred-${index}`} item={listItem} />,
              )}
            </Grid>
          </Grid>
        </Grid>
      )}

      {devAttestations.length > 0 && (
        <Grid item xs={12}>
          <Grid container className={css.sectionHeader}>
            <Grid item xs={8}>
              <h4>Attestation Requests</h4>
              <p>Below are recent developer attestation requests for the applications you’ve created.</p>
            </Grid>
            <Grid item xs={4} className="alignTextRight">
              <Button onClick={handleGoToAttestations}>All Attestation Requests</Button>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              {_.map(_.orderBy(devAttestations, ['submission_date'], 'desc'), renderAttestationTile)}
            </Grid>
          </Grid>
        </Grid>
      )}

      <Grid item xs={12}>
        <Grid container className={css.sectionHeader}>
          <Grid item xs={8}>
            <h4>Document Library</h4>
          </Grid>
          <Grid item xs={4} className="alignTextRight">
            <Button onClick={handleGoToDocLibrary}>Document Library</Button>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <Tile
              title="Setting your application's privacy policy"
              bottomContent={
                <Button tertiary>
                  <Visibility /> Read More
                </Button>
              }
            />
            <Tile
              title="Resolving attestation issues"
              bottomContent={
                <Button tertiary>
                  <Visibility /> Read More
                </Button>
              }
            />
            <Tile
              title="Registering an application for production"
              bottomContent={
                <Button tertiary>
                  <Visibility /> Read More
                </Button>
              }
            />
            <Tile
              title="Editing a production application registration"
              bottomContent={
                <Button tertiary>
                  <Visibility /> Read More
                </Button>
              }
            />
          </Grid>
        </Grid>
      </Grid>

      <MUIModal width="md" allowClose open={newAppModal} handleClose={toggleNewApp}>
        <NewApp bindComplete={handleNewAppComplete} bindCancel={handleNewAppComplete} />
      </MUIModal>
    </Grid>
  );
};

export default DeveloperDashboard;
