import * as React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { devLibrarySideMenuBuilder } from 'common/utils/devLibrarySideMenuBuilder';
import { IUser } from 'common/interfaces/IUser';
import { TLayoutType } from 'components/AppFrame/interfaces/TLayoutType';
import AppFrame from 'components/AppFrame';
import 'components/AppFrame/app-frame.module.scss';

const Overview: React.FC = (props) => {
  const history = useHistory();
  const currentUser = useSelector((state) => state.auth.user) as IUser;

  const layoutType: TLayoutType =
    currentUser?.user_type === 'developer'
      ? 'developerPortal'
      : currentUser?.user_type === 'payer'
      ? 'payerPortal'
      : 'adminPortal';

  const sideMenuItems = devLibrarySideMenuBuilder({ active: 'overview', history });

  return (
    <AppFrame layoutType={layoutType} selectedMenuItem="DocLibrary" subMenuItems={sideMenuItems}>
      <Grid container>
        <Grid item xs={12}>
          <h2>Overview</h2>
          <p>
            The MiHIN Group’s InterOp Station® is a fully FHIR-enabled platform that facilitates broad healthcare data
            interoperability. It has been designed to provide HIE functionality required by the 21st Century Cures Act
            and the associated ONC/CMS Interoperability and Patient Access final rule with a minimum burden and maximum
            ease of use for stakeholders. The InterOp Station leverages the latest industry standards including FHIR®
            4.0.1 &amp; USCDI and adheres to CARIN Alliance Blue Button and Da Vinci implementation guides, including:
            Da Vinci Clinical Data Exchange, Da Vinci Plan Net, Da Vinci Payer Data Exchange US Drug Formulary, and Da
            Vinci Data Exchange for Quality Measures.
          </p>
          <p>
            The InterOp Station intakes a wide range of claims and clinical data types from various sources and converts
            them to FHIR resources that are housed in a cohesive, secure cloud environment. From there, a patient may
            request their data using a third-party app of their choice. Authorized payers may also exchange historical
            data with other payers.
          </p>
          <p>
            While originally focused on final rule requirements, the InterOp Station provides a foundational platform
            with wide-ranging potential because for the first time, all clinical and claims data available will be in
            one environment and in one format. This represents a huge leap forward in health information exchange and
            interoperability, and we look forward to conceptualizing and building the next wave of InterOp Station
            functionality with our stakeholder community.
          </p>
        </Grid>
      </Grid>
    </AppFrame>
  );
};

export default Overview;
