import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Formik, ErrorMessage, Field } from 'formik';
import { Grid, Radio, RadioGroup, FormControl, FormLabel, FormControlLabel, Divider } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Save } from '@material-ui/icons';
import { Button } from 'components/controls/Button';
import { IOAuthApp } from 'views/OAuthManagement/interfaces/IOAuthApp';
import { IAuthClient, clientOptions, grantOptions } from 'views/OAuthManagement/interfaces/IAuthClient';
import { IDeveloperAttestationModalProps } from './interfaces/IDeveloperAttestationModalProps';
import { IDeveloperAttestationModalValues } from './interfaces/IDeveloperAttestationModalValues';
import { IDeveloperAttestation } from './interfaces/IDeveloperAttestation';
import { IUser } from 'common/interfaces/IUser';
import { trimAllStringProperties } from 'common/utils/trimAllStringProperties';
import { getUserDisplayName } from 'common/utils/getUserDisplayName';
import css from 'components/AppFrame/app-frame.module.scss';
import formCSS from './submit-attestation.module.scss';
import classes from 'classnames';
import { saveDevAttestation, submitDevAttestation, getAttestations } from './redux/actions';
import _ from 'lodash';

const DeveloperAttestationRequest: React.FC<IDeveloperAttestationModalProps> = ({ bindComplete, attestationData }) => {
  const dispatch = useDispatch();
  const initialValues: IDeveloperAttestation = {
    attest_passwords_hidden: false,
    attest_data_security_safeguards: false,
    attest_minimum_scopes: false,
    attest_transmissions_encrypted: false,
    attest_not_malicious: false,
    attest_credentials_confidential_safeguards: false,
    attest_user_restrict_authorized_devices: false,
    attest_info_stored_elsewhere_notification: false,
    attest_only_device_stored_tokens: false,
    attest_qualified_tech_security_assessments: false,
    attest_discovered_vulnerabilities_remediated: false,
  };

  const [formValues, setFormValues] = useState(initialValues);

  const [sendError, setSendError] = React.useState('');

  const currentApp = useSelector((state) => state.oauthMngmt.currentApp) as IOAuthApp;
  const currentUser = useSelector((state) => state.auth.user) as IUser;

  const currentAttestation = useSelector(
    (state) =>
      attestationData ||
      _.find(state.developerAttestations?.attestations, (attestation) => attestation.app_id === currentApp.id),
  );

  useEffect(() => {
    dispatch(getAttestations());
  }, []);

  useEffect(() => {
    if (!!currentAttestation?.id) {
      setFormValues({
        ...formValues,
        ...currentAttestation,
      });
    } else {
      // bindComplete();
    }
  }, [currentAttestation]);

  const formPreprocessor = () => {
    const trimmedValues = trimAllStringProperties(formValues);
    const processedData = {
      ...trimmedValues,
      app_id: attestationData?.app_id || currentAttestation?.app_id || currentApp.id,
      developer_id: attestationData?.developer_id || currentAttestation?.developer_id || currentApp.username,
    };
    return processedData;
  };

  const handleSubmit = (values: IDeveloperAttestation, setSubmitting: any) => {
    const attestationData = formPreprocessor();
    dispatch(submitDevAttestation(attestationData)).then(() => {
      dispatch(getAttestations());
      bindComplete();
    });
    setSubmitting(false);
  };

  const handleSaveAttestationClick = () => {
    const attestationData = formPreprocessor();
    dispatch(saveDevAttestation(attestationData)).then(() => {
      dispatch(getAttestations());
      bindComplete();
    });
  };

  const parseRadioValue = (e) => {
    // This little bit of weirdness is brought to you by a formik quirk
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value === 'true',
    });
  };

  const AttestationRadio = ({ label, value, name, onChange }) => (
    <div style={{ width: '100%' }}>
      <FormControl component="fieldset" style={{ width: '100%' }}>
        {/* FIXME: this isn't correct MUI structure */}
        <div className={classes(formCSS.radioFieldSet)}>
          <RadioGroup aria-label={label} value={value} style={{ minWidth: '15%' }} row>
            <FormControlLabel
              name={name}
              onChange={onChange}
              value={true}
              control={<Radio color="default" />}
              label="Yes"
              labelPlacement="top"
            />
            <FormControlLabel
              name={name}
              onChange={onChange}
              value={false}
              control={<Radio color="default" />}
              label="No"
              labelPlacement="top"
            />
          </RadioGroup>
          <div className={classes(formCSS.radioFieldSetLabel)}>{label}</div>
        </div>
      </FormControl>
    </div>
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        <Formik
          initialValues={formValues}
          enableReinitialize={true}
          onSubmit={(values, { setSubmitting }) => handleSubmit(values, setSubmitting)}
        >
          {({ values, handleChange, handleBlur, handleSubmit, isSubmitting }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Grid container>
                  {!!sendError && (
                    <Grid item xs={12}>
                      <Alert severity="error">{sendError}</Alert>
                    </Grid>
                  )}
                  <>
                    <Grid item xs={12}>
                      <h4 className={css.modalHeader}>Application Attestation</h4>
                    </Grid>
                    <Grid item xs={12}>
                      <p>
                        To ensure that your application is ready to be registered in production, please look at your
                        application information below carefully and complete the attestation checklist below.
                      </p>
                    </Grid>

                    <Grid item xs={12}>
                      <label>Application Information</label>
                    </Grid>
                    <Grid container>
                      <Grid item xs={4} container>
                        <Grid item xs={12}>
                          Developed By:
                        </Grid>
                        <Grid item xs={12}>
                          {getUserDisplayName(currentUser)}
                        </Grid>
                        {currentApp.app_contacts && (
                          <Grid item xs={12}>
                            {currentApp.app_contacts}
                          </Grid>
                        )}
                      </Grid>
                      {currentApp.submission_date && (
                        <Grid item xs={4} container>
                          <Grid item xs={12}>
                            Submitted On:
                          </Grid>
                          <Grid item xs={12}>
                            {currentApp.submission_date}
                          </Grid>
                        </Grid>
                      )}
                    </Grid>

                    <Grid item xs={12}>
                      <hr />
                    </Grid>
                    <AttestationRadio
                      name="attest_credentials_confidential_safeguards"
                      label="Technical and procedural safeguards are in place to maintain the confidentiality of any developer keys, app keys, or other confidential information used for authentication (e.g. passwords, tokens, keys, etc)."
                      value={formValues.attest_credentials_confidential_safeguards}
                      onChange={parseRadioValue}
                    />
                    <AttestationRadio
                      name="attest_data_security_safeguards"
                      label="Data security safeguards are consistent with the responsible stewardship associated with protection of a user's health information against risks such as unauthorized access, use, alteration, or disclosure."
                      value={formValues.attest_data_security_safeguards}
                      onChange={parseRadioValue}
                    />
                    <AttestationRadio
                      name="attest_transmissions_encrypted"
                      label="Personal information, health information, keys, credentials, tokens, and other sensitive information is protected through a combination of mechanisms including, at a minimum: secure storage, use of a strong encryption algorithm both in transit and at rest (e.g. TLS 1.2+, AES 256), and accountability measures (e.g., access controls and audit logs)."
                      value={formValues.attest_transmissions_encrypted}
                      onChange={parseRadioValue}
                    />
                    <AttestationRadio
                      name="attest_minimum_scopes"
                      label="Access scopes requested by the app support the principle of minimum use."
                      value={formValues.attest_minimum_scopes}
                      onChange={parseRadioValue}
                    />
                    <AttestationRadio
                      name="attest_user_restrict_authorized_devices"
                      label="A user can restrict app usage to authorized devices."
                      value={formValues.attest_user_restrict_authorized_devices}
                      onChange={parseRadioValue}
                    />
                    <AttestationRadio
                      name="attest_info_stored_elsewhere_notification"
                      label="App notifies user if personal and health information is stored in locations other than their device where the app is installed."
                      value={formValues.attest_info_stored_elsewhere_notification}
                      onChange={parseRadioValue}
                    />
                    <AttestationRadio
                      name="attest_only_device_stored_tokens"
                      label="Authentication tokens are never stored in a location other than the device where the app is installed."
                      value={formValues.attest_only_device_stored_tokens}
                      onChange={parseRadioValue}
                    />
                    <AttestationRadio
                      name="attest_qualified_tech_security_assessments"
                      label="Technical security assessments are performed by a qualified and experienced party and are incorporated into the Software Development Life Cycle (SDLC) for the app."
                      value={formValues.attest_qualified_tech_security_assessments}
                      onChange={parseRadioValue}
                    />
                    <AttestationRadio
                      name="attest_discovered_vulnerabilities_remediated"
                      label="Internally and publicly discovered and reported app security vulnerabilities are acknowledged, prioritized, and remediated within a documented timeframe based on severity."
                      value={formValues.attest_discovered_vulnerabilities_remediated}
                      onChange={parseRadioValue}
                    />
                    <AttestationRadio
                      name="attest_not_malicious"
                      label="Technical and procedural safeguards are in place to ensure app does not transmit viruses, files, computer code, or programs that may harm or disrupt the normal operation of InterOp Station."
                      value={formValues.attest_not_malicious}
                      onChange={parseRadioValue}
                    />
                    <AttestationRadio
                      name="attest_passwords_hidden"
                      label="App does not store or surreptitiously discover user’s passwords for accessing InterOp Station."
                      value={formValues.attest_passwords_hidden}
                      onChange={parseRadioValue}
                    />
                    <Grid item xs={6} className={classes(css.modalActionButtons)}>
                      <Button type="button" tertiary onClick={handleSaveAttestationClick}>
                        <Save />
                        <span>Save Progress</span>
                      </Button>
                    </Grid>
                    <Grid item xs={6} className={classes('alignTextRight', css.modalActionButtons, css.formRow)}>
                      <Button type="button" secondary onClick={bindComplete}>
                        Cancel
                      </Button>
                      <Button type="submit">Submit Attestation</Button>
                    </Grid>
                  </>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default DeveloperAttestationRequest;
