import * as yup from 'yup';
import { passwordSchema } from 'views/Auth/schemas/password';
import { IResetPasswordValidationSchema } from 'views/Auth/ResetPassword/interfaces/IResetPasswordValidationSchema';

export const resetPasswordValidationSchema = yup.object<IResetPasswordValidationSchema>({
  new_password: passwordSchema(),
  confirm_password: yup
    .string()
    .required('Confirm New Password Required')
    .oneOf([yup.ref('new_password'), ''], 'Passwords must match'),
});

export default resetPasswordValidationSchema;
