import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Save, Edit, PanoramaOutlined } from '@material-ui/icons';
import moment from 'moment';
import { Button } from 'components/controls/Button';
import { AppBanner } from 'components/AppBanner';
import {
  updateAuthClient as updateAuthClientAction,
  getPayerCredential as getPayerCredentialAction,
} from 'views/OAuthManagement/redux/actions';
import { IOAuthApp } from 'views/OAuthManagement/interfaces/IOAuthApp';
import { IAuthClient } from 'views/OAuthManagement/interfaces/IAuthClient';
import css from './style.module.scss';

const AppBaseForm: React.FC = () => {
  const dispatch = useDispatch();
  const currentApp = useSelector((state) => state.oauthMngmt.currentApp) as IOAuthApp;
  const currentClient = useSelector((state) => state.oauthMngmt.currentClient) as IAuthClient;
  const [openTopBarEdit, setOpenTopBarEdit] = React.useState(false);
  const [sendError, setSendError] = React.useState('');
  const [appName, setAppName] = React.useState('');

  React.useEffect(() => {
    setAppName(currentClient.name || '');
  }, [currentClient]);

  const toggleTopBarEdit = () => {
    setOpenTopBarEdit(!openTopBarEdit);
  };

  const handleRequiredSave = () => {
    // save the auth client data
    dispatch(
      updateAuthClientAction({
        ...currentClient,
        name: appName,
      }),
    ).then((response) => {
      if (response.error) {
        setSendError(response.error.message);
      } else {
        dispatch(getPayerCredentialAction());
        toggleTopBarEdit();
      }
    });
  };

  return (
    <AppBanner>
      <Grid container>
        <Grid item xs={12} className={css.actions}>
          {openTopBarEdit ? (
            <>
              <Button type="link" className={css.cancel} onClick={toggleTopBarEdit}>
                Cancel
              </Button>
              <Button tertiary onClick={handleRequiredSave}>
                <Save /> Save
              </Button>
            </>
          ) : (
            <Button type="link" onClick={toggleTopBarEdit}>
              <Edit className={css.editLink} />
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid container className={css.topBar}>
        <Grid item xs={9}>
          {!!sendError && <Alert severity="error">{sendError}</Alert>}
          {openTopBarEdit ? (
            <input
              name="app_name"
              type="text"
              className={css.nameInput}
              value={appName}
              onChange={(e) => setAppName(e.target.value)}
            />
          ) : (
            <h2>{appName}</h2>
          )}
          {currentApp.updated_date && (
            <div className={css.lastUpdated}>
              <strong>Last Updated:</strong> {moment(currentApp.updated_date).format('MMM DD, YYYY, h:mm a')}
            </div>
          )}
        </Grid>
        {/* <Grid item xs={3} className={css.appImageRegion}>
          <PanoramaOutlined className={css.appImage} />
        </Grid> */}
      </Grid>
    </AppBanner>
  );
};

export default AppBaseForm;
