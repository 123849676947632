import { createAsyncThunk, createSlice, unwrapResult, createEntityAdapter } from '@reduxjs/toolkit';
import * as authActions from './actions';
import { IUser } from 'common/interfaces/IUser';

const userAPI = {
  fetchUserById: (userId) => userId,
};

export interface IAuthState {
  user?: IUser | null;
  entities: any;
  loading: string;
  currentRequestId?: number;
  error?: string | null;
}

const initialState: IAuthState = {
  entities: {},
  loading: 'idle',
  currentRequestId: undefined,
  error: null,
  user: null,
};

const authSlice = createSlice({
  name: 'Auth',
  initialState: initialState,
  reducers: {},
  // reducers: authReducers,
  extraReducers: (builder) => {
    builder.addCase(authActions.login.fulfilled, (state, { payload }) => {
      state.user = payload;
      state.loading = 'idle';
    });
    builder.addCase(authActions.login.pending, (state, { payload }) => {
      state.loading = 'pending';
    });
    builder.addCase(authActions.login.rejected, (state, { payload }) => {
      state.loading = 'idle';
    });

    builder.addCase(authActions.logout.fulfilled, (state) => {
      state.user = null;
      state.loading = 'idle';
    });
    builder.addCase(authActions.logout.pending, (state, { payload }) => {
      state.user = null;
      state.loading = 'pending';
    });
    builder.addCase(authActions.logout.rejected, (state, { payload }) => {
      state.user = null;
      state.loading = 'idle';
    });

    builder.addCase(authActions.signup.fulfilled, (state, { payload }) => {
      state.loading = 'idle';
    });
    builder.addCase(authActions.signup.pending, (state, { payload }) => {
      state.loading = 'pending';
    });
    builder.addCase(authActions.signup.rejected, (state, { payload }) => {
      state.loading = 'idle';
    });

    builder.addCase(authActions.acceptTermsOfService.fulfilled, (state, { payload }) => {
      state.loading = 'idle';
    });
    builder.addCase(authActions.acceptTermsOfService.pending, (state, { payload }) => {
      state.loading = 'pending';
    });
    builder.addCase(authActions.acceptTermsOfService.rejected, (state, { payload }) => {
      state.loading = 'idle';
    });

    builder.addCase(authActions.forgotPassword.fulfilled, (state, { payload }) => {
      state.loading = 'idle';
    });
    builder.addCase(authActions.forgotPassword.pending, (state, { payload }) => {
      state.loading = 'pending';
    });
    builder.addCase(authActions.forgotPassword.rejected, (state, { payload }) => {
      state.loading = 'idle';
    });

    builder.addCase(authActions.resetPassword.fulfilled, (state, { payload }) => {
      state.loading = 'idle';
    });
    builder.addCase(authActions.resetPassword.pending, (state, { payload }) => {
      state.loading = 'pending';
    });
    builder.addCase(authActions.resetPassword.rejected, (state, { payload }) => {
      state.loading = 'idle';
    });

    builder.addCase(authActions.getAuth.fulfilled, (state, { payload }) => {
      state.user = payload;
      state.loading = 'idle';
    });
    builder.addCase(authActions.getAuth.pending, (state, { payload }) => {
      state.loading = 'pending';
    });
    builder.addCase(authActions.getAuth.rejected, (state, { payload }) => {
      state.loading = 'idle';
    });

    builder.addCase(authActions.updateUser.fulfilled, (state, { payload }) => {
      state.loading = 'idle';
    });
    builder.addCase(authActions.updateUser.pending, (state, { payload }) => {
      state.loading = 'pending';
    });
    builder.addCase(authActions.updateUser.rejected, (state, { payload }) => {
      state.loading = 'idle';
    });
  },
});

export default authSlice;
