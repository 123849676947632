import * as React from 'react';
import { Link } from 'react-router-dom';
import css from './breadcrumbs.module.scss';
import { TNavigationItems } from 'common/interfaces/INavigation';

interface IBreadcrumbs {
  breadcrumbItems: TNavigationItems;
}

export const Breadcrumbs: React.FC<IBreadcrumbs> = (props) => {
  const { breadcrumbItems } = props;
  return (
    <ul className={css.list}>
      {breadcrumbItems &&
        breadcrumbItems.map((item, index) => {
          return (
            <li key={index} className={css.listItem}>
              {breadcrumbItems.length == index + 1 ? item.label : <Link to={item.location}>{item.label}</Link>}
            </li>
          );
        })}
    </ul>
  );
};
