import { IMeasureReport } from '@ahryman40k/ts-fhir-types/lib/R4';
import { Observable } from 'rxjs';
import { getCurrentAuthHeaders } from 'common/utils/getCurrentAuthHeaders';
import { API_URL } from 'settings'

export interface IFhirService {
  getMeasureReportsByDate(start: string, end: string): Observable<IMeasureReport[]>;
}

export const getMeasureReportsByDate = async (start, end) => {
  const headers = await getCurrentAuthHeaders('json');
  const request = {
    method: "GET",
    headers,
  }
  const response = await fetch(`${API_URL}/deqm-fhir/bcbsm/MeasureReport?period=ge${start}&period=le${end}`, request);
  return (await response.json())
};
