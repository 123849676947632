import React from 'react';
import Routes from './routes/Routes';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';

import { store } from './store';

import './App.scss';
import { Router } from 'react-router';
import awsConfig from 'config/awsSetup';
import Amplify, { Auth as awsAmplifyAuth } from 'aws-amplify';

Amplify.configure(awsConfig);

const persistor = persistStore(store);

const createHistory = require('history').createBrowserHistory;
const history = createHistory();

window.addEventListener('pagehide', (event) => {
  try {
    awsAmplifyAuth.signOut();
  } catch (err) {}
});

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Router history={history}>
          <Routes />
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
