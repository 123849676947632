import { createAsyncThunk, createSlice, unwrapResult, createEntityAdapter } from '@reduxjs/toolkit';
import * as devAttestationActions from './actions';
import { IDeveloperAttestation } from 'views/DeveloperAttestation/interfaces/IDeveloperAttestation';
import _ from 'lodash'
import AttestationList from '../AttestationList';
const userAPI = {
  fetchUserById: (userId) => userId,
};

export interface IDevAttestationState {
  loading: string;
  error?: string | null;
  attestations?: IDeveloperAttestation[];
}

const initialState: IDevAttestationState = {
  loading: 'idle',
  error: null,
  attestations: []
};

const devAttestationSlice = createSlice({
  name: 'DevAttestation',
  initialState: initialState,
  reducers: {},
  // reducers: authReducers,
  extraReducers: (builder) => {
    builder.addCase(devAttestationActions.getAttestations.fulfilled, (state, { payload }) => {
      state.attestations = payload;
      state.loading = 'idle';
    });
    builder.addCase(devAttestationActions.getAttestations.pending, (state, { payload }) => {
      state.loading = 'pending';
    });
    builder.addCase(devAttestationActions.getAttestations.rejected, (state, { payload }) => {
      state.loading = 'idle';
    });

    builder.addCase(devAttestationActions.submitDevAttestation.fulfilled, (state, { payload }) => {
      const newAttestations = _.map(state.attestations, (attestation) => {
        return payload.id === (attestation || {}).id ? payload : attestation;
        // ...state.attestations, newAttestations;
      })
      state.attestations = newAttestations;
      state.loading = 'idle';
    });
    builder.addCase(devAttestationActions.submitDevAttestation.pending, (state, { payload }) => {
      state.loading = 'pending';
    });
    builder.addCase(devAttestationActions.submitDevAttestation.rejected, (state, { payload }) => {
      state.loading = 'idle';
    });

    builder.addCase(devAttestationActions.saveDevAttestation.fulfilled, (state, { payload }) => {
        const newAttestations = _.map(state.attestations, (attestation) => {
          
        return (payload.id === (attestation || {}).id) ? payload : attestation;
        // ...state.attestations, newAttestations;
        })
      state.attestations = newAttestations;
      state.loading = 'idle';
    });
    builder.addCase(devAttestationActions.saveDevAttestation.pending, (state, { payload }) => {
      state.loading = 'pending';
    });
    builder.addCase(devAttestationActions.saveDevAttestation.rejected, (state, { payload }) => {
      state.loading = 'idle';
    });
  },
});

export default devAttestationSlice;
