import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Formik, ErrorMessage } from 'formik';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Checkbox, FormControlLabel, FormHelperText } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Button } from 'components/controls/Button';
import { trimAllStringProperties } from 'common/utils/trimAllStringProperties';
import { MUIModal } from 'components/controls/MUI/MUIModal';
import { ISignupProps } from './interfaces/ISignupProps';
import { ISignupRouteProps } from './interfaces/ISignupRouteProps';
import { ISignupValues } from './interfaces/ISignupValues';
import { IUser } from 'common/interfaces/IUser';
import { signupValidationSchema } from './schemas/signupValidationSchema';
import { signup as signupAction, completeSignup as completeSignupAction } from 'views/Auth/redux/actions';
import AppFrame from 'components/AppFrame';
import css from 'components/AppFrame/app-frame.module.scss';
import TermsOfServiceModal from './TermsOfService/TermsOfServiceModal';
import classes from 'classnames';
import shieldIcon from 'assets/icons/icon-shield.svg';
import { Auth } from 'aws-amplify';
import { userInfo } from 'os';

const Signup: React.FC<ISignupProps & RouteComponentProps<ISignupRouteProps>> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.user) as IUser;
  const [termsOfServiceModalVisible, setTermsOfServiceModalVisible] = useState(false);
  const [confirmationModalVisible, setConfirmationModalVisible] = useState(false);
  const initialValues: ISignupValues = {
    email: currentUser ? currentUser.email : '',
    username: '',
    password: '',
    confirm_password: '',
    terms_accepted: 0,
  };

  useEffect(() => {
    Auth.currentUserInfo().then((user) => {
      if (user && currentUser && currentUser.terms_accepted) {
        history.push('/');
      }
    });
  }, []);

  const [sendError, setSendError] = React.useState('');

  const handleSubmit = (values: ISignupValues, setSubmitting: Function) => {
    setSubmitting(true);
    const trimmedValues = trimAllStringProperties(values);
    const processedValues = {
      ...trimmedValues,
      terms_accepted: !!values.terms_accepted ? 1 : 0,
    };
    if (currentUser) {
      dispatch(completeSignupAction({ ...processedValues, currentUser })).then((response) => {
        if (response.error) {
          if (
            response.error.message === 'AliasExistsException' ||
            response.error.message === 'UsernameExistsException'
          ) {
            setSendError('That username already exists, please enter a different one.');
          } else if (response.error.message === 'InvalidPasswordException') {
            setSendError('Your password is invalid, please try another one.');
          } else {
            setSendError(response.error.message);
          }
        }
        setSubmitting(false);
      });
    } else {
      dispatch(signupAction(processedValues)).then((user) => {
        if (user.error) {
          if (user.error.message === 'AliasExistsException' || user.error.message === 'UsernameExistsException') {
            setSendError('That username already exists, please enter a different one.');
          } else if (user.error.message === 'InvalidPasswordException') {
            setSendError('Your password is invalid, please try another one.');
          } else {
            setSendError('There was an issue while trying to sign you up, please try again.');
          }
        } else {
          toggleConfirmationModal();
        }
        setSubmitting(false);
      });
    }
  };

  const handleSignInNavClick = () => {
    history.push('/login');
  };

  const toggleTermsOfServiceModal = () => setTermsOfServiceModalVisible(!termsOfServiceModalVisible);
  const toggleConfirmationModal = () => setConfirmationModalVisible(!confirmationModalVisible);

  const handleAcceptTermsComplete = (setFieldValue: Function) => {
    setFieldValue('terms_accepted', 1);
    setTermsOfServiceModalVisible(false);
  };

  const handleAcceptTermsCancel = () => setTermsOfServiceModalVisible(false);

  const handleEmailSupport = () => {
    window.open('mailto:helpdesk@mihin.org?subject=Open%20A%20Payer%20Account');
  };

  return (
    <AppFrame layoutType={!currentUser ? 'authCenterFrame' : 'completeSignupFrame'}>
      <Grid container>
        <Grid item xs={!currentUser ? 7 : 12}>
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={(values, { setSubmitting }) => handleSubmit(values, setSubmitting)}
            validationSchema={signupValidationSchema}
          >
            {({ values, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting }) => {
              return (
                <form onSubmit={handleSubmit} autoComplete="off">
                  <Grid container>
                    <Grid item xs={12}>
                      {currentUser ? (
                        <>
                          <h2>Create your account</h2>
                          <div className={css.subtitle}>
                            Welcome to InterOP Station&trade;! Let’s finish creating your account and get you started.
                          </div>
                        </>
                      ) : (
                        <>
                          <h2>Sign Up</h2>
                          <div className={css.subtitle}>
                            Welcome to InterOP Station&trade;! Create an account and let's get started.
                          </div>
                        </>
                      )}
                    </Grid>
                    {sendError !== '' && (
                      <Grid item xs={12}>
                        <Alert severity="error">{sendError}</Alert>
                      </Grid>
                    )}
                    <Grid item xs={12} className={css.formRow}>
                      <label htmlFor="email">Email</label>
                      {currentUser ? (
                        <div className={css.subtitle}>{values.email}</div>
                      ) : (
                        <>
                          <input
                            id="email"
                            placeholder="Enter email"
                            type="text"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            autoComplete="off"
                          />
                          <ErrorMessage name="email">
                            {(msg) => <div className="input-feedback">{msg}</div>}
                          </ErrorMessage>
                        </>
                      )}
                    </Grid>
                    <Grid item xs={12} className={css.formRow}>
                      <label htmlFor="username">Create Username</label>
                      <input
                        id="username"
                        placeholder="Enter username"
                        type="text"
                        value={values.username}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autoComplete="off"
                      />
                      <ErrorMessage name="username">
                        {(msg) => <div className="input-feedback">{msg}</div>}
                      </ErrorMessage>
                    </Grid>
                    <Grid item xs={12} className={css.formRow}>
                      <label className={css.passwordLabel} htmlFor="password">
                        Create Password
                      </label>
                      <input
                        id="password"
                        className={css.passwordInput}
                        placeholder="Enter password"
                        type="password"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autoComplete="off"
                      />
                      <FormHelperText className={css.passwordRequirementsText}>
                        Must be at least 10 characters long, contain numbers, uppercase and lowercase letters, and
                        special characters.
                      </FormHelperText>
                      <ErrorMessage name="password">
                        {(msg) => <div className="input-feedback">{msg}</div>}
                      </ErrorMessage>
                    </Grid>
                    <Grid item xs={12} className={css.formRow}>
                      <label className={css.passwordLabel} htmlFor="new_password">
                        Confirm Password
                      </label>
                      <input
                        id="confirm_password"
                        placeholder="Confirm password"
                        type="password"
                        value={values.confirm_password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autoComplete="off"
                      />
                      <ErrorMessage name="confirm_password">
                        {(msg) => <div className="input-feedback">{msg}</div>}
                      </ErrorMessage>
                    </Grid>
                    <Grid item xs={12} className={classes(css.formRow)}>
                      <FormControlLabel
                        name="terms_accepted"
                        onChange={handleChange}
                        checked={!!values.terms_accepted}
                        control={<Checkbox />}
                        classes={{ label: 'inline' }}
                        label={
                          <div>
                            I accept the{' '}
                            <Button type="link" onClick={toggleTermsOfServiceModal}>
                              Terms of Service
                            </Button>
                          </div>
                        }
                      />
                      <ErrorMessage name="terms_accepted">
                        {(msg) => <div className="input-feedback">{msg}</div>}
                      </ErrorMessage>
                    </Grid>
                    <Grid item xs={6} className={css.formRow}>
                      <Button
                        type="submit"
                        disabled={isSubmitting}
                        isLoading={isSubmitting}
                        loadingText="Creating account..."
                      >
                        Create My Account
                      </Button>
                    </Grid>
                    <Grid item xs={6} className={css.signUpSignInArea}>
                      Already have an account?
                      <Button type="link" className={css.signUpSignInLink} onClick={handleSignInNavClick}>
                        Sign in
                      </Button>
                    </Grid>
                  </Grid>
                  <MUIModal
                    width="lg"
                    allowClose
                    open={termsOfServiceModalVisible}
                    handleClose={toggleTermsOfServiceModal}
                  >
                    <TermsOfServiceModal
                      bindComplete={() => {
                        handleAcceptTermsComplete(setFieldValue);
                      }}
                    />
                  </MUIModal>
                  <MUIModal width="md" allowClose open={confirmationModalVisible} handleClose={toggleConfirmationModal}>
                    <Grid container>
                      <Grid item xs={12} className="alignTextCenter">
                        <h3 className={css.modalHeader}>Your account has been created.</h3>
                        <p className={css.subtitle}>
                          We've sent you an email to confirm your account. You will need to confirm your account before
                          you can log in.
                        </p>
                        <Button type="button" onClick={handleSignInNavClick} secondary>
                          Back To Login
                        </Button>
                      </Grid>
                    </Grid>
                  </MUIModal>
                </form>
              );
            }}
          </Formik>
        </Grid>
        {!currentUser && (
          <Grid item xs={5} className={css.payerSection}>
            <div className={css.question}>
              <img className={css.shieldIcon} src={shieldIcon} />
              <div className={css.questionContent}>Are you a payer and looking to open an account?</div>
            </div>
            <p className={css.instruction}>
              Thank you for your interest! We welcome payers to join our community for Payer-to-Payer data exchange. To
              start your account set up process as a payer, please contact us.
            </p>
            <Button type="button" onClick={handleEmailSupport} className={css.emailButton}>
              Email MiHIN Support
            </Button>
          </Grid>
        )}
      </Grid>
    </AppFrame>
  );
};

export default Signup;
