import * as React from 'react';
import css from './button.module.scss';
import classes from 'classnames';

interface IButtonProps {
  onClick?: any;
  disabled?: boolean;
  type?: 'button' | 'reset' | 'submit' | 'link';
  isLoading?: boolean;
  loadingText?: string;
  className?: string;
  secondary?: boolean;
  tertiary?: boolean;
  notice?: boolean;
}

export const Button: React.FC<IButtonProps> = (props) => {
  const { children, onClick, disabled, type, isLoading, loadingText, secondary, tertiary, notice, className } = props;
  return (
    <button
      disabled={disabled || isLoading}
      type={type !== 'link' ? type : 'button'}
      onClick={(event) => {
        if (onClick) {
          onClick(event);
        }
      }}
      className={classes(
        css.button,
        { [css.isLoading]: isLoading },
        { [css.link]: type === 'link' },
        { [css.secondary]: secondary },
        { [css.tertiary]: tertiary },
        { [css.notice]: notice },
        className,
      )}
    >
      {isLoading ? <>{loadingText ? loadingText : 'Loading...'}</> : children}
    </button>
  );
};
