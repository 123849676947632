import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { List, ListItem, ListItemText, Collapse } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { TMenuItemKey } from './interfaces/TMenuItemKey';
import { ISideMenuProps } from './interfaces/ISideMenuProps';
import classes from 'classnames';
import css from './side-menu.module.scss';
import { Button } from 'components/controls/Button';
import { IUser } from 'common/interfaces/IUser';

export const SideMenu: React.FC<ISideMenuProps> = ({ presetSelectedMenuItem, presetSelectedMenuSubItems }) => {
  const history = useHistory();
  const currentUser = useSelector((state) => state.auth.user) as IUser;
  const [selectedMenuItem, setSelectedMenuItem] = React.useState<TMenuItemKey>();

  React.useEffect(() => {
    setSelectedMenuItem(presetSelectedMenuItem);
  }, [presetSelectedMenuItem]);

  const handleMenuItemClick = (menuItemKey: TMenuItemKey, mainURL?: string) => {
    if ((menuItemKey !== presetSelectedMenuItem || presetSelectedMenuSubItems === undefined) && mainURL !== undefined) {
      history.push(mainURL);
    } else {
      if (menuItemKey === selectedMenuItem) {
        setSelectedMenuItem(undefined);
      } else {
        setSelectedMenuItem(menuItemKey);
      }
    }
  };

  const presetSubMenu = (
    <List component="div" disablePadding>
      {presetSelectedMenuSubItems?.map((item, index) => (
        <ListItem button key={`list-item-button-${index}`}>
          <ListItemText onClick={() => (item.action ? item.action() : null)} className={item.active ? css.active : ''}>
            {item.icon || null} {item.label}
          </ListItemText>
        </ListItem>
      ))}
    </List>
  );

  return (
    <>
      {currentUser && (
        <List component="nav" className={css.menuList}>
          {currentUser.user_type === 'external-payer' ? (
          <>
            <ListItem
              button
              onClick={() => {
                handleMenuItemClick('PayerInformation', '/external-payer');
                history.push('/external-payer');
              }}
              className={classes(css.menuItem, { [css.selected]: selectedMenuItem === 'PayerInformation' })}
            >
              <ListItemText primary={'Payer Information'} />
              {selectedMenuItem === 'PayerInformation' ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={selectedMenuItem === 'PayerInformation'} timeout="auto" unmountOnExit className={css.subMenu}>
              {presetSubMenu}
            </Collapse>
          </>
          ) : (
          <>
            <ListItem
              button
              onClick={() => {
                handleMenuItemClick('OAuthManagement', '/applications');
                history.push('/applications');
              }}
              className={classes(css.menuItem, { [css.selected]: selectedMenuItem === 'OAuthManagement' })}
            >
              <ListItemText primary={currentUser.user_type === 'developer' ? 'OAuth Management' : 'Applications'} />
              {selectedMenuItem === 'OAuthManagement' ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={selectedMenuItem === 'OAuthManagement'} timeout="auto" unmountOnExit className={css.subMenu}>
              {presetSubMenu}
            </Collapse>
          </>
          )}
          {currentUser.user_type === 'developer' && (
            <>
              <ListItem
                button
                onClick={() => {
                  handleMenuItemClick('DevAttestation', '/attestation');
                  history.push('/attestation');
                }}
                className={classes(css.menuItem, { [css.selected]: selectedMenuItem === 'DevAttestation' })}
              >
                <ListItemText primary="Attestation Request" />
                {selectedMenuItem === 'DevAttestation' ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={selectedMenuItem === 'DevAttestation'} timeout="auto" unmountOnExit className={css.subMenu}>
                {presetSubMenu}
              </Collapse>
            </>
          )}

          {(currentUser.user_type === 'mihin-admin' || currentUser.user_type === 'payer') && (
            <>
              <ListItem
                button
                onClick={() =>
                  handleMenuItemClick(
                    'OrgManagement',
                    currentUser.user_type === 'mihin-admin' ? '/orgs' : `/orgs/${currentUser.organization_id}`,
                  )
                }
                className={classes(css.menuItem, { [css.selected]: selectedMenuItem === 'OrgManagement' })}
              >
                <ListItemText
                  primary={currentUser.user_type === 'mihin-admin' ? 'Organization Management' : 'User Management'}
                />
                {selectedMenuItem === 'OrgManagement' ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={selectedMenuItem === 'OrgManagement'} timeout="auto" unmountOnExit className={css.subMenu}>
                {presetSubMenu}
              </Collapse>

              {currentUser.user_type === 'mihin-admin' && (
                <>
                  <ListItem
                    button
                    onClick={() => {
                      handleMenuItemClick('ApproveAttestation', '/approve-attestations');
                      history.push('/approve-attestations');
                    }}
                    className={classes(css.menuItem, { [css.selected]: selectedMenuItem === 'ApproveAttestation' })}
                  >
                    <ListItemText primary="Approve Attestation" />
                    {selectedMenuItem === 'ApproveAttestation' ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse
                    in={selectedMenuItem === 'ApproveAttestation'}
                    timeout="auto"
                    unmountOnExit
                    className={css.subMenu}
                  >
                    {presetSubMenu}
                  </Collapse>
                </>
              )}
            </>
          )}
          
          {currentUser.user_type === 'payer' && (
            <>
              <ListItem
                button
                onClick={() => {
                  handleMenuItemClick('Monitoring', '/monitoring');
                  history.push('/monitoring');
                }}
                className={classes(css.menuItem, { [css.selected]: selectedMenuItem === 'Monitoring' })}
              >
                <ListItemText primary="Monitoring" />
                {selectedMenuItem === 'Monitoring' ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={selectedMenuItem === 'Monitoring'} timeout="auto" unmountOnExit className={css.subMenu}>
                {presetSubMenu}
              </Collapse>
            </>
          )}
          <ListItem
            button
            onClick={() => handleMenuItemClick('DocLibrary', '/document-library')}
            className={classes(css.menuItem, { [css.selected]: selectedMenuItem === 'DocLibrary' })}
          >
            <ListItemText primary="Document Library" />
            {selectedMenuItem === 'DocLibrary' ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={selectedMenuItem === 'DocLibrary'} timeout="auto" unmountOnExit className={css.subMenu}>
            {presetSubMenu}
          </Collapse>
        </List>
      )}
      <div className={css.trademarkAndTermsRegion}>
        <div className={css.trademarkMsg}>
          HL7, FHIR and the [FLAME] mark are the registered trademarks of Health Level Seven International. Use of these
          trademarks do not constitute an endorsement from HL7.
        </div>
        <div className={css.terms}>
          <Button type="link">Terms</Button>
        </div>
      </div>
    </>
  );
};
