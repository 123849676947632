import * as React from 'react';
import { Grid, FormHelperText } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Formik, ErrorMessage } from 'formik';
import classes from 'classnames';
import { Auth } from 'aws-amplify';
import { Button } from 'components/controls/Button';
import { IAccountModalProps } from 'views/AccountSettings/interfaces/IAccountModalProps';
import { IChangePasswordValues } from 'views/AccountSettings/interfaces/IChangePasswordValues';
import { userChangePasswordValidationSchema } from 'views/AccountSettings/schemas/userChangePasswordValidationSchema';
import { MUIModal } from 'components/controls/MUI/MUIModal';
import ForgotPassword from 'views/Auth/ForgotPassword';
import css from 'components/AppFrame/app-frame.module.scss';

const ChangeUserPassword: React.FC<IAccountModalProps> = ({ bindComplete, bindCancel }) => {
  const [forgotPasswordModal, setForgotPasswordModal] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  const initialValues: IChangePasswordValues = {
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  };

  const toggleForgotPassword = () => {
    setForgotPasswordModal(!forgotPasswordModal);
  };
  const handleForgotPasswordComplete = () => {
    toggleForgotPassword();
  };

  const handleSubmit = async (values: IChangePasswordValues, setSubmitting: any) => {
    const oldPassword = values.currentPassword;
    const newPassword = values.newPassword;

    Auth.currentUserPoolUser().then((user) => {
      Auth.changePassword(user, oldPassword, newPassword)
        .then((resp) => {
          if (resp === 'SUCCESS') {
            bindComplete();
          } else {
            setErrorMessage('There was an issue trying to reset your password, please try again.');
          }
        })
        .catch((resp) => {
          if (resp.code === 'InvalidParameterException') {
            setErrorMessage(
              'Your password must be at least 10 characters long, and include uppercase letters, lowercase letters, special characters, and numbers.',
            );
          } else if (resp.code == 'NotAuthorizedException') {
            setErrorMessage('You entered the wrong password for your account.');
          } else {
            setErrorMessage('There was an issue trying to reset your password, please try again.');
          }
        });
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={(values, { setSubmitting }) => handleSubmit(values, setSubmitting)}
      validationSchema={userChangePasswordValidationSchema}
    >
      {({ values, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Grid container>
              <Grid item xs={12}>
                <h3>Change password</h3>
                <div className="subtitle">
                  Enter your existing password and new one below. It's a good idea to use a strong password that you're
                  not using elsewhere.
                </div>
              </Grid>
              {!!errorMessage && (
                <Grid item xs={12}>
                  <Alert severity="error">{errorMessage}</Alert>
                </Grid>
              )}
              <Grid item xs={12} className={css.formRow}>
                <Button type="link" className={css.accountSettingsForgotPassword} onClick={toggleForgotPassword}>
                  Forgot Password?
                </Button>
                <label htmlFor="currentPassword">Current Password</label>
                <input
                  id="currentPassword"
                  placeholder="Enter your current password"
                  type="password"
                  value={values.currentPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorMessage name="currentPassword">
                  {(msg) => <div className="input-feedback">{msg}</div>}
                </ErrorMessage>
              </Grid>
              <Grid item xs={12} className={css.formRow}>
                <label htmlFor="newPassword">New Password</label>
                <input
                  id="newPassword"
                  className={css.passwordInput}
                  placeholder="Enter your new password"
                  type="password"
                  value={values.newPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <FormHelperText className={css.passwordRequirementsText}>
                  Must be at least 10 characters long, contain numbers, uppercase and lowercase letters, and special
                  characters.
                </FormHelperText>
                <ErrorMessage name="newPassword">{(msg) => <div className="input-feedback">{msg}</div>}</ErrorMessage>
              </Grid>
              <Grid item xs={12} className={css.formRow}>
                <label htmlFor="confirmPassword">Confirm New Password</label>
                <input
                  id="confirmPassword"
                  placeholder="Confirm your new password"
                  type="password"
                  value={values.confirmPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorMessage name="confirmPassword">
                  {(msg) => <div className="input-feedback">{msg}</div>}
                </ErrorMessage>
              </Grid>
              <Grid item xs={12} className={classes('alignTextRight', css.modalActionButtons)}>
                <Button type="button" secondary onClick={bindCancel}>
                  Cancel
                </Button>
                <Button type="submit">Send</Button>
              </Grid>
            </Grid>
            <MUIModal width="md" allowClose open={forgotPasswordModal} handleClose={toggleForgotPassword}>
              <ForgotPassword bindComplete={handleForgotPasswordComplete} forAccountSettings={true} />
            </MUIModal>
          </form>
        );
      }}
    </Formik>
  );
};

export default ChangeUserPassword;
