import moment from 'moment';
import React from 'react';
import { withStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import JSONPretty from 'react-json-pretty';

interface IDEQMPatientDetailProps {
  report: any;
}

const DEQMPatientDetailView: React.FC<IDEQMPatientDetailProps> = ({ report }) => {
  const StyledTableCell = withStyles((theme: Theme) => ({
    head: {
      backgroundColor: "#4473c3",
      color: "#d9e2f2",
      fontSize: 16,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme: Theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

  const useStyles = makeStyles({
    table: {
      minWidth: 700,
    },
  });
  const classes = useStyles();
  return <div id="patient-detail">
    <br />
    <br />
    <TableContainer component={Paper}>

      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <StyledTableCell>Patient</StyledTableCell>
            <StyledTableCell>Calculated Measure</StyledTableCell>
            <StyledTableCell>Reporting Period Date Range</StyledTableCell>
            <StyledTableCell>Additional Data</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>

          <StyledTableRow key={report.subject?.reference}>
            <StyledTableCell>{report.patientFirstName ? `${report.patientFirstName} ${report.patientLastName}`: report.subject?.reference}</StyledTableCell>
            <StyledTableCell align="center">{report.group ? (report.group[0]?.measureScore?.value) : ''}</StyledTableCell>
            <StyledTableCell align="right">
              {moment(report.period.start).format('yyyy-MM-DD')} - {moment(report.period.end).format('yyyy-MM-DD')}
            </StyledTableCell>
            <StyledTableCell> <JSONPretty id="json-pretty" data={report.contained}></JSONPretty> </StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  </div>
};

export default DEQMPatientDetailView;
