import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Formik, ErrorMessage } from 'formik';
import { Grid, Radio, RadioGroup, CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Button } from 'components/controls/Button';
import { IUser } from 'common/interfaces/IUser';
import { IOAuthApp } from 'views/OAuthManagement/interfaces/IOAuthApp';
import { IAuthClient, clientOptions, grantOptions } from 'views/OAuthManagement/interfaces/IAuthClient';
import { IOAuthModalProps } from 'views/OAuthManagement/interfaces/IOAuthModalProps';
import { INewAppValues } from 'views/OAuthManagement/interfaces/INewAppValues';
import { IFileMetadata } from 'common/interfaces/IFileMetadata';
import { trimAllStringProperties } from 'common/utils/trimAllStringProperties';
import Select from 'react-select';
import { OAuthScopeSelector } from 'components/controls/OAuthScopeSelector';
import css from 'components/AppFrame/app-frame.module.scss';
import formCSS from './new-app.module.scss';
import classes from 'classnames';
import {
  createNewApp as createNewAppAction,
  getOAuthApps as getOAuthAppsAction,
  getOAuthAppCredentials as getOAuthAppCredentialsAction,
  getAuthClients as getAuthClientsAction,
} from 'views/OAuthManagement/redux/actions';
import doItLaterIcon from 'assets/icons/icon-do-it-later.svg';
import uploadNowIcon from 'assets/icons/icon-upload-now.svg';

/*
2020/12/15 - Defaulting the grant type to Authorization Code (first in array).
For Jira Ticket NTRP-398
*/

const NewApp: React.FC<IOAuthModalProps> = ({ bindComplete }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentUser = useSelector((state) => state.auth.user) as IUser;

  const initialValues: INewAppValues = { name: '', clientType: '', grantType: '', redirectURIs: '', scope: '' };
  const [currentStep, setCurrentStep] = React.useState(1);
  const [clientType, setClientType] = React.useState('');
  const [grantType, setGrantType] = React.useState(grantOptions[0].value);
  const [appScope, setScope] = React.useState('');
  const [stepOneCompleted, setStepOneCompleted] = React.useState(false);
  const [stepTwoCompleted, setStepTwoCompleted] = React.useState(false);
  const [privacyPolicyOption, setPrivacyPolicyOption] = React.useState('');
  const [privacyPolicyFileObject, setPrivacyPolicyFileObject] = React.useState(null);
  const [privacyPolicyFileMetadata, setPrivacyPolicyFileMetadata] = React.useState<IFileMetadata>();
  const [isSending, setIsSending] = React.useState(false);
  const [sendError, setSendError] = React.useState('');

  React.useEffect(() => {
    checkStepTwo();
  }, [privacyPolicyOption]);

  React.useEffect(() => {
    checkStepTwo();
  }, [privacyPolicyFileObject]);

  const handleClientTypeChange = (selectedValue) => {
    setClientType(selectedValue.value);
  };
  const handleGrantTypeChange = (selectedValue) => {
    setGrantType(selectedValue.value);
  };
  const handleScopeChange = (selectedValue) => {
    setScope(selectedValue);
  };
  const handleFileChange = (event) => {
    setPrivacyPolicyFileObject(event.target.files[0]);
    setPrivacyPolicyFileMetadata(event.target.files[0] as IFileMetadata);
  };

  const handleSubmit = (values: INewAppValues, setSubmitting: any) => {
    setIsSending(true);
    const trimmedValues = trimAllStringProperties(values);

    const oauthApp: IOAuthApp = {
      status: 1,
      image_url: '',
      documentation_url: '',
      org_website: '',
      app_descr: '',
      app_contacts: '',
      support_email: '',
      external_payer: false,
    };

    const defaultRedirectURI =
      trimmedValues.redirectURIs.indexOf(',') === -1
        ? trimmedValues.redirectURIs
        : trimmedValues.redirectURIs.split(',')[0];

    const clientApp: IAuthClient = {
      name: trimmedValues.name,
      client_type: +clientType,
      grant_type: +grantType,
      redirect_uris: trimmedValues.redirectURIs,
      default_redirect_uri: defaultRedirectURI,
      scope: appScope,
      client_category: 'development',
      organization_id: currentUser.organization_id || 1,
    };

    dispatch(
      createNewAppAction({
        authClient: clientApp,
        authApp: oauthApp,
        authAppPrivacyPolicyFile: privacyPolicyFileObject,
      }),
    ).then((response) => {
      if (response.error) {
        setIsSending(false);
        setSendError(response.error.message);
      } else {
        setIsSending(false);
        const newAppCredId = response.payload;
        history.push(`/applications/${newAppCredId}/edit`);

        dispatch(getOAuthAppCredentialsAction());
        dispatch(getOAuthAppsAction());
        dispatch(getAuthClientsAction());
      }
    });

    setSubmitting(false);
  };

  const removeSelectedPolicyFile = () => {
    setPrivacyPolicyFileObject(null);
    setPrivacyPolicyFileMetadata(undefined);
  };
  const checkStepOne = (values: INewAppValues, callEvent: any) => {
    setStepOneCompleted(!!values.name && !!clientType && !!grantType && !!appScope && !!values.redirectURIs);
    return callEvent;
  };
  const checkStepTwo = () => {
    if (privacyPolicyOption === 'now') {
      // verify they have a file selected if the choose to upload now
      setStepTwoCompleted(privacyPolicyFileObject !== null);
    } else {
      setStepTwoCompleted(!!privacyPolicyOption);
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={(values, { setSubmitting }) => handleSubmit(values, setSubmitting)}
        >
          {({ values, handleChange, handleBlur, handleSubmit, isSubmitting }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Grid container>
                  {!!sendError && (
                    <Grid item xs={12}>
                      <Alert severity="error">{sendError}</Alert>
                    </Grid>
                  )}
                  {currentStep === 1 && (
                    <>
                      <Grid item xs={12}>
                        <h4 className={css.modalHeader}>Register a new application</h4>
                      </Grid>
                      <Grid item xs={12} className={css.formRow}>
                        <label htmlFor="name">App Name</label>
                        <input
                          name="name"
                          placeholder="Enter a Name"
                          type="text"
                          value={values.name}
                          onChange={checkStepOne(values, handleChange)}
                          onBlur={checkStepOne(values, handleBlur)}
                        />
                        <ErrorMessage name="name">{(msg) => <div className="input-feedback">{msg}</div>}</ErrorMessage>
                      </Grid>
                      <Grid container className={css.formRow}>
                        <Grid item xs={6}>
                          <label htmlFor="clientType">Client Type</label>
                          <Select
                            classNamePrefix="react-select"
                            placeholder="Select a Client Type"
                            options={clientOptions}
                            inputValue={values.clientType}
                            onChange={handleClientTypeChange}
                          />
                          <ErrorMessage name="userType">
                            {(msg) => <div className="input-feedback">{msg}</div>}
                          </ErrorMessage>
                        </Grid>
                        <Grid item xs={6}>
                          <label htmlFor="grantType">Grant Type</label>
                          <Select
                            classNamePrefix="react-select"
                            placeholder="Select a Grant Type"
                            options={grantOptions}
                            inputValue={values.grantType}
                            value={grantOptions[0]}
                            onChange={handleGrantTypeChange}
                          />
                          <ErrorMessage name="grantType">
                            {(msg) => <div className="input-feedback">{msg}</div>}
                          </ErrorMessage>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} className={css.formRow}>
                        <label htmlFor="scope">Scope(s)</label>
                        <OAuthScopeSelector
                          placeholder="Select Scope(s)"
                          defaultValue={values.scope}
                          onChange={handleScopeChange}
                        />
                        <ErrorMessage name="scope">{(msg) => <div className="input-feedback">{msg}</div>}</ErrorMessage>
                      </Grid>
                      <Grid item xs={12} className={css.formRow}>
                        <label htmlFor="redirectURIs">Callback URLs / Redirect URIs</label>
                        <textarea
                          name="redirectURIs"
                          className={formCSS.redirectURIs}
                          value={values.redirectURIs}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage name="redirectURIs">
                          {(msg) => <div className="input-feedback">{msg}</div>}
                        </ErrorMessage>
                      </Grid>
                      <Grid item xs={12} className={classes('alignTextRight', css.formRow)}>
                        <strong>Step 1 of 2</strong>
                      </Grid>
                      <Grid item xs={12} className={classes('alignTextRight', css.modalActionButtons)}>
                        <Button type="button" secondary onClick={bindComplete}>
                          Cancel
                        </Button>
                        <Button type="button" onClick={() => setCurrentStep(2)} disabled={!stepOneCompleted}>
                          Next
                        </Button>
                      </Grid>
                    </>
                  )}
                  {currentStep === 2 && (
                    <>
                      <Grid item xs={12}>
                        <h4 className={classes('alignTextCenter', css.modalHeader)}>
                          Do you have a privacy policy ready to upload?
                        </h4>
                      </Grid>
                      <Grid item xs={12} className={classes('alignTextCenter', formCSS.policyOptions)}>
                        <RadioGroup
                          row
                          name="privacy-policy-option"
                          value={privacyPolicyOption}
                          className={formCSS.policyOptionRadios}
                        >
                          <Button
                            type="button"
                            secondary
                            className={classes(formCSS.policyOptionButton, {
                              [formCSS.selected]: privacyPolicyOption === 'now',
                            })}
                            onClick={() => setPrivacyPolicyOption('now')}
                          >
                            <img src={uploadNowIcon} alt="Upload Now" />
                            <strong>Upload Now</strong>
                            <Radio value="now" />
                          </Button>
                          <Button
                            type="button"
                            secondary
                            className={classes(formCSS.policyOptionButton, {
                              [formCSS.selected]: privacyPolicyOption === 'later',
                            })}
                            onClick={() => setPrivacyPolicyOption('later')}
                          >
                            <img src={doItLaterIcon} alt="I'll do it later" />
                            <strong>I'll do it later</strong>
                            <Radio value="later" />
                          </Button>
                        </RadioGroup>
                        {privacyPolicyOption === 'now' && (
                          <Grid item xs={12} className={classes(css.formRow, formCSS.policyUploadRegion)}>
                            <label htmlFor="policy-upload">Privacy Policy (PDF files only.)</label>
                            {privacyPolicyFileObject && privacyPolicyFileMetadata ? (
                              <div className={formCSS.fileMetadata}>
                                <Button type="link" onClick={removeSelectedPolicyFile}>
                                  Remove
                                </Button>
                                {privacyPolicyFileMetadata.name}
                              </div>
                            ) : (
                              <input
                                name="policy-upload"
                                placeholder="Upload"
                                type="file"
                                accept=".pdf, application/pdf"
                                onChange={handleFileChange}
                              />
                            )}
                          </Grid>
                        )}
                      </Grid>
                      <Grid item xs={12} className={classes('alignTextRight', css.formRow)}>
                        <strong>Step 2 of 2</strong>
                      </Grid>
                      <Grid item xs={12} className={classes('alignTextRight', css.modalActionButtons)}>
                        <Button type="button" secondary onClick={() => setCurrentStep(1)} disabled={isSending}>
                          Back
                        </Button>
                        <Button type="submit" disabled={!stepTwoCompleted || isSending}>
                          {isSending ? (
                            <span>
                              <CircularProgress size="15px" /> Saving...
                            </span>
                          ) : (
                            <span>Save Application</span>
                          )}
                        </Button>
                      </Grid>
                    </>
                  )}
                </Grid>
              </form>
            );
          }}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default NewApp;
