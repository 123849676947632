import * as React from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { Visibility } from '@material-ui/icons';
import classes from 'classnames';
import { IListDataItem } from 'views/OAuthManagement/interfaces/IListDataItem';
import { credential_type_map } from 'views/OAuthManagement/interfaces/IAuthClient';
import NewPayer from 'views/PayerInformation/components/NewPayer';
import css from 'views/Dashboard/dashboard.module.scss';
import { Button } from 'components/controls/Button';
import { Tile } from 'components/controls/Tile';
import { MUIModal } from 'components/controls/MUI/MUIModal';
import { ActionTile } from 'components/controls/ActionTile';
import AppTile from 'views/Dashboard/components/AppTile';
import { listDataBuilder } from 'common/utils/listDataBuilder';

const ExternalPayerDashboard: React.FC = () => {
  const oauthApps = useSelector((state) => state.oauthMngmt.oauthApps);
  const oauthAppCredentials = useSelector((state) => state.oauthMngmt.oauthAppCredentials);
  const authClients = useSelector((state) => state.oauthMngmt.authClients);
  const devAttestations = useSelector((state) => state.developerAttestations.attestations);
  const [newPayerModal, setNewPayerModal] = React.useState(false);

  React.useEffect(() => {
    if (oauthApps.length > 0 && oauthAppCredentials.length > 0 && authClients.length > 0) {
      const appData = listDataBuilder({
        credentials: oauthAppCredentials,
        applications: oauthApps,
        clients: authClients,
        attestations: devAttestations,
      }).filter((item) => {
        return item.credential.credential_type === credential_type_map.PROD;
      });
    }
  }, [oauthApps, oauthAppCredentials, authClients, devAttestations]);

  const toggleNewPayer = () => {
    setNewPayerModal(!newPayerModal);
  };

  return (
    <Grid container>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <h3>Register as a payer</h3>
          </Grid>
          <Grid item>
          <p className={classes(css.centerParagraph)}>
            Each payer that is registered is provided a unique set of API keys and configuration settings needed for making requests
            to the system.
          </p>
          </Grid>
          <Grid item>
          <ActionTile
            className={classes(css.tile, css.appTile)}
            action={toggleNewPayer}
            label="Register as a Payer"
            standalone={true}
          />
        </Grid>
      </Grid>
      <MUIModal width="md" allowClose open={newPayerModal} handleClose={toggleNewPayer}>
        <NewPayer bindComplete={toggleNewPayer} bindCancel={toggleNewPayer} />
      </MUIModal>
      <Grid item xs={12}>
        <Grid container className={css.sectionHeader}>
          <Grid item xs={8}>
            <h4>Document Library</h4>
          </Grid>
          <Grid item xs={4} className="alignTextRight">
            <Button>Document Library</Button>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <Tile
              title="Setting your application's privacy policy"
              bottomContent={
                <Button tertiary>
                  <Visibility /> Read More
                </Button>
              }
            />
            <Tile
              title="Resolving attestation issues"
              bottomContent={
                <Button tertiary>
                  <Visibility /> Read More
                </Button>
              }
            />
            <Tile
              title="Registering an application for production"
              bottomContent={
                <Button tertiary>
                  <Visibility /> Read More
                </Button>
              }
            />
            <Tile
              title="Editing a production application registration"
              bottomContent={
                <Button tertiary>
                  <Visibility /> Read More
                </Button>
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ExternalPayerDashboard;
