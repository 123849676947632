//Type variant located at 'common/types/TUserTypes'

interface IUserList {
  'mihin-admin': string;
  payer: string;
  developer: string;
  'external-payer': string;
}

export const UserType: IUserList = {
  'mihin-admin': 'MiHIN Admin',
  payer: 'Payer',
  developer: 'Developer',
  'external-payer': 'External Payer',
};
