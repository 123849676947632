import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_URL } from 'settings';
import { getCurrentAuthHeaders } from 'common/utils/getCurrentAuthHeaders';
import { IUser } from 'common/interfaces/IUser';
import { IOrganization } from 'common/interfaces/IOrganization';
import { DynamoScan, DynamoScanRows } from '../interfaces/IDynamoDB';

interface ValidationErrors {
  errorMessage: string;
  field_errors: Record<string, string>;
}

interface ICreateOrgUserParams {
  orgId: string | number;
  user: IUser;
}

interface IResendInviteParams {
  orgId?: string | number;
  username: string;
}

export const getFPLogsByPayer = createAsyncThunk('Monit/logs-fp', async (payerId?: number | string) => {
    const headers = await getCurrentAuthHeaders('json');
    const resp = await (await fetch(`${API_URL}/monit/${payerId}/fplogs`, { method: 'GET', headers })).json() as DynamoScanRows;
    
    // const dynamoScan = await (resp.json()) as DynamoScanRows;
    const dynamoRow: [string[]] = [[]];
    for (let i = 0; i < resp.Items.length; i++) { //dynamoScan.Items.length
      const item = resp.Items[i];
      // dynamoRow.push( {id: i, col1: item.timestamp?.S, col2: item.processing_status?.S, col3: item.s3_holding_bucket_name?.S, col4: item.uuid.S} )
    }
    // resp.Items.forEach((item, index) => dynamoRow.push( {id: index, col1: item.timestamp?.S, col2: item.processing_status?.S, col3: item.s3_holding_bucket_name?.S, col4: item.uuid.S} ));
    console.log(dynamoRow);
        // dynamoScan.Rows = dynamoRow;
    resp.Rows = dynamoRow;
    resp.Items = [];
        
        // return dynamoScan;
    // const response: DynamoScanRows = {Items: dynamoScan.Items, Rows: dynamoRow, Count: dynamoScan.Count, ScannedCount: dynamoScan.ScannedCount, ResponseMetadata: dynamoScan.ResponseMetadata};

    return (await resp) as DynamoScanRows;
});

export const getOrgById = createAsyncThunk('Org/org', async (orgId?: number | string) => {
  const headers = await getCurrentAuthHeaders('json');
  const response = await fetch(`${API_URL}/org/${orgId}`, { method: 'GET', headers });
  return (await response.json()) as IOrganization;
});