import * as React from 'react';
import classes from 'classnames';
import { Card, CardContent, ClickAwayListener, Tooltip } from '@material-ui/core';
import { Visibility, FileCopy } from '@material-ui/icons';
import { Button } from 'components/controls/Button';
import { clipboardCopy } from 'common/utils/clipboardCopy';
import { IAppCredentialsProps } from 'common/interfaces/IAppCredentialsProps';
import { credential_type_map } from 'views/OAuthManagement/interfaces/IAuthClient';
import css from './style.module.scss';

const AppCredentials: React.FC<IAppCredentialsProps> = ({ clientId, clientSecret, credentialType }) => {
  const [showTokens, setShowTokens] = React.useState(false);
  const [openIDToolTop, setOpenIDToolTip] = React.useState(false);
  const [openSecretToolTop, setOpenSecretToolTip] = React.useState(false);

  const handleIDTooltipClose = () => {
    setOpenIDToolTip(false);
  };
  const handleSecretTooltipClose = () => {
    setOpenSecretToolTip(false);
  };

  const toggleTokenVisibility = () => {
    setShowTokens(!showTokens);
  };

  const copyID = () => {
    clipboardCopy(clientId).then(() => {
      setOpenIDToolTip(true);
    });
  };
  const copySecret = () => {
    clipboardCopy(clientSecret).then(() => {
      setOpenSecretToolTip(true);
    });
  };

  const copyIDAction = (
    <ClickAwayListener onClickAway={handleIDTooltipClose}>
      <div className={css.copyButton}>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleIDTooltipClose}
          open={openIDToolTop}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          arrow
          title="Copied!"
        >
          <FileCopy onClick={copyID} />
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
  const copySecretAction = (
    <ClickAwayListener onClickAway={handleSecretTooltipClose}>
      <div className={css.copyButton}>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleSecretTooltipClose}
          open={openSecretToolTop}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          arrow
          title="Copied!"
        >
          <FileCopy onClick={copySecret} />
        </Tooltip>
      </div>
    </ClickAwayListener>
  );

  return (
    <Card
      className={classes(css.detailCard, css.appCredentialsCard, {
        [css.forProd]: credentialType === credential_type_map['PROD'],
      })}
    >
      <CardContent>
        <Button tertiary className={css.credShowHideButton} onClick={toggleTokenVisibility}>
          <Visibility /> {showTokens ? 'Hide' : 'Show'}
        </Button>
        <h3>App Credentials</h3>
        <div className={css.credLabel}>Client ID:</div>
        <div className={css.credValue}>
          {copyIDAction}
          {showTokens ? (
            <input type="text" value={clientId} disabled />
          ) : (
            <pre>****************************************</pre>
          )}
        </div>
        <div className={css.credLabel}>Client Secret:</div>
        <div className={css.credValue}>
          {copySecretAction}
          {showTokens ? (
            <input type="text" value={clientSecret} disabled />
          ) : (
            <pre>****************************************</pre>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default AppCredentials;
