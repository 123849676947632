import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Avatar, Menu, MenuItem, Button } from '@material-ui/core';
import IdleTimer from 'react-idle-timer';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PersonIcon from '@material-ui/icons/Person';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { ITopMenuProps } from './interfaces/ITopMenuProps';
import classes from 'classnames';
import logo from 'assets/interoperability-station-logo.svg';
import css from './top-menu.module.scss';
import { logout as logoutAction } from 'views/Auth/redux/actions';
import { IUser } from 'common/interfaces/IUser';
import { MUIChip } from 'components/controls/MUI/MUIChip';

export const TopMenu: React.FC<ITopMenuProps> = (props) => {
  const currentUser = useSelector((state) => state.auth.user) as IUser;
  const avatarLabel = currentUser?.given_name
    ? `${currentUser?.given_name.substring(0, 1)} ${currentUser.family_name?.substring(0, 1)}`
    : `${currentUser?.username.substring(0, 2)}`;

  const history = useHistory();
  const [openMenu, setOpenMenu] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const dispatch = useDispatch();

  const timeout = 900000; // 15 mins in ms
  let idleTimer: any = null;

  const redirectToLogin = () => {
    // window.location.href = '/login';
    history.push('/login');
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenMenu(!openMenu);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setOpenMenu(false);
    setAnchorEl(null);
  };

  const handleLogOut = async () => {
    try {
      dispatch(logoutAction());
      redirectToLogin();
    } catch (err) {
      redirectToLogin();
    }
  };

  const handleAccountSettingsClick = () => {
    history.push('/account-settings');
  };

  const handleLogoClick = () => {
    history.push('/');
  };

  const handleOnIdle = (event) => {
    handleLogOut();
  };

  return (
    <>
      <IdleTimer
        ref={(ref) => {
          idleTimer = ref;
        }}
        element={document}
        timeout={timeout}
        onIdle={handleOnIdle}
        debounce={250}
      />
      <Grid container>
        <Grid item xs={10}>
          <img src={logo} alt="InterOp Station" className={css.logo} onClick={handleLogoClick} />
        </Grid>
        <Grid item xs={2}>
          <div className={css.buttonWrapper}>
            {currentUser && currentUser.user_type === 'mihin-admin' && (
              <MUIChip className={css.adminChip} type="admin" customLabel="MiHIN Admin" />
            )}
            <Button onClick={handleClick} className={classes(css.avatarButton, { [css.openMenu]: openMenu })}>
              <Avatar className={css.avatar}>{avatarLabel}</Avatar>
              {Boolean(anchorEl) ? <ExpandLess /> : <ExpandMore />}
            </Button>
            <Menu
              elevation={0}
              getContentAnchorEl={null}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              PopoverClasses={{ paper: css.dropdownMenu }}
            >
              <MenuItem onClick={handleAccountSettingsClick}>
                <PersonIcon /> Account Settings
              </MenuItem>
              <MenuItem onClick={handleLogOut}>
                <ExitToAppIcon /> Log Out
              </MenuItem>
            </Menu>
          </div>
        </Grid>
      </Grid>
    </>
  );
};
