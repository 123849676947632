import React from 'react';
import moment from 'moment';
import { withStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Button } from '@material-ui/core';

interface IDEQMPatientListViewProps {
  reportList: any[];
  getDetail: any;
}

const DEQMPatientListView: React.FC<IDEQMPatientListViewProps> = ({ reportList, getDetail }) => {
  const [reference, setReference] = React.useState<string>(' ');

  const StyledTableCell = withStyles((theme: Theme) => ({
    head: {
      backgroundColor: '#4473c3',
      color: '#d9e2f2',
      fontSize: 16,
    },
    body: {
      overflow: 'auto',
      fontSize: 14,
    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme: Theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

  const useStyles = makeStyles({
    table: {
      minWidth: 700,
    },
  });

  const sendDetailInfo = (dReport: any) => {
    return getDetail(dReport.resource);
  };

  React.useEffect(() => {
    for (const r in reportList) {
      getPatientName(reportList[r]);
    }
  }, [reportList, reference]);

  const getPatientName = (report: any) => {
    if (report.resource.contained) {
      for (const i in report?.resource?.contained[0]?.entry) {
        if (report?.resource?.contained[0]?.entry[i].fullUrl === report.resource.subject?.reference) {
          report.resource.patientFirstName = report?.contained[0]?.entry[i].resource.name[0].given[0];
          report.resource.patientLastName = report?.contained[0]?.entry[i].resource.name[0].family;
        } else {
          report.resource.patientFirstName = reference;
        }
      }
    }
  };
  const classes = useStyles();

  return (
    <div id="patient-list">
      <br />
      <br />

      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>Patient</StyledTableCell>
              <StyledTableCell>Calculated Measure</StyledTableCell>
              <StyledTableCell>Reporting Period Date Range</StyledTableCell>
              <StyledTableCell>Additional Data</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {reportList.map((report: any) => {
              return (
                <StyledTableRow key={report.resource.subject?.reference}>
                  <StyledTableCell>
                    {report.resource.patientFirstName
                      ? `${report.resource.patientFirstName} ${report.resource.patientLastName}`
                      : report.resource.subject?.reference}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {report.resource.group[0].measureScore ? report.resource.group[0].measureScore.value : 'N/A'}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {moment(report.resource.period.start).format('yyyy-MM-DD')} -{' '}
                    {moment(report.resource.period.end).format('yyyy-MM-DD')}
                  </StyledTableCell>
                  <StyledTableCell>
                    {' '}
                    <Button variant="contained" onClick={() => sendDetailInfo(report)}>
                      Additional Data
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default DEQMPatientListView;
