import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, ErrorMessage } from 'formik';
import { Grid, FormHelperText } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Button } from 'components/controls/Button';
import { trimAllStringProperties } from 'common/utils/trimAllStringProperties';
import { IResetPasswordProps } from './interfaces/IResetPasswordProps';
import { IResetPasswordValues } from './interfaces/IResetPasswordValues';
import { IUser } from 'common/interfaces/IUser';
import { resetPasswordValidationSchema } from './schemas/resetPasswordValidationSchema';
import AppFrame from 'components/AppFrame';
import css from 'components/AppFrame/app-frame.module.scss';
import successIcon from 'assets/icons/icon-password-reset-success.svg';
import {
  resetPassword as resetPasswordAction,
  getAuth as getAuthAction,
  forgotPasswordSubmit as forgotPasswordSubmitAction,
} from 'views/Auth/redux/actions';

const ResetPassword: React.FC<IResetPasswordProps> = (props) => {
  const params = new URLSearchParams(window.location.search);
  const userEmail = params.get('email');
  const validationCode = params.get('code');

  const dispatch = useDispatch();
  const history = useHistory();
  const currentUser = useSelector((state) => state.auth.user) as IUser;

  const initialValues: IResetPasswordValues = { new_password: '', confirm_password: '' };
  const [resetComplete, setResetComplete] = React.useState(false);
  const [sendError, setSendError] = React.useState('');

  const handleCancel = () => {
    if (!currentUser) {
      history.push('/login');
    } else {
      history.push('/');
    }
  };
  const handleFinalClick = () => {
    if (!currentUser) {
      history.push('/login');
    } else {
      dispatch(getAuthAction()).then(() => {
        history.push('/');
      });
    }
  };
  const handleSubmit = (values: IResetPasswordValues, setSubmitting: Function) => {
    const trimmedValues = trimAllStringProperties(values);

    if (!currentUser) {
      dispatch(
        forgotPasswordSubmitAction({
          username: userEmail || '',
          code: validationCode || '',
          password: trimmedValues.new_password,
        }),
      ).then((resp) => {
        if (resp.error) {
          setSendError(resp.error.message);
        } else {
          setResetComplete(true);
        }
      });
    } else {
      dispatch(resetPasswordAction({ ...trimmedValues, ...{ currentUser: currentUser } })).then((resp) => {
        if (resp.error) {
          setSendError(resp.error.message);
        } else {
          setResetComplete(true);
        }
      });
    }

    setSubmitting(false);
  };

  return (
    <AppFrame layoutType="authFullFrame">
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={(values, { setSubmitting }) => handleSubmit(values, setSubmitting)}
        validationSchema={resetPasswordValidationSchema}
      >
        {({ values, handleChange, handleBlur, handleSubmit, isSubmitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              {resetComplete && (
                <Grid container>
                  <Grid item xs={12}>
                    <img src={successIcon} alt="InterOp Station" />
                  </Grid>
                  <Grid item xs={12}>
                    <h4>Success!</h4>
                    <div className={css.subtitle}>Your account password was successfully reset. </div>
                    <Button type="button" onClick={handleFinalClick}>
                      {!currentUser ? 'Go to login' : 'Go to my Dashboard'}
                    </Button>
                  </Grid>
                </Grid>
              )}
              {!resetComplete && (
                <Grid container>
                  <Grid item xs={12}>
                    <h2>Create yourself a new password.</h2>
                    <div className={css.subtitle}>
                      You’ve requested to reset your account password. Let’s create a new password and get you back to
                      work!
                    </div>
                  </Grid>
                  {sendError !== '' && (
                    <Grid item xs={12}>
                      <Alert severity="error">Error: {sendError}</Alert>
                    </Grid>
                  )}
                  <Grid item xs={12} className={css.formRow}>
                    <label className={css.passwordLabel} htmlFor="new_password">
                      New Password
                    </label>
                    <input
                      id="new_password"
                      className={css.passwordInput}
                      placeholder="Enter Password"
                      type="password"
                      value={values.new_password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <FormHelperText className={css.passwordRequirementsText}>
                      Must be at least 10 characters long, contain numbers, uppercase and lowercase letters, and special
                      characters.
                    </FormHelperText>
                    <ErrorMessage name="new_password">
                      {(msg) => <div className="input-feedback">{msg}</div>}
                    </ErrorMessage>
                  </Grid>
                  <Grid item xs={12} className={css.formRow}>
                    <label className={css.passwordLabel} htmlFor="new_password">
                      Confirm New Password
                    </label>
                    <input
                      id="confirm_password"
                      placeholder="Enter Password"
                      type="password"
                      value={values.confirm_password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage name="confirm_password">
                      {(msg) => <div className="input-feedback">{msg}</div>}
                    </ErrorMessage>
                  </Grid>
                  <Grid item xs={12} className={css.formRow} style={{ textAlign: 'right' }}>
                    <Button type="button" onClick={handleCancel} secondary>
                      Cancel
                    </Button>
                    <Button type="submit" disabled={isSubmitting}>
                      Reset Password
                    </Button>
                  </Grid>
                </Grid>
              )}
            </form>
          );
        }}
      </Formik>
    </AppFrame>
  );
};

export default ResetPassword;
