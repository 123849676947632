import * as React from 'react';
import { Grid, Card, CardContent, ClickAwayListener, Tooltip } from '@material-ui/core';
import { credential_type_map } from 'views/OAuthManagement/interfaces/IAuthClient';
import { IAppCredentialsProps } from 'views/OAuthManagement/interfaces/IAppCredentialsProps';
import { Visibility, FileCopy } from '@material-ui/icons';
import { Button } from 'components/controls/Button';
import css from 'views/OAuthManagement/OAuthAppDetail/oauth-app-detail.module.scss';
import classes from 'classnames';
import { clipboardCopy } from 'common/utils/clipboardCopy';

const AppCredentials: React.FC<IAppCredentialsProps> = ({ currentClient, currentCredential }) => {
  const [showTokens, setShowTokens] = React.useState(false);
  const [openIDToolTop, setOpenIDToolTip] = React.useState(false);
  const [openSecretToolTop, setOpenSecretToolTip] = React.useState(false);

  const handleIDTooltipClose = () => {
    setOpenIDToolTip(false);
  };
  const handleSecretTooltipClose = () => {
    setOpenSecretToolTip(false);
  };

  const toggleTokenVisibility = () => {
    setShowTokens(!showTokens);
  };

  const copyID = () => {
    clipboardCopy(currentClient?.client_id).then(() => {
      setOpenIDToolTip(true);
    });
  };
  const copySecret = () => {
    clipboardCopy(currentClient?.client_secret).then(() => {
      setOpenSecretToolTip(true);
    });
  };

  const copyIDAction = (
    <ClickAwayListener onClickAway={handleIDTooltipClose}>
      <div className={css.copyButton}>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleIDTooltipClose}
          open={openIDToolTop}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          arrow
          title="Copied!"
        >
          <FileCopy onClick={copyID} />
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
  const copySecretAction = (
    <ClickAwayListener onClickAway={handleSecretTooltipClose}>
      <div className={css.copyButton}>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleSecretTooltipClose}
          open={openSecretToolTop}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          arrow
          title="Copied!"
        >
          <FileCopy onClick={copySecret} />
        </Tooltip>
      </div>
    </ClickAwayListener>
  );

  return (
    <Grid item xs={6} className={css.detailRegion}>
      <Card
        className={classes(css.detailCard, css.appCredentialsCard, {
          [css.forProd]: currentCredential.credential_type === credential_type_map['PROD'],
        })}
      >
        <CardContent>
          <Button tertiary className={css.credShowHideButton} onClick={toggleTokenVisibility}>
            <Visibility /> {showTokens ? 'Hide' : 'Show'}
          </Button>
          <h3>App Credentials</h3>
          <div className={css.credLabel}>Client ID:</div>
          <div className={css.credValue}>
            {copyIDAction}
            {showTokens ? (
              <input type="text" value={currentClient.client_id} disabled />
            ) : (
              <pre>****************************************</pre>
            )}
          </div>
          <div className={css.credLabel}>Client Secret:</div>
          <div className={css.credValue}>
            {copySecretAction}
            {showTokens ? (
              <input type="text" value={currentClient.client_secret} disabled />
            ) : (
              <pre>****************************************</pre>
            )}
          </div>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default AppCredentials;
