import * as React from 'react';
import { IOrganization } from 'common/interfaces/IOrganization';

export interface IHelpDeskContactProps {
  org: IOrganization;
}

const HelpDeskContact: React.FC<IHelpDeskContactProps> = ({ org }) => {
  return (
    org.registration_custom_error_message ? <span dangerouslySetInnerHTML={{__html: org.registration_custom_error_message}}></span> : 
    <span>
      Please contact our help desk at{' '}
      {org.registration_help_email && (
        <a href={`mailto:${org.registration_help_email}`}>{org.registration_help_email}</a>
      )}{' '}
      {org.registration_help_phone && org.registration_help_email && ' or call '}
      {org.registration_help_phone && (
        <a href={`tel:${org.registration_help_phone}`}>{org.registration_help_phone}</a>
      )}{' '}
      to continue.
    </span>
  );
};
export default HelpDeskContact;
