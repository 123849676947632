import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, ErrorMessage } from 'formik';
import { Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Button } from 'components/controls/Button';
import { trimAllStringProperties } from 'common/utils/trimAllStringProperties';
import { TUserType } from 'common/types/TUserType';
import { IUser } from 'common/interfaces/IUser';
import { newUserValidationSchema } from './schemas/newUserValidationSchema';
import { INewUserProps } from './interfaces/INewUserProps';
import { INewUserValues } from './interfaces/INewUserValues';
import Select from 'react-select';
import { createOrgUser as createOrgUserAction } from 'views/Organization/redux/actions';
import classes from 'classnames';
import css from 'components/AppFrame/app-frame.module.scss';
import { UserType } from 'common/property-list/user-types';

const NewUser: React.FC<INewUserProps> = (props) => {
  const { bindComplete } = props;
  const dispatch = useDispatch();

  const currentUser = useSelector((state) => state.auth.user) as IUser;
  const currentUserType = currentUser?.user_type;

  const orgs = useSelector((state) =>
    state.orgMgmnt.orgs
      ? state.orgMgmnt.orgs.map((org) => {
          return { value: org.id, label: org.name };
        })
      : [],
  );

  const userTypes = [
    { value: 'mihin-admin', label: UserType['mihin-admin'] },
    { value: 'payer', label: UserType.payer },
    { value: 'developer', label: UserType.developer },
    { value: 'external-payer', label: UserType['external-payer'] },
  ];

  const initialValues: INewUserValues = { email: '', userType: '', organizationId: '' };
  const [sendError, setSendError] = React.useState('');
  const [selectedUserType, setSelectedUserType] = React.useState<TUserType>();
  const [selectedPayerOrg, setSelectedPayerOrg] = React.useState(0);

  const getOrgId = () => {
    if (currentUser.user_type === 'mihin-admin') {
      return selectedUserType === 'mihin-admin' ? 1 : selectedUserType === 'payer' ? selectedPayerOrg : 0;
    } else if (currentUser.user_type === 'payer') {
      return currentUser.organization_id || 0;
    }

    return 0;
  };

  const handleSubmit = (values: INewUserValues, setSubmitting: any) => {
    const organizationId: number = getOrgId();
    const trimmedValues = trimAllStringProperties(values);
    const newUser: IUser = {
      username: trimmedValues.email,
      email: trimmedValues.email,
      user_type: currentUser.user_type === 'payer' ? 'payer' : selectedUserType,
    };

    dispatch(
      createOrgUserAction({
        orgId: organizationId,
        user: newUser,
      }),
    ).then((user) => {
      if (user.error) {
        if (user.error.message === 'AliasExistsException' || user.error.message === 'UsernameExistsException') {
          setSendError('That email already exists, please enter a different one.');
        } else {
          setSendError(user.error.message);
        }
      } else {
        bindComplete();
      }
    });

    setSubmitting(false);
  };

  const handleUserTypeChange = (selectedValue) => {
    setSelectedUserType(selectedValue.value);
  };
  const handlePayerOrgChange = (selectedValue) => {
    setSelectedPayerOrg(selectedValue.value);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <h4 className={css.modalHeader}>Add a New User</h4>
      </Grid>
      <Grid item xs={12}>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={(values, { setSubmitting }) => handleSubmit(values, setSubmitting)}
          validationSchema={newUserValidationSchema}
        >
          {({ values, handleChange, handleBlur, handleSubmit, isSubmitting }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Grid container>
                  {!!sendError && (
                    <Grid item xs={12}>
                      <Alert severity="error">{sendError}</Alert>
                    </Grid>
                  )}

                  <Grid item xs={12} className={css.formRow}>
                    <label htmlFor="email">Email</label>
                    <input
                      name="email"
                      placeholder="Enter Email"
                      type="text"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage name="email">{(msg) => <div className="input-feedback">{msg}</div>}</ErrorMessage>
                  </Grid>
                  {currentUserType === 'mihin-admin' && (
                    <Grid item xs={12} className={css.formRow}>
                      <label htmlFor="userType">User Type</label>
                      <Select
                        classNamePrefix="react-select"
                        placeholder="Select a User Type"
                        options={userTypes}
                        inputValue={values.userType}
                        onChange={handleUserTypeChange}
                      />
                      <ErrorMessage name="userType">
                        {(msg) => <div className="input-feedback">{msg}</div>}
                      </ErrorMessage>
                    </Grid>
                  )}

                  {selectedUserType === 'payer' && (
                    <Grid item xs={12} className={css.formRow}>
                      <label htmlFor="organizationId">Payer Organization</label>
                      <Select
                        classNamePrefix="react-select"
                        placeholder="Select an Organization"
                        options={orgs}
                        inputValue={values.organizationId}
                        onChange={handlePayerOrgChange}
                      />
                      <ErrorMessage name="organizationId">
                        {(msg) => <div className="input-feedback">{msg}</div>}
                      </ErrorMessage>
                    </Grid>
                  )}

                  <Grid item xs={12} className={classes('alignTextRight', css.modalActionButtons)}>
                    <Button type="button" secondary onClick={bindComplete}>
                      Cancel
                    </Button>
                    <Button type="submit" disabled={isSubmitting}>
                      Send
                    </Button>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default NewUser;
