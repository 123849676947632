import { createAsyncThunk } from '@reduxjs/toolkit';
import { API_URL } from 'settings';
import { getCurrentAuthHeaders } from 'common/utils/getCurrentAuthHeaders';
import { IOrganization } from 'common/interfaces/IOrganization';
import { IDemographicsRequest } from 'views/Registration/interfaces/IDemographicsRequest';
import { IDemographicsResponse } from 'views/Registration/interfaces/IDemographicsResponse';
import { ISubmitRequest } from 'views/Registration/interfaces/ISubmitRequest';
import { ISubmitResponse } from 'views/Registration/interfaces/ISubmitResponse';
import { IRegistrationError } from 'views/Registration/interfaces/IRegistrationError';
import { INewAccount } from 'views/Registration/interfaces/INewAccount';

export const submitSessionRequest = createAsyncThunk<IDemographicsResponse, IDemographicsRequest>(
  'Registration/submit-session-request',
  async (sessionRequest) => {
    try {
      const headers = await getCurrentAuthHeaders('json', false);
      const request = {
        method: 'POST',
        headers,
        body: JSON.stringify(sessionRequest),
      };

      const response = await fetch(`${API_URL}/registration/request-session`, request);

      if (response.status !== 200) {
        throw {
          code: await response.text(),
        };
      }

      const responseJSON = await response.json();
      return { ...responseJSON, demographics: sessionRequest } as IDemographicsResponse;
    } catch (err) {
      if ('code' in err) {
        throw new Error(err.code);
      } else {
        throw err;
      }
    }
  },
);

export const submit = createAsyncThunk<ISubmitResponse, ISubmitRequest>(
  'Registration/submit',
  async (submitRequest) => {
    try {
      const headers = await getCurrentAuthHeaders('json', false);
      const request = {
        method: 'POST',
        headers,
        body: JSON.stringify(submitRequest),
      };

      const response = await fetch(`${API_URL}/registration/submit`, request);

      if (response.status !== 200) {
        throw {
          code: await response.text(),
        };
      }

      const responseJSON = await response.json();
      return responseJSON as ISubmitResponse;
    } catch (err) {
      if ('code' in err) {
        throw new Error(err.code);
      } else {
        throw err;
      }
    }
  },
);

export const completeRegistration = createAsyncThunk<ISubmitResponse, INewAccount>(
  'Registration/complete-registration',
  async (newAccount) => {
    try {
      const headers = await getCurrentAuthHeaders('json', false);
      const request = {
        method: 'POST',
        headers,
        body: JSON.stringify({ ...newAccount, org_id: newAccount.demographics?.org_id }),
      };

      const response = await fetch(`${API_URL}/registration/complete`, request);

      if (response.status !== 200) {
        const errorJSON = await response.json();
        if ('body' in errorJSON) {
          // if there is a `body` in the error there was an issue with Okta creating the user
          const errorBody = errorJSON['body'];
          if ('errorCode' in errorBody && 'errorCauses' in errorBody) {
            const errorCode = errorBody['errorCode'];
            const errorCauses = errorBody['errorCauses'];
            // https://developer.okta.com/docs/reference/error-codes/#E0000001
            if (errorCode === 'E0000001' && errorCauses.length > 0) {
              // https://developer.okta.com/docs/reference/api-overview/#errors
              // if there is a email or username confict the error message from Okta contains the following:
              if (errorCauses[0]['errorSummary'].indexOf('field already exists') > -1) {
                throw {
                  code: 'LoginConflict',
                };
              }
              // if the password doesn't meet the Okta requirement the error message contains the following:
              else if (errorCauses[0]['errorSummary'].indexOf('Password requirements were not met') > -1) {
                throw {
                  code: 'PasswordError',
                };
              } else {
                throw {
                  code: '[OKTA E0000001]',
                };
              }
            }

            throw {
              code: `[OKTA ${errorCode}]`,
            };
          }
        }

        throw {
          code: await response.text(),
        };
      }

      const responseJSON = await response.json();
      return responseJSON as ISubmitResponse;
    } catch (err) {
      if ('code' in err) {
        throw new Error(err.code);
      } else {
        throw err;
      }
    }
  },
);

export const getOrgByCode = createAsyncThunk('Registration/org-by-code', async (orgCode?: string) => {
  try {
    const headers = await getCurrentAuthHeaders('json', false);
    const response = await fetch(`${API_URL}/registration/get_org_by_code?code=${orgCode}`, { method: 'GET', headers });

    if (response.status !== 200) {
      throw {
        code: 'OrganizationError',
      };
    }

    const responseJSON = await response.json();
    return responseJSON as IOrganization;
  } catch (err) {
    if ('code' in err) {
      throw new Error(err.code);
    } else {
      throw err;
    }
  }
});

export const clearSession = createAsyncThunk('Registration/clear-session', async () => {
  return null;
});

export const setRegistrationError = createAsyncThunk<IRegistrationError, any>(
  'Registration/set-reg-error',
  async ({ session_id, code }) => {
    return { session_id, code } as IRegistrationError;
  },
);
