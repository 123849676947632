import React, { useState, useEffect } from 'react';
import { Formik, ErrorMessage } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Button } from 'components/controls/Button';
import { trimAllStringProperties } from 'common/utils/trimAllStringProperties';
import { useHistory } from 'react-router-dom';
import classes from 'classnames';
import AppFrame from 'components/AppFrame';
import css from 'components/AppFrame/app-frame.module.scss';
import ForgotPassword from 'views/Auth/ForgotPassword/';
import { ILoginProps } from './interfaces/ILoginProps';
import { ILoginValues } from './interfaces/ILoginValues';
import { IUser } from 'common/interfaces/IUser';
import { loginValidationSchema } from './schemas/loginValidationSchema';
import { login as loginAction } from 'views/Auth/redux/actions';
import { MUIModal } from 'components/controls/MUI/MUIModal';
import { Auth } from 'aws-amplify';
import { userInfo } from 'os';

const Login: React.FC<ILoginProps> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.user) as IUser;

  const initialValues: ILoginValues = { username: '', password: '' };

  const [forgotPasswordModal, setForgotPasswordModal] = useState(false);
  const [sendError, setSendError] = useState('');

  useEffect(() => {
    Auth.currentUserInfo()
      .then((user) => {
        if (user && currentUser && currentUser.terms_accepted) {
          history.push('/');
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  }, []);

  const handleLogin = (values: ILoginValues, setSubmitting: Function) => {
    setSubmitting(true);
    const trimmedValues = trimAllStringProperties(values);
    dispatch(loginAction(trimmedValues)).then((resp) => {
      const user = resp.payload as IUser;
      if (resp.error) {
        if (resp.error.message === 'Password attempts exceeded') {
          setSendError(
            'You have exceeded the maximum number of password attempts and have been locked out. Please wait 15 minutes before trying again.',
          );
        } else {
          // any error back from AWS will be masked with this message
          setSendError('Incorrect username or password');
        }
      } else if (user === null) {
        setSendError('There was an error while trying to log you in.');
      } else {
        switch (user.challengeName) {
          case 'NEW_PASSWORD_REQUIRED':
            history.push('/sign-up/complete');
            break;
          default:
            history.push('/');
            break;
        }
      }
      setSubmitting(false);
    });
  };

  const toggleForgotPassword = () => {
    setForgotPasswordModal(!forgotPasswordModal);
  };
  const handleForgotPasswordComplete = () => {
    toggleForgotPassword();
  };
  const handleSignupNavClick = () => {
    history.push('/sign-up');
  };

  return (
    <AppFrame layoutType="authLeftRight">
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={(values, { setSubmitting }) => handleLogin(values, setSubmitting)}
        validationSchema={loginValidationSchema}
      >
        {({ values, handleChange, handleBlur, handleSubmit, isSubmitting }) => {
          return (
            <form onSubmit={handleSubmit} autoComplete="off">
              <Grid container>
                <Grid item xs={12}>
                  <h2>Log in</h2>
                  <div className={css.subtitle}>Welcome to InterOp Station&trade;.</div>
                </Grid>
                {sendError !== '' && (
                  <Grid item xs={12}>
                    <Alert severity="error">{sendError}</Alert>
                  </Grid>
                )}
                <Grid item xs={12} className={css.formRow}>
                  <label htmlFor="username">Username</label>
                  <input
                    id="username"
                    placeholder="Enter username"
                    type="text"
                    value={values.username}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete="off"
                  />
                  <ErrorMessage name="username">{(msg) => <div className="input-feedback">{msg}</div>}</ErrorMessage>
                </Grid>
                <Grid item xs={12} className={css.formRow}>
                  <label className={css.passwordLabel} htmlFor="password">
                    Password
                  </label>
                  <input
                    id="password"
                    placeholder="Enter password"
                    type="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete="off"
                  />
                  <ErrorMessage name="password">{(msg) => <div className="input-feedback">{msg}</div>}</ErrorMessage>
                </Grid>
                <Grid item xs={12} className={css.formRow}>
                  <Button type="submit" disabled={isSubmitting} isLoading={isSubmitting} loadingText="Signing in...">
                    Sign in
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button type="link" className={css.forgotPasswordLink} onClick={toggleForgotPassword}>
                    Forgot your password?
                  </Button>
                  <MUIModal width="md" allowClose open={forgotPasswordModal} handleClose={toggleForgotPassword}>
                    <ForgotPassword bindComplete={handleForgotPasswordComplete} />
                  </MUIModal>
                </Grid>
                <Grid item xs={12} className={classes(css.signUpSignInArea, css.signUpArea)}>
                  Need an account?
                  <Button type="link" className={css.signUpSignInLink} onClick={handleSignupNavClick}>
                    Sign Up
                  </Button>
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Formik>
    </AppFrame>
  );
};

export default Login;
