import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { IUser } from 'common/interfaces/IUser';
import { IOrganizationRouteProps } from './interfaces/IOrganizationRouteProps';
import AppFrame from 'components/AppFrame';
import { Grid, Card, CardContent } from '@material-ui/core';
import { Edit, MailOutline, PersonOutline, ListOutlined } from '@material-ui/icons';
import { Button } from 'components/controls/Button';
import { MUIChip } from 'components/controls/MUI/MUIChip';
import { MUIModal } from 'components/controls/MUI/MUIModal';
import Select from 'react-select';
import { Tile } from 'components/controls/Tile';
import { InputGroup } from 'components/controls/InputGroup';
import css from './organization.module.scss';
import {
  getUsersByOrgId as getUsersByOrgIdAction,
  getOrgById as getOrgByIdAction,
  resendInvite as resendInviteAction,
} from 'views/Organization/redux/actions';
import _ from 'lodash';
import { ActionTile } from 'components/controls/ActionTile';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { ISubMenuItem } from 'components/controls/SideMenu/interfaces/ISubMenuItem';
import NewUser from 'views/NewUser';
import { UserType } from 'common/property-list/user-types';

// https://app.zeplin.io/project/5f4eb371598cfa10a60f245d/screen/5f6e48781d6fa3365a9a6e0d

const Organization: React.FC<RouteComponentProps<IOrganizationRouteProps>> = (props) => {
  const dispatch = useDispatch();

  const currentUser = useSelector((state) => state.auth.user) as IUser;
  const currentOrg = useSelector((state) => state.org.currentOrganization);
  const orgUsers = useSelector((state) => state.org.users);

  const [orgId, setOrgId] = React.useState<number | string | undefined>(props.match?.params.id);
  const [activeTab, setActiveTab] = React.useState('active-users');
  const [filterOrgUsers, setFilteredOrgUsers] = React.useState<IUser[]>([]);
  const [searchParam, setSearchParam] = React.useState('');
  const [filterBy, setFilterBy] = React.useState();
  const [newUserModal, setNewUserModal] = React.useState(false);
  const [subMenuItems, setSubMenuItems] = React.useState<ISubMenuItem[]>([]);

  const userTypesForFilter = [
    { value: '', label: 'Show All' },
    { value: 'mihin-admin', label: UserType['mihin-admin'] },
    { value: 'payer', label: UserType.payer },
    { value: 'developer', label: UserType.developer },
    { value: 'external-payer', label: UserType['external-payer'] },
  ];

  React.useEffect(() => {
    if (currentUser?.user_type === 'payer') {
      setOrgId(currentUser.organization_id);
    }
  }, [currentUser]);

  React.useEffect(() => {
    if (orgId) {
      dispatch(getOrgByIdAction(orgId));
      dispatch(getUsersByOrgIdAction(orgId));
    }
  }, [orgId]);

  React.useEffect(() => {
    if (orgUsers && orgUsers.errorType !== "ProfileNotFound") {
      setFilteredOrgUsers(orgUsers);
      setSubMenuItems([
        {
          label: `Active Users (${
            orgUsers.filter((user) => {
              return user.status === 'CONFIRMED';
            }).length
          })`,
          active: activeTab === 'active-users',
          action: () => setActiveTab('active-users'),
          icon: <PersonOutline />,
        },
        {
          label: `Pending Invitation (${
            orgUsers.filter((user) => {
              return user.status === 'FORCE_CHANGE_PASSWORD';
            }).length
          })`,
          active: activeTab === 'pending-users',
          action: () => setActiveTab('pending-users'),
          icon: <ListOutlined />,
        },
      ]);
    }
  }, [orgUsers, activeTab]);

  React.useEffect(() => {
    let foundData: IUser[] = [];

    if (searchParam.length > 2) {
      _.each(orgUsers, (user) => {
        _.each(['username', 'preferred_username', 'given_name', 'family_name', 'email'], (property) => {
          if (user[property] && user[property].toLowerCase().indexOf(searchParam.toLowerCase()) > -1) {
            foundData.push(user);
          }
        });
      });
    } else {
      foundData = orgUsers;
    }

    if (!!filterBy) {
      foundData = foundData.filter((item) => {
        return item.user_type === filterBy;
      });
    }

    setFilteredOrgUsers(_.uniq(foundData));
  }, [searchParam, filterBy]);

  const handleFilterByChange = (selectedValue) => {
    setFilterBy(selectedValue.value);
  };
  const handleNewUserComplete = () => {
    toggleNewUser();
  };
  const handleResendInvite = (username: string) => {
    dispatch(resendInviteAction({ orgId, username }));
  };
  const handleSearchChange = (e) => {
    setSearchParam(e.target.value);
  };
  const handleEditOrgClick = () => {
    console.log('Not Implemented Yet');
  };
  const toggleNewUser = () => {
    setNewUserModal(!newUserModal);
  };
  const getDisplayName = (user: IUser) => {
    if (user.status !== 'CONFIRMED') {
      return '';
    } else {
      if (user.given_name) {
        return `${user.given_name} ${user.family_name}`;
      } else {
        return user.preferred_username || user.username;
      }
    }
  };
  const getAvatarChip = (user: IUser) => {
    if (user.status !== 'CONFIRMED') {
      return null;
    } else if (user.user_type === 'mihin-admin') {
      return <MUIChip type="admin" />;
    } else if (user.user_type === 'payer') {
      return <MUIChip type="payer" />;
    } else if (user.user_type === 'developer') {
      return <MUIChip type="developer" />;
    }

    return null;
  };

  return (
    <AppFrame layoutType="developerPortal" selectedMenuItem="OrgManagement" subMenuItems={subMenuItems}>
      <Grid container>
        <Grid item xs={6}>
          <h2 className="section-title">
            {currentUser?.user_type === 'mihin-admin' ? 'Organization' : 'User'} Management
          </h2>
        </Grid>
        <Grid item xs={6} className="alignTextRight">
          <Button onClick={toggleNewUser}>Add New User</Button>
        </Grid>
      </Grid>
      <Grid container>
        {currentUser?.user_type === 'mihin-admin' && (
          <Grid item xs={12}>
            <Breadcrumbs
              breadcrumbItems={[
                {
                  label: 'All Organizations',
                  location: `/orgs`,
                },
                {
                  label: `${currentOrg?.name}`,
                  location: `/orgs/${currentOrg?.id}`,
                },
              ]}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <h3>{currentOrg?.name}</h3>
        </Grid>
        {currentOrg && (
          <Grid item xs={12}>
            <Card className={css.orgDetails}>
              <CardContent>
                <Grid container>
                  <Grid item xs={3}>
                    <h5>Organization Contact</h5>
                    <div className={css.detailInfo}>{currentOrg.contact_name}</div>
                  </Grid>
                  <Grid item xs={3}>
                    <h5>Address</h5>
                    <div className={css.detailInfo}>
                      {currentOrg.address}
                      <br />
                      {currentOrg.city}, {currentOrg.state} {currentOrg.postal_code}
                    </div>
                  </Grid>
                  <Grid item xs={5}>
                    <h5>Organization Details</h5>
                    <div className={css.detailInfo}>{currentOrg.descr}</div>
                  </Grid>
                  <Grid item xs={1} className="alignTextRight">
                    <Button type="link" className={css.editLink} onClick={handleEditOrgClick}>
                      <Edit />
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
      <Grid container>
        <ul className="tabBar">
          <li>
            <button
              className={activeTab === 'active-users' ? 'active' : ''}
              onClick={() => setActiveTab('active-users')}
            >
              Active Users
            </button>
          </li>
          <li>
            <button
              className={activeTab === 'pending-users' ? 'active' : ''}
              onClick={() => setActiveTab('pending-users')}
            >
              Pending Invitation
            </button>
          </li>
        </ul>
      </Grid>
      <Grid container style={{ marginBottom: '3rem' }}>
        <Grid item xs={currentUser?.user_type === 'mihin-admin' ? 5 : 8}></Grid>
        <Grid item xs={4}>
          <InputGroup label="Search:" className={css.searchGroup}>
            <input type="text" value={searchParam} onChange={handleSearchChange} />
          </InputGroup>
        </Grid>
        {currentUser?.user_type === 'mihin-admin' && (
          <Grid item xs={3}>
            <InputGroup label="Filter by:">
              <Select
                classNamePrefix="react-select"
                placeholder="Account Type"
                options={userTypesForFilter}
                onChange={handleFilterByChange}
              />
            </InputGroup>
          </Grid>
        )}
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          {activeTab === 'active-users' && <ActionTile className={css.tile} action={toggleNewUser} label="Add User" />}
          {filterOrgUsers
            .filter((user) => {
              if (activeTab === 'active-users') {
                return user.status === 'CONFIRMED';
              } else {
                return user.status !== 'CONFIRMED';
              }
            })
            .map((user, index) => (
              <Tile
                key={`${index}`}
                className={css.tile}
                avatarChip={getAvatarChip(user)}
                // actions={}
                title={getDisplayName(user)}
                description={user.email}
              >
                {user.status === 'FORCE_CHANGE_PASSWORD' && (
                  <Grid container className={css.inviteSentInfo}>
                    <Grid item xs={12}>
                      <small>SENT:</small>
                    </Grid>
                    <Grid item xs={8}>
                      <div className={css.sentAt}>{moment(user.created_at).format('MMMM DD, YYYY')}</div>
                    </Grid>
                    <Grid item xs={4}>
                      <Button tertiary onClick={() => handleResendInvite(user.username)}>
                        <MailOutline /> Resend
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Tile>
            ))}
        </Grid>
      </Grid>
      <MUIModal width="md" allowClose open={newUserModal} handleClose={toggleNewUser}>
        <NewUser bindComplete={handleNewUserComplete} />
      </MUIModal>
    </AppFrame>
  );
};

export default Organization;
