import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import OAuthFrame from 'views/OAuthManagement/components/OAuthFrame';
import { Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { IOAuthApp } from 'views/OAuthManagement/interfaces/IOAuthApp';
import { IOAuthAppCredential } from 'views/OAuthManagement/interfaces/IOAuthAppCredential';
import { IAuthClient, credential_type_map } from 'views/OAuthManagement/interfaces/IAuthClient';
import { IOAuthAppDetailRouteProps } from 'views/OAuthManagement/interfaces/IOAuthAppDetailRouteProps';
import { Delete } from '@material-ui/icons';
import { Button } from 'components/controls/Button';
import {
  getOAuthApp as getOAuthAppAction,
  getPayerCredential as getPayerCredentialAction,
  getAuthClient as getAuthClientAction,
  deleteOAuthAppCredential as deleteOAuthAppCredentialAction,
  deleteAuthClient as deleteAuthClientAction,
} from 'views/OAuthManagement/redux/actions';
import classes from 'classnames';
import { MUIModal } from 'components/controls/MUI/MUIModal';
import DeleteConfirm from 'views/PayerInformation/components/DeleteConfirm';
import AppBaseForm from 'components/AppBaseForm';
import AppCredentials from 'components/AppCredentials';
import AppDetailsForm from 'views/PayerInformation/components/AppDetailsForm';
import AppInfoForm from 'views/PayerInformation/components/AppInfoForm';
import css from './payer-detail.module.scss';
import moment from 'moment';

const OAuthAppDetail: React.FC<RouteComponentProps<IOAuthAppDetailRouteProps>> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const currentCredential = useSelector((state) => state.oauthMngmt.currentCredential) as IOAuthAppCredential;
  const currentApp = useSelector((state) => state.oauthMngmt.currentApp) as IOAuthApp;
  const currentClient = useSelector((state) => state.oauthMngmt.currentClient) as IAuthClient;

  const [deleteConfirmModal, setDeleteConfirmModal] = React.useState(false);
  const [sendError, setSendError] = React.useState('');

  React.useEffect(() => {
    dispatch(getPayerCredentialAction());
  }, [dispatch]);

  React.useEffect(() => {
    if (currentCredential.application_id > 0 && currentCredential.auth_client_id > 0) {
      dispatch(getOAuthAppAction(currentCredential.application_id));
      dispatch(getAuthClientAction(currentCredential.auth_client_id));
    }
  }, [currentCredential]);

  const toggleDeleteConfirmation = () => {
    setDeleteConfirmModal(!deleteConfirmModal);
  };

  const handleDeleteClick = () => {
    dispatch(deleteOAuthAppCredentialAction(currentCredential)).then((response) => {
      if (response.error) {
        setSendError(response.error.message);
      } else {
        dispatch(deleteAuthClientAction(currentClient)).then(() => {
          history.push('/');
        });
      }
    });
  };

  return (
    <OAuthFrame {...props}>
      {currentCredential && currentClient && currentApp && (
        <Grid container>
          {!!sendError && (
            <Grid item xs={12}>
              <Alert severity="error">{sendError}</Alert>
            </Grid>
          )}
          <Grid item xs={12} className={css.actionHeader}>
            <Button tertiary className={css.deleteButton} onClick={toggleDeleteConfirmation}>
              <Delete /> Delete Payer
            </Button>
          </Grid>
          <Grid item xs={12}>
            <AppBaseForm />
          </Grid>
          <Grid container className={classes(css.mainDetailsContainer, css.topDetails)}>
            <Grid item xs={12} className={css.detailRegion}>
              <AppCredentials
                clientId={currentClient?.client_id}
                clientSecret={currentClient?.client_secret}
                credentialType={currentCredential.credential_type}
              />
            </Grid>
          </Grid>
          <Grid container className={classes(css.mainDetailsContainer, css.bottomDetails)}>
            <AppDetailsForm
              currentApp={currentApp}
              currentClient={currentClient}
              currentCredential={currentCredential}
            />
            <AppInfoForm currentApp={currentApp} currentCredential={currentCredential} />
          </Grid>
        </Grid>
      )}
      <MUIModal width="md" allowClose open={deleteConfirmModal} handleClose={toggleDeleteConfirmation}>
        <DeleteConfirm bindComplete={handleDeleteClick} bindCancel={toggleDeleteConfirmation} />
      </MUIModal>
    </OAuthFrame>
  );
};

export default OAuthAppDetail;
