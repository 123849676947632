import { object, string, bool } from 'yup';
import { IOrganization } from 'common/interfaces/IOrganization';
import { API_URL } from 'settings';
import { getCurrentAuthHeaders } from 'common/utils/getCurrentAuthHeaders';

import { mPhone, mPostalCode } from 'common/matchers';

export const newOrganizationValidationSchema = object<IOrganization>({
  name: string().required('Please enter an organization name').max(50),
  code: string()
    .required('Enter a unique code for this organization')
    .matches(/[a-z0-9_]/, 'Code must contain only lowercase letters, numbers, and underscore (_)')
    .max(32)
    .test('checkDuplicateCode', 'Code must be unique', async (value) => {
      try {
        const headers = await getCurrentAuthHeaders('json');
        const res = await fetch(`${API_URL}/org/check-code/${value}`, { method: 'GET', headers });
        const { is_unique } = await res.json();
        return is_unique;
      } catch (err) {
        console.error(err);
      }
    }),
  descr: string().max(255),
  contact_name: string().max(50).required('Enter the primary contact for this organization'),
  contact_email: string().email('Invalid email').max(255),
  contact_phone: string().matches(mPhone, 'Invalid phone number'),
  address: string().required('Please enter a street address').max(50),
  city: string().required('Please enter a city name').max(50),
  postal_code: string().required('Please enter a zip code').matches(mPostalCode, 'Invalid zip'),
});

export default newOrganizationValidationSchema;
