import * as yup from 'yup';
import { INewAccount } from 'views/Registration/interfaces/INewAccount';
import { newAccountPasswordSchema } from './password';

export const newAccountValidationSchema = (emailValidation) =>
  yup.object<INewAccount>({
    email: yup.string().trim().email('Email is not valid'),
    confirm_email: emailValidation
      ? yup
          .string()
          .required('Confirm Email Address Required')
          .oneOf([yup.ref('email'), ''], 'Emails must match')
      : undefined,
    password: newAccountPasswordSchema(),
    confirm_password: yup
      .string()
      .required('Confirm Password Required')
      .oneOf([yup.ref('password'), ''], 'Passwords must match'),
  });

export default newAccountValidationSchema;
