import { createAsyncThunk, createSlice, unwrapResult, createEntityAdapter } from '@reduxjs/toolkit';
import { IOrganization } from 'common/interfaces/IOrganization';
import { IUser } from 'common/interfaces/IUser';
import * as authActions from './actions';

const userAPI = {
  fetchUserById: (userId) => userId,
};

export interface IOrgMgmntState {
  users: IUser[];
  currentOrganization?: IOrganization;
  loading: string;
  currentRequestId?: number;
  error?: string | null;
}

const initialState: IOrgMgmntState = {
  users: [],
  currentOrganization: undefined,
  loading: 'idle',
  currentRequestId: undefined,
  error: null,
};

const orgSlice = createSlice({
  name: 'Org',
  initialState: initialState,
  reducers: {},
  // reducers: authReducers,
  extraReducers: (builder) => {
    builder.addCase(authActions.getUsersByOrgId.fulfilled, (state, { payload }) => {
      state.users = payload;
      state.loading = 'idle';
    });
    builder.addCase(authActions.getUsersByOrgId.pending, (state, { payload }) => {
      state.loading = 'pending';
    });
    builder.addCase(authActions.getUsersByOrgId.rejected, (state, { payload }) => {
      state.loading = 'idle';
    });

    builder.addCase(authActions.getOrgById.fulfilled, (state, { payload }) => {
      state.currentOrganization = payload;
      state.loading = 'idle';
    });
    builder.addCase(authActions.getOrgById.pending, (state, { payload }) => {
      state.loading = 'pending';
    });
    builder.addCase(authActions.getOrgById.rejected, (state, { payload }) => {
      state.loading = 'idle';
    });

    builder.addCase(authActions.createOrgUser.fulfilled, (state, { payload }) => {
      state.users.push(payload);
      state.loading = 'idle';
    });
    builder.addCase(authActions.createOrgUser.pending, (state, { payload }) => {
      state.loading = 'pending';
    });
    builder.addCase(authActions.createOrgUser.rejected, (state, { payload }) => {
      state.loading = 'idle';
    });
  },
});

export default orgSlice;
