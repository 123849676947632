import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { ReportProblem } from '@material-ui/icons';
import moment from 'moment';
import classes from 'classnames';
import { getUser as getUserAction } from 'views/Auth/redux/actions';
import { IUser } from 'common/interfaces/IUser';
import { IListDataItem } from 'views/OAuthManagement/interfaces/IListDataItem';
import { IAppPrivacyPolicyAttestation } from 'views/OAuthManagement/interfaces/IAppPrivacyPolicyAttestation';
import { getUserDisplayName } from 'common/utils/getUserDisplayName';
import { Button } from 'components/controls/Button';
import { MUIModal } from 'components/controls/MUI/MUIModal';
import ReviewAttestation from 'views/ApproveAttestation/components/ReviewAttestation';
import css from 'components/AppFrame/app-frame.module.scss';
import {
  getOAuthAppPrivacyPolicy as getOAuthAppPrivacyPolicyAction,
  getOAuthAppPrivacyPolicyAttestation as getOAuthAppPrivacyPolicyAttestationAction,
} from 'views/OAuthManagement/redux/actions';
import ReviewPrivacyPolicyAttestation from '../ReviewPrivacyPolicyAttestation';

interface IAppInfoProps {
  item: IListDataItem;
  bindClose: Function;
}

const AppInfo: React.FC<IAppInfoProps> = ({ item, bindClose }) => {
  const dispatch = useDispatch();

  const currentPrivacyPolicyAttestation = useSelector(
    (state) => state.oauthMngmt.currentPrivacyPolicyAttestation,
  ) as IAppPrivacyPolicyAttestation;

  const [developer, setDeveloper] = React.useState<IUser>();
  const [getError, setGetError] = React.useState('');
  const [attestationModal, setAttestationModal] = React.useState(false);
  const [privacyPolicyAttestationModal, setPrivacyPolicyAttestationModal] = React.useState(false);

  const toggleAttestationModal = () => {
    setAttestationModal(!attestationModal);
  };
  const togglePrivacyPolicyAttestationModal = () => {
    setPrivacyPolicyAttestationModal(!privacyPolicyAttestationModal);
  };

  React.useEffect(() => {
    if (item.user) {
      setDeveloper(item.user);
    } else {
      getUserAction(item.app.username).then((user) => {
        setDeveloper(user);
      });
    }

    if (item.app.id) {
      dispatch(getOAuthAppPrivacyPolicyAttestationAction(item.app.id));
    }
  }, [dispatch, item.app.id, item.user]);

  const handleViewPrivacyPolicyClick = () => {
    if (item.app && item.app.id) {
      dispatch(getOAuthAppPrivacyPolicyAction(item.app.id)).then((response) => {
        if (response.error) {
          setGetError(response.error.message);
        } else {
          window.open(response.payload);
        }
      });
    }
  };

  return (
    <>
      <Grid container className={css.detailsRegion}>
        <Grid item xs={12}>
          <h4 className={css.modalHeader}>Application Info</h4>
        </Grid>
        <Grid item xs={4}>
          <div className={css.detailLabel}>APPLICATION NAME</div>
          <div className={css.detailData}>{item.client.name}</div>
        </Grid>
        <Grid item xs={4}>
          <div className={css.detailLabel}>DEVELOPED BY</div>
          {developer && (
            <>
              <div className={css.detailData}>{getUserDisplayName(developer)}</div>
              <div className={css.detailData}>
                <a href={`mailto:${developer.email}`}>{developer.email}</a>
              </div>
            </>
          )}
        </Grid>
        <Grid item xs={4}>
          <div className={css.detailLabel}>LAST UPDATED</div>
          <div className={css.detailData}>
            {moment(item.app.updated_date || item.app.created_date).format('MMMM DD, YYYY')}
          </div>
        </Grid>
      </Grid>
      {/* 11/19/2020 - commented out because mhulce wanted it hidden on this first pass */}
      {/* <Grid container className={css.detailsRegion}>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}>
          <div className={css.detailLabel}>REPORT THIS PRODUCT</div>
          <div className={css.detailData}>Report this app to MiHIN if you see it misusing information.</div>
          <div className={css.detailData}>
            <Button tertiary>
              <ReportProblem /> Report this App
            </Button>
          </div>
        </Grid>
        <Grid item xs={4}></Grid>
      </Grid> */}
      {!!item.app.app_descr && (
        <Grid container className={css.detailsRegion}>
          <Grid item xs={12}>
            <div className={css.detailLabel}>Application Description</div>
            <textarea disabled value={item.app.app_descr} />
          </Grid>
        </Grid>
      )}
      {!!getError && (
        <Grid container>
          <Grid item xs={12}>
            <Alert severity="error">{getError}</Alert>
          </Grid>
        </Grid>
      )}
      <Grid container>
        <Grid item xs={12} className={classes('alignTextRight', css.modalActionButtons)}>
          <Button type="button" secondary onClick={bindClose}>
            Close
          </Button>
          <Button type="button" onClick={handleViewPrivacyPolicyClick} disabled={!item.app.privacy_policy_url}>
            View Privacy Policy File
          </Button>
          <Button
            type="button"
            onClick={togglePrivacyPolicyAttestationModal}
            disabled={!currentPrivacyPolicyAttestation}
          >
            View Privacy Policy Attestation
          </Button>
          <Button type="button" onClick={toggleAttestationModal} disabled={!item.attestation}>
            View Security Attestation
          </Button>
        </Grid>
      </Grid>
      <MUIModal
        width="lg"
        allowClose
        open={privacyPolicyAttestationModal}
        handleClose={togglePrivacyPolicyAttestationModal}
      >
        <ReviewPrivacyPolicyAttestation
          bindCancel={togglePrivacyPolicyAttestationModal}
          attestation={currentPrivacyPolicyAttestation}
          application={item.app}
          developer={developer}
        />
      </MUIModal>
      <MUIModal width="lg" allowClose open={attestationModal} handleClose={toggleAttestationModal}>
        <ReviewAttestation
          bindComplete={toggleAttestationModal}
          bindCancel={toggleAttestationModal}
          forApproval={false}
          attestation={item.attestation}
          application={item.app}
          developer={developer}
        />
      </MUIModal>
    </>
  );
};

export default AppInfo;
