import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_URL, AUTH_SERVER_API_URL } from 'settings';
import _ from 'lodash';
import { getCurrentAuthHeaders } from 'common/utils/getCurrentAuthHeaders';
import { IOAuthApp } from '../interfaces/IOAuthApp';
import { IOAuthAppCredential } from '../interfaces/IOAuthAppCredential';
import { IAuthClient, credential_type_map } from '../interfaces/IAuthClient';
import { IAppPrivacyPolicyAttestation } from '../interfaces/IAppPrivacyPolicyAttestation';
import { IUser } from 'common/interfaces/IUser';

interface ICreateNewAppParams {
  authClient: IAuthClient;
  authApp: IOAuthApp;
  authAppPrivacyPolicyFile?: any;
}

interface IAddPrivacyPolicyParams {
  authApp: IOAuthApp;
  authAppPrivacyPolicyFile: any;
}

interface IRegisterToProdParams {
  authApp: IOAuthApp;
  authClient: IAuthClient;
}

export const getOAuthApps = createAsyncThunk('OAuthMngmt/applications', async () => {
  const headers = await getCurrentAuthHeaders('json');
  const response = await fetch(`${API_URL}/application`, { method: 'GET', headers });
  return (await response.json()) as IOAuthApp[];
});

export const getOAuthApp = createAsyncThunk('OAuthMngmt/application', async (appId: string | number) => {
  const headers = await getCurrentAuthHeaders('json');
  const response = await fetch(`${API_URL}/application/${appId}`, { method: 'GET', headers });
  return (await response.json()) as IOAuthApp;
});

export const updateOAuthApp = createAsyncThunk('OAuthMngmt/update-application', async (application: IOAuthApp) => {
  const headers = await getCurrentAuthHeaders('json');

  const portalAPIAppRequest = {
    method: 'PUT',
    headers,
    body: JSON.stringify(application),
  };

  const portalAPIAppResponse = await fetch(`${API_URL}/application/${application.id}`, portalAPIAppRequest);
  return await portalAPIAppResponse.text();
});
export const getOAuthAppPrivacyPolicy = createAsyncThunk(
  'OAuthMngmt/get-privacy-policy',
  async (appId: string | number) => {
    const headers = await getCurrentAuthHeaders('json');
    const response = await fetch(`${API_URL}/application/${appId}/get_privacy_policy`, { method: 'GET', headers });
    return await response.text();
  },
);

export const getOAuthAppDevelopers = createAsyncThunk(
  'OAuthMngmt/application-get-developers',
  async (applications: IOAuthApp[]) => {
    const usernames = _.uniq(
      applications.map((app) => {
        return app.username;
      }),
    );

    const headers = await getCurrentAuthHeaders('json');
    const portalAPIAppDevsRequest = {
      method: 'POST',
      headers,
      body: JSON.stringify(
        usernames.map((username) => {
          return { username: username };
        }),
      ),
    };

    const portalAPIAppResponse = await fetch(`${API_URL}/user/get_users`, portalAPIAppDevsRequest);
    return (await portalAPIAppResponse.json()) as IUser[];
  },
);

export const getOAuthAppCredentials = createAsyncThunk('OAuthMngmt/application-credentials', async () => {
  const headers = await getCurrentAuthHeaders('json');
  const response = await fetch(`${API_URL}/application/credentials`, { method: 'GET', headers });
  return (await response.json()) as IOAuthAppCredential[];
});

export const getOAuthAppCredential = createAsyncThunk(
  'OAuthMngmt/application-credential',
  async (appCredId: string) => {
    const headers = await getCurrentAuthHeaders('json');
    const response = await fetch(`${API_URL}/application/credential/${appCredId}`, { method: 'GET', headers });
    return (await response.json()) as IOAuthAppCredential;
  },
);

export const deleteOAuthAppCredential = createAsyncThunk(
  'OAuthMngmt/delete-application-credential',
  async (appCred: IOAuthAppCredential) => {
    const headers = await getCurrentAuthHeaders('json');

    const portalAPIAppRequest = {
      method: 'DELETE',
      headers,
    };

    const portalAPIAppResponse = await fetch(
      `${API_URL}/application/${appCred.application_id}/credential/${appCred.id}`,
      portalAPIAppRequest,
    );
    return await portalAPIAppResponse.text();
  },
);

export const getAuthClients = createAsyncThunk('OAuthMngmt/auth-clients', async () => {
  const headers = await getCurrentAuthHeaders('json');
  const response = await fetch(`${AUTH_SERVER_API_URL}/client`, { method: 'GET', headers });
  return (await response.json()) as IAuthClient[];
});

export const getAuthClient = createAsyncThunk('OAuthMngmt/auth-client', async (authClientId: string | number) => {
  const headers = await getCurrentAuthHeaders('json');
  const response = await fetch(`${AUTH_SERVER_API_URL}/client/${authClientId}`, { method: 'GET', headers });
  return (await response.json()) as IAuthClient;
});

export const updateAuthClient = createAsyncThunk('OAuthMngmt/update-auth-client', async (authClient: IAuthClient) => {
  delete authClient.client_id;
  delete authClient.client_secret;

  const headers = await getCurrentAuthHeaders('json');

  const authServerRequest = {
    method: 'PUT',
    headers,
    body: JSON.stringify(authClient),
  };

  const authServerResponse = await fetch(`${AUTH_SERVER_API_URL}/client/${authClient.id}`, authServerRequest);
  return await authServerResponse.text();
});

export const deleteAuthClient = createAsyncThunk('OAuthMngmt/delete-auth-client', async (authClient: IAuthClient) => {
  const headers = await getCurrentAuthHeaders('json');

  const authServerRequest = {
    method: 'DELETE',
    headers,
  };

  const authServerResponse = await fetch(`${AUTH_SERVER_API_URL}/client/${authClient.id}`, authServerRequest);
  return await authServerResponse.text();
});

export const createNewApp = createAsyncThunk<string, ICreateNewAppParams>(
  'OAuthMngmt/new-app',
  async ({ authClient, authApp, authAppPrivacyPolicyFile }) => {
    const headers = await getCurrentAuthHeaders('json');

    // Create the Auth Server Client
    const authServerRequest = {
      method: 'POST',
      headers,
      body: JSON.stringify(authClient),
    };

    const authServerResponse = await fetch(`${AUTH_SERVER_API_URL}/client`, authServerRequest);
    const newAuthClient = (await authServerResponse.json()) as IAuthClient;
    const newClientId = newAuthClient['smart_client_id'];

    // Create the Portal App
    const portalAPIAppRequest = {
      method: 'POST',
      headers,
      body: JSON.stringify(authApp),
    };
    const portalAPIAppResponse = await fetch(`${API_URL}/application/create`, portalAPIAppRequest);
    const newAppData = await portalAPIAppResponse.json();
    const newAppId = newAppData['application_id'];

    if (newAppId === undefined) {
      throw new Error(
        'There was a problem while creating the application. Please try again, if this persists please contact help@interoperabilityinstitute.org',
      );
    }

    // Create the Portal App Credentials
    const portalAPIAppCredRequest = {
      method: 'POST',
      headers,
      body: JSON.stringify({
        application_id: newAppId,
        auth_client_id: newClientId,
        credential_type:
          authClient.client_category === 'development' ? credential_type_map['DEV'] : credential_type_map['PROD'],
      }),
    };
    const portalAPIAppCredResponse = await fetch(
      `${API_URL}/application/${newAppId}/credential/create`,
      portalAPIAppCredRequest,
    );
    const newAppCredData = await portalAPIAppCredResponse.json();
    const newAppCredId = newAppCredData['application_credential_id'];

    // If we have a privacy policy, upload it
    if (authAppPrivacyPolicyFile) {
      try {
        // setup the form data
        const formData = new FormData();
        formData.append('myFile', authAppPrivacyPolicyFile);

        // set the content-type to the file type
        headers.set('Content-Type', authAppPrivacyPolicyFile.type);
        const portalAPIAppPrivacyPolicyRequest = {
          method: 'POST',
          headers,
          body: formData,
        };
        const portalAPIAppPrivacyPolicyResponse = await fetch(
          `${API_URL}/application/${newAppId}/set_privacy_policy`,
          portalAPIAppPrivacyPolicyRequest,
        );
        const uploadResponse = await portalAPIAppPrivacyPolicyResponse.json();
      } catch (err) {
        console.log('ERROR', err);
      }
    }

    return newAppCredId;
  },
);

export const registerAppToProd = createAsyncThunk<string, IRegisterToProdParams>(
  'OAuthMngmt/register-app-to-prod',
  async ({ authApp, authClient }) => {
    const headers = await getCurrentAuthHeaders('json');

    // Create the Auth Server Client
    const authServerRequest = {
      method: 'POST',
      headers,
      body: JSON.stringify(authClient),
    };

    const authServerResponse = await fetch(`${AUTH_SERVER_API_URL}/client`, authServerRequest);
    const newAuthClient = (await authServerResponse.json()) as IAuthClient;
    const newClientId = newAuthClient['smart_client_id'];

    // Create the Portal App Credentials
    const portalAPIAppCredRequest = {
      method: 'POST',
      headers,
      body: JSON.stringify({
        application_id: authApp.id,
        auth_client_id: newClientId,
        credential_type: credential_type_map['PROD'],
      }),
    };
    const portalAPIAppCredResponse = await fetch(
      `${API_URL}/application/${authApp.id}/credential/create`,
      portalAPIAppCredRequest,
    );
    const newAppCredData = await portalAPIAppCredResponse.json();
    const newAppCredId = newAppCredData['application_credential_id'];

    return newAppCredId;
  },
);

export const getOAuthAppPrivacyPolicyAttestation = createAsyncThunk(
  'OAuthMngmt/get-privacy-policy-attestation',
  async (appId: number) => {
    try {
      const headers = await getCurrentAuthHeaders('json');
      const response = await fetch(`${API_URL}/application/${appId}/get_privacy_policy_attestation`, {
        method: 'GET',
        headers,
      });
      return (await response.json()) as IAppPrivacyPolicyAttestation;
    } catch (ex) {
      throw ex;
    }
  },
);

export const submitOAuthAppPrivacyPolicyAttestation = createAsyncThunk(
  'OAuthMngmt/submit-privacy-policy-attestation',
  async (attestationData: IAppPrivacyPolicyAttestation) => {
    try {
      const headers = await getCurrentAuthHeaders('json');
      const response = await fetch(
        `${API_URL}/application/${attestationData.app_id}/submit_privacy_policy_attestation`,
        {
          method: 'POST',
          headers,
          body: JSON.stringify(attestationData),
        },
      );
      return await response.text();
    } catch (ex) {
      throw ex;
    }
  },
);

export const addOAuthAppPrivacyPolicy = createAsyncThunk<string, IAddPrivacyPolicyParams>(
  'OAuthMngmt/add-privacy-policy',
  async ({ authApp, authAppPrivacyPolicyFile }) => {
    const headers = await getCurrentAuthHeaders();

    try {
      // setup the form data
      const formData = new FormData();
      formData.append('myFile', authAppPrivacyPolicyFile);

      // set the content-type to the file type
      headers.set('Content-Type', authAppPrivacyPolicyFile.type);
      const portalAPIAppPrivacyPolicyRequest = {
        method: 'POST',
        headers,
        body: formData,
      };
      const portalAPIAppPrivacyPolicyResponse = await fetch(
        `${API_URL}/application/${authApp.id}/set_privacy_policy`,
        portalAPIAppPrivacyPolicyRequest,
      );
      return await portalAPIAppPrivacyPolicyResponse.text();
    } catch (err) {
      console.log('ERROR', err);
      return err;
    }
  },
);

export const deleteOAuthAppPrivacyPolicy = createAsyncThunk(
  'OAuthMngmt/delete-privacy-policy',
  async (appId: string | number) => {
    const headers = await getCurrentAuthHeaders('json');

    const portalAPIAppRequest = {
      method: 'DELETE',
      headers,
    };

    const portalAPIAppResponse = await fetch(
      `${API_URL}/application/${appId}/remove_privacy_policy`,
      portalAPIAppRequest,
    );
    return await portalAPIAppResponse.text();
  },
);

export const getPayerCredential = createAsyncThunk('OAuthMngmt/payer-credential', async () => {
  const headers = await getCurrentAuthHeaders('json');
  const response = await fetch(`${API_URL}/application/external_payer_credential`, { method: 'GET', headers });
  return (await response.json()) as IOAuthAppCredential;
});
