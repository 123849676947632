import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Formik, ErrorMessage } from 'formik';
import { Grid, CircularProgress } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import classes from 'classnames';
import { Button } from 'components/controls/Button';
import { IDemographicsRequest } from 'views/Registration/interfaces/IDemographicsRequest';
import { submitSessionRequest, setRegistrationError } from 'views/Registration/redux/actions';
import css from 'components/AppFrame/app-frame.module.scss';
import regCSS from 'views/Registration/registration.module.scss';
import { StateSelector } from 'components/controls/StateSelector';
import { GenderSelector } from 'components/controls/GenderSelector';
import { RecoveryQuestionSelector } from 'components/controls/RecoveryQuestionSelector';
import { TextCallout } from 'components/TextCallout';
import initialQuestionsValidationSchema from 'views/Registration/schemas/initialQuestionsValidationSchema';
import { IInitialQuestionsProps } from 'views/Registration/interfaces/IInitialQuestionsProps';

const InitialQuestions: React.FC<IInitialQuestionsProps> = ({ org }) => {
  const dispatch = useDispatch();

  const initialValues: IDemographicsRequest = {
    first_name: '',
    middle_initial: '',
    last_name: '',
    date_of_birth: null,
    street: '',
    street2: '',
    city: '',
    state: '',
    zip_code: '',
    phone: '',
    is_mobile: false,
    email: '',
    gender: '',
    ssn_4: '',
    org_id: org.id,
    recovery_question: '',
    recovery_answer: '',
  };

  const [fullName, setFullName] = React.useState('');

  const handleSubmit = (values: IDemographicsRequest, setSubmitting: Function) => {
    setFullName(`${values.first_name} ${values.last_name}`);
    values.email = values.email?.trim();
    values.date_of_birth = moment(values.date_of_birth).format('YYYY-MM-DD');
    values.phone = values.phone?.replace(/[^0-9\.]+/g, '');
    dispatch(submitSessionRequest(values)).then((resp) => {
      setSubmitting(false);
      if (resp.error) {
        dispatch(setRegistrationError({ session_id: '', code: resp.error.message }));
      }
    });
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={(values, { setSubmitting }) => handleSubmit(values, setSubmitting)}
        validationSchema={initialQuestionsValidationSchema(org.feature_okta_recovery_question_and_answer)}
      >
        {({ values, handleChange, setFieldValue, handleBlur, handleSubmit, isSubmitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              {isSubmitting ? (
                <Grid container>
                  <Grid item xs={12}>
                    <h4 className="alignTextCenter">
                      <CircularProgress size={'8rem'} />
                      <br /> One moment, verifying you are {fullName}...
                    </h4>
                  </Grid>
                </Grid>
              ) : (
                <Grid container>
                  <Grid item xs={12}>
                    <h2>{org.registration_form_header}</h2>
                    <p>
                      Let's get you registered. First, fill out the information below and we'll ask you a few questions
                      to confirm your identity. Then, you'll create your account to log in.
                    </p>
                  </Grid>
                  <Grid item xs={12} className={css.formRow}>
                    <label htmlFor="first_name">First Name</label>
                    <input
                      name="first_name"
                      placeholder="Enter First Name"
                      type="text"
                      value={values.first_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage name="first_name">
                      {(msg) => <div className="input-feedback">{msg}</div>}
                    </ErrorMessage>
                  </Grid>
                  <Grid item xs={4} className={css.formRow}>
                    <label htmlFor="middle_initial">Middle Initial</label>
                    <input
                      name="middle_initial"
                      placeholder="Enter MI"
                      type="text"
                      maxLength={1}
                      value={values.middle_initial}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage name="middle_initial">
                      {(msg) => <div className="input-feedback">{msg}</div>}
                    </ErrorMessage>
                  </Grid>
                  <Grid item xs={12} className={css.formRow}>
                    <label htmlFor="last_name">Last Name</label>
                    <input
                      name="last_name"
                      placeholder="Enter Last Name"
                      type="text"
                      value={values.last_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage name="last_name">{(msg) => <div className="input-feedback">{msg}</div>}</ErrorMessage>
                  </Grid>
                  <Grid item xs={8} className={css.formRow}>
                    <label htmlFor="date_of_birth">Date of Birth (YYYY-MM-DD)</label>
                    <KeyboardDatePicker
                      disableFuture
                      variant="inline"
                      format="yyyy-MM-dd"
                      id="date-picker-inline"
                      invalidDateMessage=""
                      value={values.date_of_birth}
                      onChange={(ioDate) => {
                        if (moment(ioDate).isValid()) {
                          setFieldValue('date_of_birth', ioDate);
                        }
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      className="datepicker"
                    />
                    <ErrorMessage name="date_of_birth">
                      {(msg) => <div className="input-feedback">{msg}</div>}
                    </ErrorMessage>
                  </Grid>
                  <Grid container className={css.formTwoCols}>
                    <Grid item xs={6} className={css.formRow}>
                      <label htmlFor="gender">Gender</label>
                      <GenderSelector
                        name="gender"
                        placeholder="Select Gender"
                        multipleSelect={false}
                        onChange={(v) => {
                          handleChange(v);
                        }}
                      />
                      <ErrorMessage name="gender">{(msg) => <div className="input-feedback">{msg}</div>}</ErrorMessage>
                    </Grid>
                    <Grid item xs={6} className={css.formRow}>
                      <label htmlFor="ssn_4">Last 4 of your SSN</label>
                      <input
                        name="ssn_4"
                        placeholder="SSN last 4"
                        type="password"
                        maxLength={4}
                        value={values.ssn_4}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <ErrorMessage name="ssn_4">{(msg) => <div className="input-feedback">{msg}</div>}</ErrorMessage>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} className={css.formRow}>
                    <label htmlFor="street">Street Address</label>
                    <input
                      name="street"
                      placeholder="Enter Street Address"
                      type="text"
                      value={values.street}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage name="street">{(msg) => <div className="input-feedback">{msg}</div>}</ErrorMessage>
                  </Grid>
                  <Grid item xs={6} className={css.formRow}>
                    <input
                      name="street2"
                      placeholder=""
                      type="text"
                      value={values.street2}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage name="street2">{(msg) => <div className="input-feedback">{msg}</div>}</ErrorMessage>
                  </Grid>
                  <Grid item xs={12} className={css.formRow}>
                    <label htmlFor="city">City</label>
                    <input
                      name="city"
                      placeholder="Enter City"
                      type="text"
                      value={values.city}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage name="city">{(msg) => <div className="input-feedback">{msg}</div>}</ErrorMessage>
                  </Grid>
                  <Grid container className={css.formTwoCols}>
                    <Grid item xs={6} className={css.formRow}>
                      <label htmlFor="state">State</label>
                      <StateSelector
                        name="state"
                        placeholder="Select a State"
                        multipleSelect={false}
                        onChange={(v) => {
                          handleChange(v);
                        }}
                      />
                      <ErrorMessage name="state">{(msg) => <div className="input-feedback">{msg}</div>}</ErrorMessage>
                    </Grid>
                    <Grid item xs={6} className={css.formRow}>
                      <label htmlFor="zip_code">ZIP Code</label>
                      <input
                        name="zip_code"
                        placeholder="Enter ZIP Code"
                        type="text"
                        value={values.zip_code}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <ErrorMessage name="zip_code">
                        {(msg) => <div className="input-feedback">{msg}</div>}
                      </ErrorMessage>
                    </Grid>
                  </Grid>
                  <Grid container className={css.formTwoCols}>
                    <Grid item xs={6} className={css.formRow}>
                      <label htmlFor="phone">Phone Number</label>
                      <input
                        name="phone"
                        placeholder="Enter Phone Number"
                        type="text"
                        value={values.phone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <ErrorMessage name="phone">{(msg) => <div className="input-feedback">{msg}</div>}</ErrorMessage>
                    </Grid>
                    <Grid item xs={6} className={css.formRow}>
                      <label htmlFor="is_mobile">&nbsp;</label>
                      <label className={regCSS.mobileCheckbox}>
                        <input
                          name="is_mobile"
                          type="checkbox"
                          value={values.is_mobile ? 'on' : 'off'}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />{' '}
                        <span>Mobile Phone</span>
                      </label>
                    </Grid>
                  </Grid>
                  <Grid item xs={8} className={css.formRow}>
                    <label htmlFor="email">Email Address</label>
                    <input
                      name="email"
                      placeholder="Enter Email Address"
                      type="text"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage name="email">{(msg) => <div className="input-feedback">{msg}</div>}</ErrorMessage>
                  </Grid>

                  {org.feature_okta_recovery_question_and_answer && (
                    <Grid container>
                      <Grid item xs={12} className={css.formRow}>
                        <label htmlFor="recovery_question">Password Recovery Question and Answer</label>
                        <RecoveryQuestionSelector
                          name="recovery_question"
                          placeholder="Select a Question"
                          multipleSelect={false}
                          onChange={(v) => {
                            handleChange(v);
                          }}
                        />
                        <ErrorMessage name="recovery_question">
                          {(msg) => <div className="input-feedback">{msg}</div>}
                        </ErrorMessage>
                      </Grid>
                      <Grid item xs={12} className={css.formRow}>
                        <input
                          name="recovery_answer"
                          placeholder="Enter your Answer"
                          type="text"
                          value={values.recovery_answer}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage name="recovery_answer">
                          {(msg) => <div className="input-feedback">{msg}</div>}
                        </ErrorMessage>
                      </Grid>
                    </Grid>
                  )}

                  <Grid item xs={12} className={css.formRow}>
                    <Button type="submit" disabled={isSubmitting} className={regCSS.submitButton}>
                      Submit
                    </Button>
                  </Grid>
                  {org.registration_credit_statement && (
                    <Grid item xs={12} className={classes(css.formRow, regCSS.creditStatementText)}>
                      <TextCallout title="Credit Statement">{org.registration_credit_statement}</TextCallout>
                    </Grid>
                  )}
                </Grid>
              )}
            </form>
          );
        }}
      </Formik>
    </MuiPickersUtilsProvider>
  );
};

export default InitialQuestions;
