import { Grid } from '@material-ui/core';
import * as React from 'react';
import classes from 'classnames';
import css from './input-group.module.scss';
import { IInputGroupProps } from './interfaces/IInputGroupProps';

export const InputGroup: React.FC<IInputGroupProps> = (props) => {
  const { label, children, className } = props;
  return (
    <Grid container className={classes(css.inputGroup, className)}>
      <Grid item className={css.labelPart}>
        <label>{label}</label>
      </Grid>
      <Grid item className={css.inputPart}>
        {children}
      </Grid>
    </Grid>
  );
};
