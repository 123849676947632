import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AppFrame from 'components/AppFrame';
import { Grid, Card, CardContent } from '@material-ui/core';
import { Edit, ArrowForwardIos, Add, Group } from '@material-ui/icons';
import { Button } from 'components/controls/Button';
import css from './organization-management.module.scss';
import { IOrganization } from 'common/interfaces/IOrganization';
import { getOrgs as getOrgsAction } from 'views/OrganizationManagement/redux/actions';
import { getUsersByOrgId as getUsersByOrgIdAction } from 'views/Organization/redux/actions';
import { IUser } from 'common/interfaces/IUser';
import { ISubMenuItem } from 'components/controls/SideMenu/interfaces/ISubMenuItem';
import { MUIModal } from 'components/controls/MUI/MUIModal';
import NewOrganizationForm from 'views/Organization/NewOrganizationForm';

// https://app.zeplin.io/project/5f4eb371598cfa10a60f245d/screen/5f6e0758434efb468c216913

const OrganizationManagement: React.FC = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const organizations = useSelector((state) => state.orgMgmnt.orgs);
  const orgUsers = useSelector((state) => state.org.users);

  const [newOrganizationModalOpen, setNewOrganizationModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('active-orgs');
  const [selectedOrganization, setSelectedOrganization] = useState<IOrganization>();
  const [selectedOrganizationActiveUsers, setSelectedOrganizationActiveUsers] = useState<IUser[]>();
  const [selectedOrganizationPendingUsers, setSelectedOrganizationPendingUsers] = useState<IUser[]>();

  useEffect(() => {
    dispatch(getOrgsAction());
  }, []);

  useEffect(() => {
    // preload first organization once we have them all
    if (organizations.length > 0) {
      setSelectedOrganization(organizations[0]);
    }
  }, [organizations]);

  useEffect(() => {
    dispatch(getUsersByOrgIdAction(selectedOrganization?.id));
  }, [selectedOrganization]);

  useEffect(() => {
    // when we have all of the selected org users, sort them by status
    setSelectedOrganizationActiveUsers(
      orgUsers.filter((user) => {
        return user.status === 'CONFIRMED';
      }),
    );
    setSelectedOrganizationPendingUsers(
      orgUsers.filter((user) => {
        return user.status !== 'CONFIRMED';
      }),
    );
  }, [orgUsers]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };
  const handleOrgDetailsClick = () => {
    history.push(`/orgs/${selectedOrganization?.id}`);
  };

  const toggleNewOrganizationModalOpen = () => setNewOrganizationModalOpen(!newOrganizationModalOpen);

  const handleNewOrgButtonClick = () => setNewOrganizationModalOpen(true);
  const handleEditOrgButtonClick = () => {
    console.log('Not Implemented Yet');
  };

  const handleNewOrganizationCancel = () => setNewOrganizationModalOpen(false);
  const handleNewOrganizationComplete = () => setNewOrganizationModalOpen(false);

  const subMenuItems: ISubMenuItem[] = [
    {
      label: `All Organizations (${organizations.length})`,
      active: true,
      action: () => history.push('/orgs'),
      icon: <Group />,
    },
    {
      label: 'Add Organization',
      active: false,
      action: handleNewOrgButtonClick,
      icon: <Add />,
    },
  ];

  return (
    <>
      <AppFrame layoutType="adminPortal" selectedMenuItem="OrgManagement" subMenuItems={subMenuItems}>
        <Grid container>
          <Grid item xs={6}>
            <h2 className="section-title">Organization Management</h2>
          </Grid>
          <Grid item xs={6} className="alignTextRight">
            <Button onClick={handleNewOrgButtonClick}>Add New Organization</Button>
          </Grid>
        </Grid>
        <Grid container>
          <ul className="tabBar">
            <li>
              <button
                className={activeTab === 'active-orgs' ? 'active' : ''}
                onClick={() => handleTabChange('active-orgs')}
              >
                Active Organizations
              </button>
            </li>
          </ul>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <h5>Organizations</h5>
          </Grid>
          <Grid item xs={3}>
            <ul className={css.orgList}>
              {organizations.map((org, index) => (
                <li key={`org-item-${index}`}>
                  <button
                    className={org.id === selectedOrganization?.id ? css.active : ''}
                    onClick={() => setSelectedOrganization(org)}
                  >
                    {org.name}
                    {org.id === selectedOrganization?.id && <ArrowForwardIos />}
                  </button>
                </li>
              ))}
            </ul>
          </Grid>
          <Grid item xs={9}>
            {selectedOrganization && (
              <Card className={css.orgDetails}>
                <CardContent>
                  <Grid container>
                    <Grid item xs={11}>
                      <h4>{selectedOrganization.name}</h4>
                    </Grid>
                    <Grid item xs={1} className="alignTextRight">
                      <Button type="link" className={css.editLink} onClick={handleEditOrgButtonClick}>
                        <Edit />
                      </Button>
                    </Grid>
                    <Grid item xs={8}>
                      <h5>Organization Details</h5>
                      <div className={css.detailInfo}>{selectedOrganization.descr}</div>
                    </Grid>
                    <Grid item xs={4}>
                      <h5>Organization Contact</h5>
                      <div className={css.detailInfo}>{selectedOrganization.contact_name}</div>
                      <h5>Address</h5>
                      <div className={css.detailInfo}>
                        {selectedOrganization.address}
                        <br />
                        {selectedOrganization.city}, {selectedOrganization.state} {selectedOrganization.postal_code}
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <hr />
                    </Grid>
                    <Grid item xs={4}>
                      <Button secondary onClick={handleOrgDetailsClick}>
                        View Organization Details
                      </Button>
                    </Grid>
                    <Grid item xs={2} className={css.userInfo}>
                      <h3>{selectedOrganizationActiveUsers?.length}</h3>
                      <div className={css.detailInfo}>Active Users</div>
                    </Grid>
                    <Grid item xs={6} className={css.userInfo}>
                      <h3>{selectedOrganizationPendingUsers?.length}</h3>
                      <div className={css.detailInfo}>Pending Invites</div>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            )}
          </Grid>
        </Grid>
        <MUIModal width="md" allowClose open={newOrganizationModalOpen} handleClose={toggleNewOrganizationModalOpen}>
          <NewOrganizationForm bindComplete={handleNewOrganizationComplete} bindCancel={handleNewOrganizationCancel} />
        </MUIModal>
      </AppFrame>
    </>
  );
};

export default OrganizationManagement;
