import * as React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { devLibrarySideMenuBuilder } from 'common/utils/devLibrarySideMenuBuilder';
import { IUser } from 'common/interfaces/IUser';
import { TLayoutType } from 'components/AppFrame/interfaces/TLayoutType';
import AppFrame from 'components/AppFrame';
import { CodeCallout } from 'components/CodeCallout';
import css from 'components/AppFrame/app-frame.module.scss';

const CDAComparedToFHIR: React.FC = (props) => {
  const history = useHistory();
  const currentUser = useSelector((state) => state.auth.user) as IUser;

  const layoutType: TLayoutType =
    currentUser?.user_type === 'developer'
      ? 'developerPortal'
      : currentUser?.user_type === 'payer'
      ? 'payerPortal'
      : 'adminPortal';

  const sideMenuItems = devLibrarySideMenuBuilder({ active: 'cdavfhir', history });

  return (
    <AppFrame layoutType={layoutType} selectedMenuItem="DocLibrary" subMenuItems={sideMenuItems}>
      <Grid container>
        <Grid item xs={12}>
          <h2>CDA as Compared to FHIR</h2>
          <p>
            A comparison of Fast Healthcare Interoperability Resources (FHIR®) and Clinical Document Architecture (CDA)
            begins with understanding a little about Health Level Seven (HL7). Since 1989 HL7 standards have supported
            health providers with transferring clinical and administrative data between software applications. These
            standards focus on the application layer of Open Systems Interconnection (OSI) model i.e., "layer 7".
          </p>
          <p>
            In the year 2000, HL7 released Clinical Document Architecture (CDA) is an XML-based standard for encoding
            supporting the exchange of human readable clinical data exchange. CDA allows providers to create digital
            documents containing patient information that they can send to other providers or government programs.
          </p>
          <p>
            Fast Healthcare Interoperability Resources (FHIR®) was released in 2019 as a standard describing data
            formats, data elements, and an Application Programing Interface (API) for exchanging electronic health
            records. The design of FHIR is based on Representational State Transfer (RESTful) web services which
            provides interoperability between computer systems on the internet.
          </p>
          <p>
            Both CDA and FHIR standards were developed by HL7. Therefore, FHIR has many components of CDA, but they are
            separated into smaller more manageable pieces. There isn’t much of a difference to be seen by just by
            looking at the code (Table 2 below), but there is a difference when using them as show in Table 1 below.
          </p>

          <table className={css.compareTable}>
            <caption>Table 1 CDA as compared to FHIR</caption>
            <thead>
              <tr>
                <th>CDA</th>
                <th>FHIR</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Is traditionally used in a read only medium.</td>
                <td>
                  Being a RESTful interface, allows developers to interact with the resources by creating, reading,
                  updating, and deleting them.
                </td>
              </tr>
              <tr>
                <td>
                  Intended to transmit and parse a huge file composed of patient demographics, observations, allergies,
                  immunizations, encounters, etc., for all years of a patient’s life.
                </td>
                <td>
                  Can make a specific request for a heart rate observation for last week and get that data only or use
                  the $everything query to pull the full medical history.
                </td>
              </tr>
              <tr>
                <td>
                  Becomes less uniform and confusing when processing larger documents for patients with more complex
                  care histories.
                </td>
                <td>
                  The modular approach breaks data into smaller, uniform pieces lending itself to more flexibility when
                  developing applications for both patients and providers.
                </td>
              </tr>
              <tr>
                <td>
                  The large format with too many data points increases the difficultly to keep pace with advances in
                  technology.
                </td>
                <td>
                  Can maintain pace with technology as each resource covers a small amount of coherent data, parts of it
                  can be changed without affecting everything else, and it is easy to develop Apps.
                </td>
              </tr>
            </tbody>
          </table>

          <p>As can be seen in Table 2, there is little difference in the code between CDA and FHIR.</p>
          <table className={css.compareTable}>
            <caption>Table 2 CDA code as compared to FHIR code</caption>
            <thead>
              <tr>
                <th>CDA</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <CodeCallout
                    hideBorder={true}
                    includeCopy={false}
                    code={`<?xml version="1.0"?>
  <ClinicalDocument 
    xmlns="urn:hl7-org:v3"
    xmlns:voc="urn:hl7-org:v3/voc"
    xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
    templateId="2.16.840.1.113883.3.27.1776">
    <!--********************************************************  CDA Header********************************************************-->
    <id extension="c266" root="2.16.840.1.113883.3.933"/>
    <code code="11488-4" codeSystem="2.16.840.1.113883.6.1" displayName="Consultation note"/>
      <title>Good Health Clinic Consultation Note</title>
        <effectiveTime value="20000407"/>
          <confidentialityCode code="N" codeSystem="2.16.840.1.113883.5.25"/>
            <setId extension="BB35" root="2.16.840.1.113883.3.933"/>
            <versionNumber value="2"/>
            <legalAuthenticator>
              <time value="20000408"/>
              <signatureCode code="S"/>
              <assignedEntity>
                <id extension="KP00017" root="2.16.840.1.113883.3.933"/>
                <assignedPerson>
                  <name>
                    <given>Robert</given>
                    <family>Dolin</family>
                    <suffix>MD</suffix>
                  </name>
                </assignedPerson>
                <representedOrganization>
                  <id extension="M345" root="2.16.840.1.113883.3.933"/>
                </representedOrganization>
              </assignedEntity>
            </legalAuthenticator>
            <author>
            ...
          `}
                  ></CodeCallout>
                </td>
              </tr>
              <tr>
                <th>FHIR</th>
              </tr>
              <tr>
                <td>
                  <CodeCallout
                    hideBorder={true}
                    includeCopy={false}
                    code={`{
  "resourceType": "Bundle",
  "id": "33d24439-061c-4f79-b272-c03e0963a39b",
  "meta": {
    "lastUpdated": "2018-01-03T21:33:59.788-05:00"
  },
  "type": "searchset",
  "total": 124,
  "link": [
    {
      "relation": "self",
      "url": "http://fhirtest.uhn.ca/baseDstu2/Patient/2228/$everything?_format=json"
    },
    {
      "relation": "next",
      "url": "http://fhirtest.uhn.ca/baseDstu2?_getpages=33d24439-061c-4f79-b272-c03e0963a39b&_getpagesoffset=10 _
              &_count=10&_format=json&_pretty=true&_bundletype=searchset"
    }
  ],
  "entry": [
    {
      "fullUrl": "http://fhirtest.uhn.ca/baseDstu2/Patient/2228",
      "resource": {
        "resourceType": "Patient",
        "id": "2228",
        "meta": {
          "versionId": "5",
          "lastUpdated": "2017-07-07T16:53:11.952-04:00"
        },
        "text": {
          "status": "generated", 
          "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">Generated by <a href=\"https://github.com/synthetichealth/synthea\">Synthea</a>. 
                    Version identifier: e4694a308119e0ec381819537257a2fe7ebccb2c </div>"
        },
        ...`}
                  ></CodeCallout>
                </td>
              </tr>
            </tbody>
          </table>

          <p>References and more information can be found at:</p>
          <p>
            <a href="https://en.wikipedia.org/wiki/Health_Level_7" target="_blank">
              https://en.wikipedia.org/wiki/Health_Level_7
            </a>
            <br />
            <a href="https://1up.health/blog/post/2018-01-15-fhir-vs-cda/" target="_blank">
              https://1up.health/blog/post/2018-01-15-fhir-vs-cda/
            </a>
            <br />
            <a
              href="https://www.w3.org/2011/10/integration-workshop/p/Similarities_and_Differences_between_HL7_CDA_Release_2.pdf"
              target="_blank"
            >
              https://www.w3.org/2011/10/integration-workshop/p/Similarities_and_Differences_between_HL7_CDA_Release_2.pdf
            </a>
          </p>
        </Grid>
      </Grid>
    </AppFrame>
  );
};

export default CDAComparedToFHIR;
