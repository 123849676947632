import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import IdleTimer from 'react-idle-timer';
import { Grid, CircularProgress } from '@material-ui/core';
import { Error } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { IUser } from 'common/interfaces/IUser';
import { IRegistrationRouteProps } from './interfaces/IRegistrationRouteProps';
import { clearSession, getOrgByCode } from 'views/Registration/redux/actions';
import AppFrame from 'components/AppFrame';
import InitialQuestions from './components/InitialQuestions';
import IdentityQuestions from './components/IdentityQuestions';
import IdentityResult from './components/IdentityResult';
import { IRegistrationError } from './interfaces/IRegistrationError';
import css from 'views/Registration/registration.module.scss';
import { CodeCallout } from 'components/CodeCallout';
import HelpDeskContact from './components/HelpDeskContact';

const Registration: React.FC<RouteComponentProps<IRegistrationRouteProps>> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const orgCode: string | undefined = props.match?.params.code;
  const currentSession = useSelector((state) => state.registration.currentSession);
  const currentOrg = useSelector((state) => state.registration.currentOrganization);
  const currentSubmit = useSelector((state) => state.registration.currentSubmit);
  const registrationError = useSelector((state) => state.registration.registrationError) as IRegistrationError;
  const currentUser = useSelector((state) => state.auth.user) as IUser;

  const timeout = 1800000; // 30 mins in ms
  let idleTimer: any = null;

  React.useEffect(() => {
    dispatch(clearSession());
    dispatch(getOrgByCode(orgCode)).then((res) => {
      if (res.error) {
        history.push('/');
      }
    });
  }, [dispatch]);

  React.useEffect(() => {
    if (currentUser) {
      history.push('/');
    }
  }, [currentUser]);

  const handleOnIdle = (event) => {
    dispatch(clearSession());
  };

  const parseErrorCode = (code: string) => {
    if (code.indexOf('[') !== -1 && code.indexOf(']') !== -1) {
      code = code.substring(code.indexOf('['), code.indexOf(']') + 1);
    }
    return `${code} - ${new Date().toUTCString()}`;
  };

  const isExperianError = (code: string) => {
    return code.indexOf('EXPERIAN') !== -1;
  };

  return (
    <AppFrame layoutType="registrationFrame" hideLogo={true}>
      {currentOrg && (
        <>
          <IdleTimer
            ref={(ref) => {
              idleTimer = ref;
            }}
            element={document}
            timeout={timeout}
            onIdle={handleOnIdle}
            debounce={250}
          />
          <Grid item xs={12}>
            {currentOrg.registration_logo_url && (
              <>
                <img src={currentOrg.registration_logo_url} alt={currentOrg.descr} className={css.logo} />
                <br />
              </>
            )}
            <strong>{currentOrg.descr}</strong>
          </Grid>
          {registrationError && (
            <>
              <Grid item xs={12}>
                <h2>We ran into a problem with your registration.</h2>

                {isExperianError(registrationError.code) && currentOrg.registration_experian_failure_message ? (
                  <>
                    <Alert severity={'error'}>
                      {currentOrg.registration_experian_failure_title ? (
                        <div>{currentOrg.registration_experian_failure_title}</div>
                      ) : (
                        <div>Identity Validation Failure</div>
                      )}
                    </Alert>
                    <p>{currentOrg.registration_experian_failure_message}</p>
                    <p>
                      <HelpDeskContact org={currentOrg} />
                    </p>
                  </>
                ) : (
                  <>
                    <Alert severity={'error'}>
                      <div>Please contact our help desk to continue.</div>
                    </Alert>
                    <p className="alignTextCenter">
                      <HelpDeskContact org={currentOrg} />
                    </p>
                  </>
                )}

                <div className="alignTextCenter">
                  <Error className={css.deniedIcon} />
                </div>
                {registrationError.code && (
                  <div>
                    <strong>Error Details:</strong>
                    <CodeCallout code={parseErrorCode(registrationError.code)} includeCopy={true} hideBorder={false} />
                  </div>
                )}
              </Grid>
            </>
          )}
          {!registrationError && (
            <>
              {!currentSession ? (
                <InitialQuestions org={currentOrg} />
              ) : currentSubmit ? (
                <IdentityResult session={currentSession} org={currentOrg} submitResponse={currentSubmit} />
              ) : (
                <IdentityQuestions session={currentSession} org={currentOrg} />
              )}
            </>
          )}
        </>
      )}
      {!currentOrg && (
        <Grid container>
          <Grid item xs={12}>
            <h4 className="alignTextCenter">
              <CircularProgress size={'8rem'} />
              <br /> Loading...
            </h4>
          </Grid>
        </Grid>
      )}
    </AppFrame>
  );
};

export default Registration;
