import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Auth } from 'aws-amplify';
import { MUIModal } from 'components/controls/MUI/MUIModal';
import TermsOfServiceModal from 'views/Auth/Signup/TermsOfService/TermsOfServiceModal';
import { logout as logoutAction, acceptTermsOfService as acceptTermsOfServiceAction } from 'views/Auth/redux/actions';
import { IUser } from 'common/interfaces/IUser';

export const HasLoggedIn = (WrappedComponent) => {
  const HasLoggedInComponent: React.FC = (props) => {
    const history = useHistory();
    const currentUser = useSelector((state) => state.auth.user) as IUser;
    const [termsOfServiceModalVisible, setTermsOfServiceModalVisible] = useState(false);
    const dispatch = useDispatch();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const checkLogin = () => {
      Auth.currentUserInfo()
        .then((user) => {
          if (!user.attributes['custom:terms_accepted']) {
            setTermsOfServiceModalVisible(true);
          }
          setIsLoggedIn(true);
        })
        .catch((err) => {
          history.push('/login');
        });
    };

    React.useEffect(() => {
      checkLogin();
    });

    const handleAcceptTermsComplete = () => {
      dispatch(acceptTermsOfServiceAction()).then(() => setTermsOfServiceModalVisible(false));
    };

    const handleAcceptTermsCancel = () => dispatch(logoutAction()).then(() => history.push('/login'));

    return (
      <>
        {(!!isLoggedIn || currentUser) && (
          <>
            {!termsOfServiceModalVisible && <WrappedComponent {...props} />}
            <MUIModal width="lg" allowClose={false} open={termsOfServiceModalVisible}>
              <TermsOfServiceModal bindComplete={handleAcceptTermsComplete} bindCancel={handleAcceptTermsCancel} />
            </MUIModal>
          </>
        )}
      </>
    );
  };

  return HasLoggedInComponent;
};
