export interface IAuthClient {
  id?: number;
  client_id?: string;
  client_secret?: string;
  client_type?: number;
  created_at?: string;
  default_redirect_uri?: string;
  deleted_at?: string;
  grant_type?: number;
  name?: string;
  redirect_uris?: string;
  scope?: string;
  client_category?: 'development' | 'production';
  organization_id?: number;
  dev_email?: string;
  pdex_client_flag?: boolean;
  seek_pdex_consent?: boolean;
}

export const client_type_map = {
  1: 'Public',
  2: 'Confidential - Forms Auth',
  3: 'Confidential - Basic Auth',
};
export const clientOptions = [
  { value: 1, label: 'Public' },
  { value: 2, label: 'Confidential - Forms Auth' },
  { value: 3, label: 'Confidential - Basic Auth' },
];

export const grant_type_map = {
  1: 'Authorization Code',
  2: 'Implicit',
  3: 'Client Credentials',
  4: 'Password',
};

/*
2020/12/15 - Removing the other grant type options for now.
For Jira Ticket NTRP-398
*/
export const grantOptions = [
  { value: 1, label: 'Authorization Code' },
  // { value: 2, label: 'Implicit' },
  // { value: 3, label: 'Client Credentials' },
  // { value: 4, label: 'Password' },
];

export const credential_type_map = {
  DEV: 1,
  PROD: 2,
};
