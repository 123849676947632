import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Formik, ErrorMessage } from 'formik';
import { Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Button } from 'components/controls/Button';
import { trimAllStringProperties } from 'common/utils/trimAllStringProperties';
import { forgotPasswordValidationSchema } from './schemas/forgotPasswordValidationSchema';
import { IForgotPasswordProps } from './interfaces/IForgotPasswordProps';
import { IForgotPasswordValues } from './interfaces/IForgotPasswordValues';
import { forgotPassword as forgotPasswordAction } from 'views/Auth/redux/actions';
import css from 'components/AppFrame/app-frame.module.scss';
import successIcon from 'assets/icons/icon-forgot-password-success.svg';

const ForgotPassword: React.FC<IForgotPasswordProps> = (props) => {
  const { bindComplete, forAccountSettings } = props;
  const dispatch = useDispatch();
  const initialValues: IForgotPasswordValues = { username: '' };
  const [completed, setCompleted] = React.useState(false);

  const handleSubmit = (values: IForgotPasswordValues, setSubmitting: any) => {
    const trimmedValues = trimAllStringProperties(values);
    dispatch(forgotPasswordAction(trimmedValues)).then((resp) => {
      setCompleted(true);
    });

    setSubmitting(false);
  };

  return (
    <Grid container>
      {completed && (
        <>
          <Grid item xs={3}>
            <img src={successIcon} alt="InterOp Station" />
          </Grid>
          <Grid item xs={9}>
            <h4 className={css.modalHeader}>Success!</h4>
            <div className={css.subtitle}>A reset email was sent to the email associated with your account.</div>
            <Button type="button" onClick={bindComplete} secondary>
              {forAccountSettings ? 'Cancel' : 'Back To Login'}
            </Button>
          </Grid>
        </>
      )}
      {!completed && (
        <>
          <Grid item xs={12}>
            <h4 className={css.modalHeader}>Forgot your password?</h4>
            <div className={css.subtitle}>
              Please provide your username and we'll send you password reset instructions.
            </div>
          </Grid>
          <Grid item xs={12}>
            <Formik
              initialValues={initialValues}
              enableReinitialize={true}
              onSubmit={(values, { setSubmitting }) => handleSubmit(values, setSubmitting)}
              validationSchema={forgotPasswordValidationSchema}
            >
              {({ values, handleChange, handleBlur, handleSubmit, isSubmitting }) => {
                return (
                  <form onSubmit={handleSubmit} autoComplete="off">
                    <Grid container>
                      <Grid item xs={12}>
                        <label htmlFor="username">Username</label>
                      </Grid>
                      <Grid item xs={9} className={css.formRow}>
                        <input
                          id="username"
                          placeholder="Enter username"
                          type="text"
                          value={values.username}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          autoComplete="off"
                        />
                        <ErrorMessage name="username">
                          {(msg) => <div className="input-feedback">{msg}</div>}
                        </ErrorMessage>
                      </Grid>
                      <Grid item xs={3}>
                        <Button type="submit" disabled={isSubmitting} className={css.sendForgotPasswordReset}>
                          Send
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                );
              }}
            </Formik>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default ForgotPassword;
