import * as React from 'react';
import { Grid } from '@material-ui/core';
import { Button } from 'components/controls/Button';
import { IOAuthModalProps } from 'views/OAuthManagement/interfaces/IOAuthModalProps';
import classes from 'classnames';
import css from 'components/AppFrame/app-frame.module.scss';
import formCSS from './delete-app-confirm.module.scss';
import trashcanIcon from 'assets/icons/icon-lg-trashcan.svg';

const DeleteAppConfirm: React.FC<IOAuthModalProps> = ({ bindComplete, bindCancel }) => {
  return (
    <Grid container className="alignTextCenter">
      <Grid item xs={12}>
        <img className={formCSS.deleteIcon} src={trashcanIcon} />
        <h3>Are you sure you want to delete this application?</h3>
        <h4>This action cannot be undone.</h4>
      </Grid>
      <Grid item xs={12} className={classes('alignTextCenter', css.modalActionButtons)}>
        <Button type="button" secondary onClick={bindCancel}>
          Cancel
        </Button>
        <Button notice type="button" onClick={bindComplete}>
          Delete
        </Button>
      </Grid>
    </Grid>
  );
};

export default DeleteAppConfirm;
