import { createAsyncThunk, createSlice, unwrapResult, createEntityAdapter } from '@reduxjs/toolkit';
import { IOrganization } from 'common/interfaces/IOrganization';
import { IUser } from 'common/interfaces/IUser';
import * as authActions from './actions';

export interface IOrgMgmntState {
  orgs: IOrganization[];
  loading: string;
  currentRequestId?: number;
  error?: string | null;
}

const initialState: IOrgMgmntState = {
  orgs: [],
  loading: 'idle',
  currentRequestId: undefined,
  error: null,
};

const orgMgmtSlice = createSlice({
  name: 'OrgMgmt',
  initialState: initialState,
  reducers: {},
  // reducers: authReducers,
  extraReducers: (builder) => {
    builder.addCase(authActions.getOrgs.fulfilled, (state, { payload }) => {
      state.orgs = payload;
      state.loading = 'idle';
    });
    builder.addCase(authActions.getOrgs.pending, (state, { payload }) => {
      state.loading = 'pending';
    });
    builder.addCase(authActions.getOrgs.rejected, (state, { payload }) => {
      state.loading = 'idle';
    });
  },
});

export default orgMgmtSlice;
