import React, { useState } from 'react';
import { Grid, Card, CardContent, CardActions } from '@material-ui/core';
import { Forward, HighlightOff, CheckCircleOutline } from '@material-ui/icons';
import { Button } from 'components/controls/Button';
import css from 'views/OAuthManagement/OAuthAppDetail/oauth-app-detail.module.scss';
import classes from 'classnames';
import { IAppSecurityAttestationProps } from 'views/OAuthManagement/interfaces/IAppSecurityAttestationProps';
import { MUIModal } from 'components/controls/MUI/MUIModal';
import SubmitAttestationRequest from 'views/DeveloperAttestation/AttestationFormModal';

const AppSecurityAttestation: React.FC<IAppSecurityAttestationProps> = ({ currentAttestation, currentApp }) => {
  const [attestationModalVisible, setAttestationModalVisible] = useState(false);

  const toggleAttestationSubmitModal = () => setAttestationModalVisible(!attestationModalVisible);

  return (
    <Grid item xs={3} className={css.detailRegion}>
      <Card className={classes(css.detailCard, { [css.success]: currentAttestation?.status === 'complete' })}>
        <CardContent className={css.detailBody}>
          <h3>Security Attestation</h3>
          {(!currentAttestation || currentAttestation?.status === 'incomplete') && (
            <div className={css.warningMessage}>
              <HighlightOff />
              Your attestation has not yet been submitted.
            </div>
          )}
          {currentAttestation?.status === 'rejected' && (
            <div className={css.message}>
              <HighlightOff />
              There is a problem with your attestation.
            </div>
          )}
          {currentAttestation?.status === 'pending' && (
            <div className={css.successMessage}>
              <CheckCircleOutline />
              Your attestation is awaiting review.
            </div>
          )}
          {currentAttestation?.status === 'complete' && (
            <div className={css.successMessage}>
              <CheckCircleOutline />
              Your attestation is approved.
            </div>
          )}
        </CardContent>
        <CardActions className={classes(css.detailActions, css.policyAndSecurityActions)}>
          {(!currentAttestation ||
            (currentAttestation?.status !== 'complete' && currentAttestation?.status !== 'pending')) && (
            <Button tertiary onClick={() => toggleAttestationSubmitModal()}>
              <Forward /> {currentAttestation?.status === 'rejected' ? 'Resubmit' : 'Submit'}
            </Button>
          )}
        </CardActions>
      </Card>
      <MUIModal width="lg" allowClose open={attestationModalVisible} handleClose={toggleAttestationSubmitModal}>
        <SubmitAttestationRequest bindComplete={toggleAttestationSubmitModal} />
      </MUIModal>
    </Grid>
  );
};

export default AppSecurityAttestation;
