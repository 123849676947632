import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AppFrame from 'components/AppFrame';
import { Grid, Card, CardContent, CircularProgress } from '@material-ui/core';
import { RadioButtonUnchecked, CheckCircleOutline } from '@material-ui/icons';
import _ from 'lodash';
import Select from 'react-select';
import moment from 'moment';
import classes from 'classnames';
import { Button } from 'components/controls/Button';
import { ISubMenuItem } from 'components/controls/SideMenu/interfaces/ISubMenuItem';
import { IDeveloperAttestation } from 'views/DeveloperAttestation/interfaces/IDeveloperAttestation';
import { InputGroup } from 'components/controls/InputGroup';
import { MUIChip } from 'components/controls/MUI/MUIChip';
import { IListDataItem } from 'views/OAuthManagement/interfaces/IListDataItem';
import css from 'views/ApproveAttestation/approve-attestation.module.scss';
import {
  getOAuthApps as getOAuthAppsAction,
  getOAuthAppCredentials as getOAuthAppCredentialsAction,
  getAuthClients as getAuthClientsAction,
} from 'views/OAuthManagement/redux/actions';
import { getUser as getUserAction } from 'views/Auth/redux/actions';
import * as attestationActions from 'views/DeveloperAttestation/redux/actions';
import { MUIModal } from 'components/controls/MUI/MUIModal';
import ReviewAttestation from 'views/ApproveAttestation/components/ReviewAttestation';
import { IOAuthApp } from 'views/OAuthManagement/interfaces/IOAuthApp';
import { IUser } from 'common/interfaces/IUser';
import { getUserDisplayName } from 'common/utils/getUserDisplayName';
import { listDataBuilder } from 'common/utils/listDataBuilder';





const muiChipCustomLabels = {
  incomplete: 'Open',
  pending: 'Pending',
  rejected: 'Issue',
  complete: 'Approved',
};

const ApproveAttestation = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = React.useState('pending');
  const oauthApps = useSelector((state) => state.oauthMngmt.oauthApps);
  const oauthAppCredentials = useSelector((state) => state.oauthMngmt.oauthAppCredentials);
  const authClients = useSelector((state) => state.oauthMngmt.authClients);
  const devAttestations = useSelector((state) => state.developerAttestations.attestations);
  const [listData, setListData] = React.useState<IListDataItem[]>([]);
  const [listUsers, setListUsers] = React.useState<IUser[]>([]);
  const [subMenuItems, setSubMenuItems] = React.useState<ISubMenuItem[]>([]);
  const [reviewModal, setReviewModal] = React.useState(false);
  const [currentAttestation, setCurrentAttestation] = React.useState<IDeveloperAttestation>();
  const [currentApplication, setCurrentApplication] = React.useState<IOAuthApp>();
  const [currentDeveloper, setCurrentDeveloper] = React.useState<IUser>();
  const [loadingData, setLoadingData] = React.useState(true);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    dispatch(attestationActions.getAttestations());
    dispatch(getOAuthAppCredentialsAction()).then(() => {
      dispatch(getOAuthAppsAction());
      dispatch(getAuthClientsAction()).then(() => {
        setLoadingData(false);
      });
    });
  }, [dispatch]);

  React.useEffect(() => {
    if (devAttestations.length > 0) {
      setSubMenuItems([
        {
          label: `Pending Approval (${
            devAttestations.filter((attestation: IDeveloperAttestation) => {
              return attestation.status === 'pending';
            }).length
          })`,
          active: activeTab === 'pending',
          action: () => setActiveTab('pending'),
          icon: <RadioButtonUnchecked />,
        },
        {
          label: `Approved (${
            devAttestations.filter((attestation: IDeveloperAttestation) => {
              return attestation.status === 'complete';
            }).length
          })`,
          active: activeTab === 'approved',
          action: () => setActiveTab('approved'),
          icon: <CheckCircleOutline />,
        },
        {
          label: `Revisions Required (${
            devAttestations.filter((attestation: IDeveloperAttestation) => {
              return attestation.status === 'rejected';
            }).length
          })`,
          active: activeTab === 'revisions',
          action: () => setActiveTab('revisions'),
        },
      ]);

      const usernames = _.uniq(
        devAttestations.map((data) => {
          return data.developer_id;
        }),
      );

      const users: IUser[] = [];
      _.each(usernames, (username) => {
        getUserAction(username).then((user) => users.push(user));
      });
      setListUsers(users);
    }
  }, [devAttestations, activeTab]);

  React.useEffect(() => {
    if (oauthApps.length > 0 && oauthAppCredentials.length > 0 && authClients.length > 0 && listUsers.length > 0) {
      const appData = listDataBuilder({
        credentials: oauthAppCredentials,
        applications: oauthApps,
        clients: authClients,
        attestations: devAttestations,
        appDevelopers: listUsers,
      });
      setListData(appData);
      setLoading(true);
    }
  }, [oauthApps, oauthAppCredentials, authClients, listUsers]);

  const toggleReview = () => {
    setReviewModal(!reviewModal);
  };
  const handleReviewComplete = () => {
    dispatch(attestationActions.getAttestations());
    toggleReview();
  };
  const handleReviewCancel = () => {
    toggleReview();
  };

  const renderListItem = (attestation: IDeveloperAttestation) => {
    const secondaryData: IListDataItem | undefined = _.find(
      listData,
      (listItem) => listItem.app?.id === attestation?.app_id, 
    );
    const attestationStatus = attestation?.status || 'incomplete';
    const handleOpenAttestationClick = () => {
      setCurrentAttestation(attestation);
      setCurrentApplication(secondaryData?.app);
      setCurrentDeveloper(secondaryData?.user);
      setReviewModal(true);
    };
    return (
      !!attestation &&
      !!secondaryData &&
      !!secondaryData.client &&
      !!secondaryData.app &&
      !!secondaryData.user && (
        <Card className={css.attestationListItem} key={`attestation_list_item_${attestation.id}`}>
          <CardContent>
            <Grid container>
              <Grid item xs={1}>
                <MUIChip type={attestationStatus} customLabel={muiChipCustomLabels[attestationStatus]} />
              </Grid>
              <Grid item xs={8}>
                <h4>{secondaryData?.client?.name}</h4>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={1}></Grid>
              <Grid item xs={3} className={css.attestDetails}>
                <div className={css.detailLabel}>DEVELOPED BY:</div>
                <div>{getUserDisplayName(secondaryData.user)}</div>
                <div>
                  <a href={`mailto:${secondaryData.user.email}`}>{secondaryData.user.email}</a>
                </div>
              </Grid>
              <Grid item xs={2} className={css.attestDetails}>
                {!!attestation.created_date && (
                  <>
                    <div className={css.detailLabel}>SUBMITTED ON:</div>
                    <div>{moment(attestation.created_date).format('MMM DD, YYYY')}</div>
                  </>
                )}
              </Grid>
              <Grid item xs={3} className={css.attestDetails}>
                {attestation.status === 'complete' && (
                  <>
                    <div className={classes(css.detailLabel, css.approved)}>APPROVED ON:</div>
                    <div>{moment(attestation.updated_date).format('MMM DD, YYYY')}</div>
                  </>
                )}
                {attestation.status === 'rejected' && (
                  <>
                    <div className={classes(css.detailLabel, css.rejected)}>ISSUE REPORTED ON:</div>
                    <div>{moment(attestation.updated_date).format('MMM DD, YYYY')}</div>
                  </>
                )}
              </Grid>
              <Grid item xs={3} className="alignTextRight">
                <Button
                  secondary
                  type="button"
                  onClick={handleOpenAttestationClick}
                  disabled={secondaryData.app == null || secondaryData.user == null}
                >
                  View
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )
    );
  };

  return (
    <AppFrame layoutType="adminPortal" selectedMenuItem="ApproveAttestation" subMenuItems={subMenuItems}>
      <Grid container>
        <Grid item xs={12}>
          <h2 className="section-title">Approve Attestation</h2>
          <div className="subtitle">All Attestation Requests</div>
        </Grid>
      </Grid>
      {loading && loadingData ? (
        <Grid container>
          <Grid item xs={12} className={css.loaderPanel}>
            <CircularProgress size="25px" /> <span>Loading....</span>
          </Grid>
        </Grid>
      ) : (
        <>
          {devAttestations.length > 0 && (
            <>
              <Grid container>
                <ul className="tabBar">
                  <li>
                    <button className={activeTab === 'pending' ? 'active' : ''} onClick={() => setActiveTab('pending')}>
                      Pending Approval
                    </button>
                  </li>
                  <li>
                    <button
                      className={activeTab === 'approved' ? 'active' : ''}
                      onClick={() => setActiveTab('approved')}
                    >
                      Approved
                    </button>
                  </li>
                  <li>
                    <button
                      className={activeTab === 'revisions' ? 'active' : ''}
                      onClick={() => setActiveTab('revisions')}
                    >
                      Revisions Required
                    </button>
                  </li>
                </ul>
              </Grid>
              {_.map(
                devAttestations.filter((attestation: IDeveloperAttestation) => {
                  if (activeTab === 'pending') {
                    return attestation.status === 'pending';
                  } else if (activeTab === 'approved') {
                    return attestation.status === 'complete';
                  } else {
                    return attestation.status === 'rejected';
                  }
                }),
                renderListItem,
              )}
            </>
          )}
        </>
      )}

      <MUIModal width="lg" allowClose open={reviewModal} handleClose={toggleReview}>
        <ReviewAttestation
          bindComplete={handleReviewComplete}
          bindCancel={handleReviewCancel}
          forApproval={true}
          attestation={currentAttestation}
          application={currentApplication}
          developer={currentDeveloper}
        />
      </MUIModal>
    </AppFrame>
  );
};

export default ApproveAttestation;

