import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IUser } from 'common/interfaces/IUser';

interface IIsUserOfTypeComponentProps {
  userType: string;
}

export const IsUserOfType = (WrappedComponent, userTypes: string[]) => {
  const IsUserOfTypeComponent: React.FC<IIsUserOfTypeComponentProps> = (props) => {
    const history = useHistory();
    const currentUser = useSelector((state) => state.auth.user) as IUser;

    React.useEffect(() => {
      if (!isUserAllowed()) {
        history.push('/');
      }
    }, []);

    const isUserAllowed = () => {
      const user_type = currentUser ? currentUser.user_type : '';
      return userTypes.includes(user_type || '');
    };

    return <>{isUserAllowed() ? <WrappedComponent {...props} /> : null}</>;
  };

  return IsUserOfTypeComponent;
};
