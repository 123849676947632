import { getCurrentJwt } from 'common/utils/getCurrentJwt';

export const getCurrentAuthHeaders = async (type?: 'json', includesAuthorization = true) => {
  const headers = new Headers();
  if (includesAuthorization) {
    const currentUserIdToken = await getCurrentJwt();
    if (currentUserIdToken) {
      headers.set('Authorization', `Bearer ${currentUserIdToken}`);
    }
  }

  if (type === 'json') {
    headers.set('Content-Type', 'application/json');
  }

  return headers;
};
