import React from 'react';
import { useDispatch } from 'react-redux';
import { IAppBaseFormProps } from 'views/OAuthManagement/interfaces/IAppBaseFormProps';
import { Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Save, Edit, PanoramaOutlined } from '@material-ui/icons';
import { Button } from 'components/controls/Button';
import css from 'views/OAuthManagement/OAuthAppDetail/oauth-app-detail.module.scss';
import moment from 'moment';
import { AppBanner } from 'components/AppBanner';
import {
  getAuthClient as getAuthClientAction,
  updateAuthClient as updateAuthClientAction,
} from 'views/OAuthManagement/redux/actions';

const AppBaseForm: React.FC<IAppBaseFormProps> = ({ currentClient, currentCredential, currentApp }) => {
  const dispatch = useDispatch();
  const [openTopBarEdit, setOpenTopBarEdit] = React.useState(false);
  const [sendError, setSendError] = React.useState('');
  const [appName, setAppName] = React.useState('');

  React.useEffect(() => {
    setAppName(currentClient.name || '');
  }, [currentClient]);

  const toggleTopBarEdit = () => {
    setAppName(currentClient?.name || '');
    // TODO: image setting
    setOpenTopBarEdit(!openTopBarEdit);
  };

  const handleTopBarSave = () => {
    dispatch(
      updateAuthClientAction({
        ...currentClient,
        name: appName,
      }),
    ).then((response) => {
      if (response.error) {
        setSendError(response.error.message);
      } else {
        dispatch(getAuthClientAction(currentCredential.auth_client_id));
        // TODO: save image
        toggleTopBarEdit();
      }
    });
  };

  return (
    <AppBanner>
      <Grid container className={css.topBar}>
        <Grid item xs={9}>
          {!!sendError && <Alert severity="error">{sendError}</Alert>}
          <h2>{appName}</h2>
          {currentApp.updated_date && (
            <div className={css.lastUpdated}>
              <strong>Last Updated:</strong> {moment(currentApp.updated_date).format('MMM DD, YYYY, h:mm a')}
            </div>
          )}
        </Grid>
        <Grid item xs={3} className={css.appImageRegion}>
          <PanoramaOutlined className={css.appImage} />
        </Grid>
      </Grid>
    </AppBanner>
  );
};

export default AppBaseForm;
