import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, ErrorMessage } from 'formik';
import { Card, CardContent, Grid, IconButton, CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import classes from 'classnames';
import AppFrame from 'components/AppFrame';
import { IUser } from 'common/interfaces/IUser';
import { userUpdateValidationSchema } from 'views/AccountSettings/schemas/userUpdateValidationSchema';
import { UserType } from 'common/property-list/user-types';
import { TLayoutType } from 'components/AppFrame/interfaces/TLayoutType';
import { Button } from 'components/controls/Button';
import { MUIChip } from 'components/controls/MUI/MUIChip';
import { trimAllStringProperties } from 'common/utils/trimAllStringProperties';
import {
  updateUser as updateUserAction,
  deleteCurrentAccount as deleteCurrentAccountAction,
  logout as logoutAction,
  getAuth as getAuthAction,
} from 'views/Auth/redux/actions';
import DeleteUserConfirm from 'views/AccountSettings/components/DeleteUserConfirm';
import ChangeUserPassword from 'views/AccountSettings/components/ChangeUserPassword';
import { MUIModal } from 'components/controls/MUI/MUIModal';
import css from 'views/AccountSettings/account-settings.module.scss';
import { useHistory } from 'react-router-dom';

const AccountSettings = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentUser = useSelector((state) => state.auth.user) as IUser;
  const layoutType: TLayoutType =
    currentUser?.user_type === 'developer'
      ? 'developerPortal'
      : currentUser?.user_type === 'payer'
      ? 'payerPortal'
      : 'adminPortal';

  const initialValues: IUser = currentUser;
  const [saveMessageType, setSaveMessageType] = React.useState<any>();
  const [saveMessage, setSaveMessage] = React.useState('');
  const [deleteConfirmModal, setDeleteConfirmModal] = React.useState(false);
  const [changePasswordModal, setChangePasswordModal] = React.useState(false);
  const [deletingAccount, setDeletingAccount] = React.useState(false);

  const handleSubmit = (values: IUser, setSubmitting: any) => {
    const trimmedValues = trimAllStringProperties(values);
    dispatch(updateUserAction(trimmedValues)).then((response) => {
      if (response.error) {
        setSaveMessageType('error');
        if (response.error.code === 'AliasExistsException') {
          setSaveMessage('That username already exists, please choose a different one.');
        } else {
          setSaveMessage(response.error.message);
        }
      } else {
        setSaveMessageType('info');
        if (trimmedValues.email !== currentUser.email) {
          setSaveMessage(
            'Changes saved successfully. Your updated email address needs to be verified before you can log in with it. Please, look in your email inbox for the verification message.',
          );
        } else {
          setSaveMessage('Changes saved successfully.');
        }
        dispatch(getAuthAction());
      }
    });
    setSubmitting(false);
  };

  const toggleDeleteConfirmation = () => {
    setDeleteConfirmModal(!deleteConfirmModal);
  };
  const handleDeleteClick = () => {
    toggleDeleteConfirmation();
    setDeletingAccount(true);
    dispatch(deleteCurrentAccountAction()).then((response) => {
      if (response.error) {
        setSaveMessageType('error');
        setSaveMessage(response.error);
        setDeletingAccount(false);
      } else {
        dispatch(logoutAction());
        history.push('/login');
      }
    });
  };

  const toggleChangePassword = () => {
    setChangePasswordModal(!changePasswordModal);
  };
  const handleChangePasswordComplete = () => {
    toggleChangePassword();
    setSaveMessageType('info');
    setSaveMessage('Your password has been successfully changed.');
  };

  return (
    <AppFrame layoutType={layoutType}>
      <Grid container>
        <Grid item xs={12}>
          <h2 className="section-title">Account Settings</h2>
        </Grid>
        <Grid item xs={12}>
          <h3>General Information</h3>
        </Grid>
        <Grid item xs={2} className="alignTextCenter">
          <AccountCircleIcon className={css.userIcon} />
          {currentUser?.user_type === 'mihin-admin' && (
            <div>
              <MUIChip type="admin" />
            </div>
          )}
        </Grid>
        <Grid item xs={10}>
          {!!saveMessage && (
            <Grid item xs={12}>
              <Alert
                severity={saveMessageType}
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setSaveMessage('');
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {saveMessage}
              </Alert>
            </Grid>
          )}
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={(values, { setSubmitting }) => handleSubmit(values, setSubmitting)}
            validationSchema={userUpdateValidationSchema}
          >
            {({ values, handleChange, handleBlur, handleSubmit, isSubmitting }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Grid container className={css.formRow}>
                    <Grid item xs={6}>
                      <label htmlFor="given_name">First Name</label>
                      <input
                        name="given_name"
                        placeholder="Enter First Name"
                        type="text"
                        value={values?.given_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <ErrorMessage name="given_name">
                        {(msg) => <div className="input-feedback">{msg}</div>}
                      </ErrorMessage>
                    </Grid>
                    <Grid item xs={6}>
                      <label htmlFor="family_name">Last Name</label>
                      <input
                        name="family_name"
                        placeholder="Enter Last Name"
                        type="text"
                        value={values?.family_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <ErrorMessage name="family_name">
                        {(msg) => <div className="input-feedback">{msg}</div>}
                      </ErrorMessage>
                    </Grid>
                  </Grid>
                  <Grid container className={css.formRow}>
                    <Grid item xs={6}>
                      <label htmlFor="email">Email</label>
                      <input
                        name="email"
                        placeholder="Enter Email"
                        type="text"
                        value={values?.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <ErrorMessage name="email">{(msg) => <div className="input-feedback">{msg}</div>}</ErrorMessage>
                    </Grid>
                    <Grid item xs={6}>
                      <label htmlFor="preferred_username">Username</label>
                      <input
                        name="preferred_username"
                        placeholder="Enter Username"
                        type="text"
                        value={values?.preferred_username}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <ErrorMessage name="preferred_username">
                        {(msg) => <div className="input-feedback">{msg}</div>}
                      </ErrorMessage>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={6}>
                      <label>Account Type</label>
                      <div className="subtitle">{UserType[currentUser?.user_type || 'developer']}</div>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12}>
                      <hr />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} className={classes('alignTextRight', css.saveRow)}>
                      <Button type="submit">Save Changes</Button>
                    </Grid>
                  </Grid>
                </form>
              );
            }}
          </Formik>
          <Grid container>
            <Grid item xs={12}>
              <Card className={css.extraFunctionCard}>
                <CardContent>
                  <Grid container>
                    <Grid item xs={6}>
                      <h3>Password</h3>
                      <div className="subtitle">Set a unique password to protect your account.</div>
                    </Grid>
                    <Grid item xs={6} className="alignTextRight">
                      <Button onClick={toggleChangePassword}>Change Password</Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Card className={css.extraFunctionCard}>
                <CardContent>
                  {deletingAccount && (
                    <Grid container>
                      <Grid item xs={12} className="alignTextCenter">
                        <h2>
                          <CircularProgress />
                        </h2>
                        <h3>Deleting your account</h3>
                      </Grid>
                    </Grid>
                  )}
                  {!deletingAccount && (
                    <Grid container>
                      <Grid item xs={6}>
                        <h3>Delete Account</h3>
                        <div className="subtitle">This will permanently delete your account.</div>
                      </Grid>
                      <Grid item xs={6} className="alignTextRight">
                        <Button notice onClick={toggleDeleteConfirmation}>
                          Delete
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <MUIModal width="md" allowClose open={changePasswordModal} handleClose={toggleChangePassword}>
        <ChangeUserPassword bindComplete={handleChangePasswordComplete} bindCancel={toggleChangePassword} />
      </MUIModal>
      <MUIModal width="md" allowClose open={deleteConfirmModal} handleClose={toggleDeleteConfirmation}>
        <DeleteUserConfirm bindComplete={handleDeleteClick} bindCancel={toggleDeleteConfirmation} />
      </MUIModal>
    </AppFrame>
  );
};

export default AccountSettings;
