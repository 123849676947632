import * as React from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { IListDataItem } from 'views/OAuthManagement/interfaces/IListDataItem';
import { credential_type_map } from 'views/OAuthManagement/interfaces/IAuthClient';
import css from 'views/Dashboard/dashboard.module.scss';
import { Button } from 'components/controls/Button';
import AppTile from 'views/Dashboard/components/AppTile';
import { listDataBuilder } from 'common/utils/listDataBuilder';

const PayerDashboard: React.FC = () => {
  const history = useHistory();
  const oauthApps = useSelector((state) => state.oauthMngmt.oauthApps);
  const oauthAppCredentials = useSelector((state) => state.oauthMngmt.oauthAppCredentials);
  const authClients = useSelector((state) => state.oauthMngmt.authClients);
  const devAttestations = useSelector((state) => state.developerAttestations.attestations);
  const [listData, setListData] = React.useState<IListDataItem[]>([]);

  React.useEffect(() => {
    if (oauthApps.length > 0 && oauthAppCredentials.length > 0 && authClients.length > 0) {
      const appData = listDataBuilder({
        credentials: oauthAppCredentials,
        applications: oauthApps,
        clients: authClients,
        attestations: devAttestations,
      }).filter((item) => {
        return item.credential.credential_type === credential_type_map.PROD;
      });
      setListData(appData);
    }
  }, [oauthApps, oauthAppCredentials, authClients, devAttestations]);

  const handleViewAllClick = () => {
    history.push('/applications');
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container className={css.sectionHeader}>
          <Grid item xs={8}>
            <h4>Production Applications</h4>
          </Grid>
          <Grid item xs={4} className="alignTextRight">
            <Button onClick={handleViewAllClick}>View All</Button>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            {listData.length === 0 && <h4>No developers have created applications yet at this point.</h4>}
            {_.map(listData, (listItem, index) => (
              <AppTile key={`oauth-cred-${index}`} item={listItem} />
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PayerDashboard;
