import * as React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { devLibrarySideMenuBuilder } from 'common/utils/devLibrarySideMenuBuilder';
import { IUser } from 'common/interfaces/IUser';
import { TLayoutType } from 'components/AppFrame/interfaces/TLayoutType';
import AppFrame from 'components/AppFrame';
import css from 'components/AppFrame/app-frame.module.scss';
import { CodeCallout } from 'components/CodeCallout';

const Endpoints: React.FC = (props) => {
  const history = useHistory();
  const currentUser = useSelector((state) => state.auth.user) as IUser;

  const layoutType: TLayoutType =
    currentUser?.user_type === 'developer'
      ? 'developerPortal'
      : currentUser?.user_type === 'payer'
      ? 'payerPortal'
      : 'adminPortal';

  const sideMenuItems = devLibrarySideMenuBuilder({ active: 'endpoints', history });

  return (
    <AppFrame layoutType={layoutType} selectedMenuItem="DocLibrary" subMenuItems={sideMenuItems}>
      <Grid container>
        <Grid item xs={12}>
          <h2>InterOp Station® API Endpoints</h2>
          <h3>Development</h3>
          <h4>OAuth2 URL</h4>
          <CodeCallout
            code={`https://api.interopstation.com/dev1/oauth2/authorize`}
            includeCopy={true}
            hideBorder={false}
          ></CodeCallout>
          <h4>Token URL</h4>
          <CodeCallout
            code={`https://api.interopstation.com/dev1/oauth2/token`}
            includeCopy={true}
            hideBorder={false}
          ></CodeCallout>
          <h4>FHIR URL</h4>
          <CodeCallout
            code={`https://api.interopstation.com/dev1/fhir`}
            includeCopy={true}
            hideBorder={false}
          ></CodeCallout>
          <h4>Provider Directory URL</h4>
          <CodeCallout
            code={`https://api.interopstation.com/dev1/fhir/Endpoint
https://api.interopstation.com/dev1/fhir/HealthcareService
https://api.interopstation.com/dev1/fhir/InsurancePlan
https://api.interopstation.com/dev1/fhir/Location
https://api.interopstation.com/dev1/fhir/OrganizationAffiliation
https://api.interopstation.com/dev1/fhir/Organization
https://api.interopstation.com/dev1/fhir/PractitionerRole
https://api.interopstation.com/dev1/fhir/Practitioner`
            }
            includeCopy={true}
            hideBorder={false}
          ></CodeCallout>
          <hr className={css.docDivider} />
          <h3>BCBSM</h3>
          <h4>OAuth2 URL</h4>
          <CodeCallout
            code={`https://api.interopstation.com/bcbsm/oauth2/authorize`}
            includeCopy={true}
            hideBorder={false}
          ></CodeCallout>
          <h4>Token URL</h4>
          <CodeCallout
            code={`https://api.interopstation.com/bcbsm/oauth2/token`}
            includeCopy={true}
            hideBorder={false}
          ></CodeCallout>
          <h4>FHIR URL</h4>
          <CodeCallout
            code={`https://api.interopstation.com/bcbsm/fhir`}
            includeCopy={true}
            hideBorder={false}
          ></CodeCallout>
          <h4>Provider Directory URL</h4>
          <CodeCallout
            code={`https://api.interopstation.com/bcbsm/fhir/Endpoint
https://api.interopstation.com/bcbsm/fhir/HealthcareService
https://api.interopstation.com/bcbsm/fhir/InsurancePlan
https://api.interopstation.com/bcbsm/fhir/Location
https://api.interopstation.com/bcbsm/fhir/OrganizationAffiliation
https://api.interopstation.com/bcbsm/fhir/Organization
https://api.interopstation.com/bcbsm/fhir/PractitionerRole
https://api.interopstation.com/bcbsm/fhir/Practitioner`
            }
            includeCopy={true}
            hideBorder={false}
          ></CodeCallout>
          <hr className={css.docDivider} />
          <h3>NJHIN</h3>
          <h4>OAuth2 URL</h4>
          <CodeCallout
            code={`https://api.interopstation.com/njhin/oauth2/authorize`}
            includeCopy={true}
            hideBorder={false}
          ></CodeCallout>
          <h4>Token URL</h4>
          <CodeCallout
            code={`https://api.interopstation.com/njhin/oauth2/token`}
            includeCopy={true}
            hideBorder={false}
          ></CodeCallout>
          <h4>FHIR URL</h4>
          <CodeCallout
            code={`https://api.interopstation.com/njhin/fhir`}
            includeCopy={true}
            hideBorder={false}
          ></CodeCallout>
          <h4>Provider Directory URL</h4>
          <CodeCallout
            code={`https://api.interopstation.com/njhin/fhir/Endpoint
https://api.interopstation.com/njhin/fhir/HealthcareService
https://api.interopstation.com/njhin/fhir/InsurancePlan
https://api.interopstation.com/njhin/fhir/Location
https://api.interopstation.com/njhin/fhir/OrganizationAffiliation
https://api.interopstation.com/njhin/fhir/Organization
https://api.interopstation.com/njhin/fhir/PractitionerRole
https://api.interopstation.com/njhin/fhir/Practitioner`
            }
            includeCopy={true}
            hideBorder={false}
          ></CodeCallout>
        </Grid>
      </Grid>
    </AppFrame>
  );
};

export default Endpoints;
