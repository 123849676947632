import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_URL, AUTH_SERVER_API_URL } from 'settings';
import { getCurrentAuthHeaders } from 'common/utils/getCurrentAuthHeaders';
import { IDeveloperAttestation } from '../interfaces/IDeveloperAttestation';
import { IAuthClient } from 'views/OAuthManagement/interfaces/IAuthClient'
import { ICognitoUser } from 'common/interfaces/ICognitoUser';


export const getAttestations = createAsyncThunk(
  'DevAttestation/GET_DEVELOPER_ATTESTATIONS',
  async () => {
    const headers = await getCurrentAuthHeaders('json');
    const response = await fetch(`${API_URL}/attestation`, { method: 'GET', headers });
    return (await response.json()) as IDeveloperAttestation[];
  },
);

export const saveDevAttestation = createAsyncThunk<
  IDeveloperAttestation, IDeveloperAttestation>(
  'DevAttestation/SAVE_DEVELOPER_ATTESTATION',
  async (attestationData) => {
    const headers = await getCurrentAuthHeaders('json');
    const request = {
      method: attestationData.id ? 'PUT' : 'POST',
      headers,
      body: JSON.stringify(attestationData),
    };

    const response = await fetch(`${API_URL}/attestation/save`, request);
    return (await response.json()) as IDeveloperAttestation
  },
);

export const submitDevAttestation = createAsyncThunk<
  IDeveloperAttestation, IDeveloperAttestation>(
  'DevAttestation/SUBMIT_DEVELOPER_ATTESTATION',
  async (attestationData) => {
    const headers = await getCurrentAuthHeaders('json');
    const request = {
      method: attestationData.id ? 'PUT' : 'POST',
      headers,
      body: JSON.stringify(attestationData),
    };

    const response = await fetch(`${API_URL}/attestation/submit`, request);
    return (await response.json()) as IDeveloperAttestation
  },
);

export const updateDevAttestationStatus = createAsyncThunk<
  IDeveloperAttestation, IDeveloperAttestation>(
  'DevAttestation/UPDATE_DEVELOPER_ATTESTATION_STATUS',
  async (attestationData) => {
    const headers = await getCurrentAuthHeaders('json');    
    const request = {
      method: 'PUT',
      headers,
      body: JSON.stringify(attestationData),
    };

    const response = await fetch(`${API_URL}/attestation/${attestationData.id}/update_status`, request);
    return (await response.json()) as IDeveloperAttestation
  },
);
