import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { IDEQMDashboardRouteProps } from './interfaces/IDEQMDashboardRouteProps';
import DEQMOverviewView from './Overview';
import { TextField } from '@material-ui/core';
import AppFrame from '../../components/AppFrame';
import './DashBoard.scss';
import moment from 'moment';

const DEQMDashboardView: React.FC<RouteComponentProps<IDEQMDashboardRouteProps>> = (props) => {
  const [endDate, setEndDate] = React.useState(moment().format('YYYY-MM-DD'));
  const [startDate, setStartDate] = React.useState(moment().subtract(1, 'month').add(1, 'day').format('YYYY-MM-DD'));
  // const [subMenuItems, setSubMenuItems] = React.useState<ISubMenuItem[]>([]);

  const handleChange = (event: any) => {
    if (event !== null && event.target !== null) {
      if (event.target.id === 'startDate') {
        setStartDate(event.target.value);
      } else if (event.target.id === 'endDate') {
        setEndDate(event.target.value);
      }
    }
  };

  const convertDateToString = (dateToConvert) => {
    return moment(dateToConvert).format('YYYY-MM-DD')
  }

  return (
    <AppFrame layoutType="adminPortal" selectedMenuItem="ApproveAttestation">
      <h3>Data Exchange for Quality Measure Dashboard</h3>
      <div >
        <p>Reporting Period Date Range: </p>
        <TextField
          id="startDate"
          label="Start date"
          type="date"
          onChange={handleChange}
          defaultValue={convertDateToString(startDate)}
          InputLabelProps={{
            shrink: true,
          }}
        />

        <TextField
          id="endDate"
          label="End date"
          type="date"
          onChange={handleChange}
          defaultValue={convertDateToString(endDate)}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </div>
      <DEQMOverviewView endOfPeriod={endDate} startOfPeriod={startDate} />
    </AppFrame>
  );
};

export default DEQMDashboardView;
