import * as React from 'react';
import { Card, CardHeader, CardContent, ClickAwayListener, Tooltip } from '@material-ui/core';
import classes from 'classnames';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import css from './code-callout.module.scss';
import { clipboardCopy } from 'common/utils/clipboardCopy';

interface ICodeCalloutProps {
  code: string;
  includeCopy: boolean;
  hideBorder: boolean;
}

export const CodeCallout: React.FC<ICodeCalloutProps> = (props) => {
  const [openToolTop, setOpenToolTip] = React.useState(false);

  const handleTooltipClose = () => {
    setOpenToolTip(false);
  };

  const copyCode = () => {
    clipboardCopy(props.code).then(() => {
      setOpenToolTip(true);
    });
  };

  const copyAction = (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={openToolTop}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          arrow
          title="Copied!"
        >
          <FileCopyIcon onClick={copyCode} />
        </Tooltip>
      </div>
    </ClickAwayListener>
  );

  return (
    <Card className={classes(css.callout, { [css.hideBorder]: props.hideBorder })}>
      {props.includeCopy && <CardHeader className={css.calloutHeader} action={copyAction}></CardHeader>}
      <CardContent className={css.calloutContent}>
        <pre>{props.code}</pre>
      </CardContent>
    </Card>
  );
};
