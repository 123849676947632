import * as React from 'react';
import { Grid } from '@material-ui/core';
import { HighlightOff, CheckCircleOutline } from '@material-ui/icons';
import classes from 'classnames';
import moment from 'moment';
import { Button } from 'components/controls/Button';
import css from 'components/AppFrame/app-frame.module.scss';
import attestCSS from 'views/ApproveAttestation/approve-attestation.module.scss';

import { IAppPrivacyPolicyAttestation } from 'views/OAuthManagement/interfaces/IAppPrivacyPolicyAttestation';
import { IOAuthApp } from 'views/OAuthManagement/interfaces/IOAuthApp';
import { IUser } from 'common/interfaces/IUser';

import { getUserDisplayName } from 'common/utils/getUserDisplayName';

interface IPrivacyPolicyAttestationModalProps {
  attestation?: IAppPrivacyPolicyAttestation;
  application?: IOAuthApp;
  developer?: IUser;
  bindCancel: Function;
}

const ReviewPrivacyPolicyAttestation: React.FC<IPrivacyPolicyAttestationModalProps> = ({
  attestation,
  application,
  developer,
  bindCancel,
}) => {
  return (
    <>
      {attestation && application && developer && (
        <Grid container>
          <Grid item xs={12}>
            <h4 className={css.modalHeader}>Privacy Policy Attestation</h4>
          </Grid>
          <Grid item xs={3} className={attestCSS.attestDetails}>
            <div className={attestCSS.detailLabel}>DEVELOPED BY:</div>
            <div>{getUserDisplayName(developer)}</div>
            <div>
              <a href={`mailto:${developer.email}`}>{developer.email}</a>
            </div>
          </Grid>
          <Grid item xs={9} className={attestCSS.attestDetails}>
            {!!attestation.created_date && (
              <>
                <div className={attestCSS.detailLabel}>SUBMITTED ON:</div>
                <div>{moment(attestation.created_date).format('MMM DD, YYYY')}</div>
              </>
            )}
          </Grid>
          <Grid item xs={12}>
            <hr />
          </Grid>
          <Grid item xs={12}>
            <h4 className={attestCSS.checklistHeader}>Attestation Checklist</h4>
          </Grid>
          <Grid container>
            <Grid item xs={1} className={classes(attestCSS.attestRow, 'alignTextCenter')}>
              {attestation.attest_publicly_available ? (
                <CheckCircleOutline className={css.success} />
              ) : (
                <HighlightOff className={css.error} />
              )}
            </Grid>
            <Grid item xs={11} className={attestCSS.attestRow}>
              The App has a publicly available privacy policy, written in plain language, that has been affirmatively
              shared with the member prior to the member authorizing the App access to their health information. To
              "affirmatively share" means that the member must take an action to indicate s/he saw the privacy policy,
              such as click or check a box.
            </Grid>

            <Grid item xs={1} className={classes(attestCSS.attestRow, 'alignTextCenter')}>
              {attestation.attest_includes_minimum_information ? (
                <CheckCircleOutline className={css.success} />
              ) : (
                <HighlightOff className={css.error} />
              )}
            </Grid>
            <Grid item xs={11} className={attestCSS.attestRow}>
              <p>The App's privacy policy includes, at a minimum, the following important information:</p>
              <ul>
                <li>
                  How a member's health information may be accessed, exchanged, or used by any person or other entity,
                  including whether the member's health information may be shared or sold at any time (including in the
                  future);
                </li>
                <li>
                  A requirement for express consent from a member before the member's health information is accessed,
                  exchanged, orused, including receiving express consent before a member's health information is shared
                  or sold (other than disclosures required by law or disclosures necessary in connection with the sale
                  of the application or asimilar transaction);
                </li>
                <li>If an App will access any other information from a member's device; and</li>
                <li>
                  How a member can discontinue the App’s access to his/her data and what the App's policy and process is
                  for disposing of a member's data once the member has withdrawn consent.
                </li>
              </ul>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} className={classes('alignTextRight', css.modalActionButtons)}>
              <Button type="button" secondary onClick={bindCancel}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ReviewPrivacyPolicyAttestation;
