'use strict';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IMonitoringRouteProps } from './interfaces/IMonitoringRouteProps';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { monitoringySideMenuBuilder } from 'common/utils/monitoringSideMenuBuilder';
import { IUser } from 'common/interfaces/IUser';
import { TLayoutType } from 'components/AppFrame/interfaces/TLayoutType';
import AppFrame from 'components/AppFrame';

import {
  getFPLogsByPayer as getFPLogsByPayerAction,
} from 'views/Monitoring/redux/actions';

import axios from 'axios';
import { getCurrentAuthHeaders } from 'common/utils/getCurrentAuthHeaders';
import { API_URL } from 'settings';

import { DynamoScanRows, FPLog } from './interfaces/IDynamoDB';

import MUIDataTable from 'mui-datatables';
import { Button } from 'components/controls/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { MUIDataTableColumnDef, MUIDataTableData, FilterType, Responsive, MUIDataTableOptions } from 'mui-datatables';
import { ConsoleLogger } from '@aws-amplify/core';
// import { useCallback } from 'react-transition-group/node_modules/@types/react';


const Logs: React.FC<RouteComponentProps<IMonitoringRouteProps>> = (props) => {
  // type StoreType = 'react' | 'redux';
  const storeType: any = 'react';
  const dispatch = useDispatch();
  const history = useHistory();
  const currentUser = useSelector((state) => state.auth.user) as IUser;
  const dynamoScanRows: DynamoScanRows = { Items: [{uuid: {S: 'string'}, timestamp: {S: 'string'}}], Rows: [['test'] ], Count: 0, ScannedCount: 0, ResponseMetadata: {}};
  const orgId = currentUser.organization_id;
  const flaskApiLogUrl = `${API_URL}/monit/${orgId}/fplogs`;

  // if (orgId && storeType === 'redux') {
    // const dynamoLogScan = useSelector((state) => state.monitor.logs) as DynamoScanRows;
    // const dynamoLogScanRows = dynamoLogScan.Rows as DataGridLogRow[];
    // const dataGridLogRowInit: DataGridLogRow = //used for mui.com DataGrid
    // [{ id: 0, col1: 'test', col2: 'test', col3: 'test', col4: 'test' } ], Count: 0, ScannedCount: 0, ResponseMetadata: {}};
    // React.useEffect(() => {
    //     dispatch(getFPLogsByPayerAction(orgId));
    // }, [dispatch]);
  // }
  
  const initHeaders = {};
  const [headers, setHeaders] = React.useState(initHeaders);
  const [headerError, setHeaderError] = React.useState(null as unknown);
  const [loadingHeaders, setLoadingHeaders] = React.useState(true);
  async function getHeaders(){
    try{
      setLoadingHeaders(true);
      const getFetchHeaders = await getCurrentAuthHeaders('json') as Headers;
      const headersObj = { 'Content-Type': 'application/json', 'Authorization': getFetchHeaders.get('Authorization')};
      setHeaders(headersObj);
    } catch (e) {
      setHeaderError(e);
    } finally {
      setLoadingHeaders(false);
    }
  }
  React.useEffect(() => {
    getHeaders();
  }, []);

  const [dynamoLogs, setDynamoLogs] = React.useState(dynamoScanRows);
  const [dynamoError, setDynamoError] = React.useState(null as any);
  const [dynamoLoading, setDynamoLoading] = React.useState(true);
  async function getDynamo(){
    try{
      setDynamoLogs(dynamoScanRows);
      setDynamoLoading(true);
      setDynamoError(null);
      const dynResp = await getDyn(flaskApiLogUrl);
      // setDynamoLogs(dynResp);
    } 
    catch (e) { setDynamoError(e) } 
    finally {
      setDynamoLoading(false);
    }
  }

  const [dataTableRows, setDataTableRows] = React.useState([['Loading']]);

  React.useEffect(() => {
    if (dynamoLogs.Count > 0) {
      const muiRow: any[] = [];
      for (let i = 0; i < dynamoLogs.Items.length; i++) {
        const item = dynamoLogs.Items[i];
        muiRow.push( 
          [
            item.timestamp,
            item.processing_status || '',
            item.s3_holding_bucket_name || '',
            item.uuid, 
            item.s3_key || '',
            item.file_arrival_timestamp || '',
            item.notes?.L?.[0].M?.note || '',
          ] 
        )
      }
      setDataTableRows(muiRow);
    }
    else {
      (typeof dynamoLogs.response === 'string' || dynamoLogs.response instanceof String)
        ? setDynamoError(dynamoLogs.response)
        : dynamoLogs.response?.message
        ? setDynamoError(dynamoLogs.response.message)
        : console.dir(dynamoLogs)
    }
  }, [dynamoLogs]);

  function getDyn(flaskApiLogUrl) {
    axios.get(flaskApiLogUrl, {headers: headers} )
    .then(response => {
      setDynamoLogs(response.data);
    return response.data;
    })
    .catch(error => {
      if (error.message) {
        setDynamoError(error);
      }
    })
    .then(() => {
      // console.dir(response)
    })
  } 

  const layoutType: TLayoutType =
    currentUser?.user_type === 'developer'
      ? 'developerPortal'
      : currentUser?.user_type === 'payer'
      ? 'payerPortal'
      : 'adminPortal';

  const sideMenuItems = monitoringySideMenuBuilder({ active: 'logs', history });
  
  const [filterArrayFullMatch, setFilterArrayFullMatch] = React.useState(true);
  const columns: MUIDataTableColumnDef[] = [
    { name: 'Timestamp', options: {filter: false}},
    { name: 'Status', options: {filter: true}},
    { name: 'Bucket', options: {filter: true}},
    { name: 'UUID', options: {filter: false}},
    { name: 'S3 Key', options: {filter: false}},
    { name: 'Arrival', options: {filter: false}},
    { name: 'Note', options: {filter: true}},
    // {
    //   name: 'Tags',
    //   options: {
    //     filter: true,
    //     filterType: 'multiselect',
    //     customBodyRenderLite: (dataIndex) => {
    //       let value = data[dataIndex][5];
    //       return value.map((val, key) => {
    //         return <Chip label={val} key={key} />;
    //       });
    //     },
    //   }
    // },
  ];

  const filter: FilterType = 'dropdown';
  const muioptions: MUIDataTableOptions = {
    filter: true,
    filterArrayFullMatch: filterArrayFullMatch,
    filterType: filter,
    responsive: 'standard',
  };
  // const dataSource = React.useCallback(loadData, []);

  return (
    <AppFrame layoutType={layoutType} selectedMenuItem="Monitoring" subMenuItems={sideMenuItems}>
      <Grid container>
        <Grid item xs={6}>
          <h2 className="section-title">Log Viewer</h2>
        </Grid>
        {storeType === 'react' && (
          <Grid item xs={6} className="alignTextCenter">
            <Button onClick={() => getDynamo()}>Retrieve Logs</Button>
          </Grid>
        )}
        { storeType === 'redux' && (
          <Grid item xs={6} className="alignTextRight">
            <Button onClick={() =>  dispatch(getFPLogsByPayerAction(orgId))}> Redux </Button>
          </Grid>
        )}
        <Grid item xs={6}>
          {dynamoError
            ? ("Failed to retrieve logs. " + dynamoError.message)
            : dynamoLoading
            ? "0 Logs Loaded"
            : dataTableRows?.[0]?.[0] == "Loading"
            ? "Loading"
            : dataTableRows.length + " Logs Retrieved"}
        </Grid>
        {dataTableRows?.[0]?.[0]?.length > 7 && (
          <Grid item xs={6}>
          <FormControlLabel
            control={
              <Switch
                checked={filterArrayFullMatch}
                onChange={e => setFilterArrayFullMatch(e.target.checked)}
                value="filterArray"
                color="primary"
              />
            }
            label="Fullmatch for Array filter"
          />
          </Grid>
        )}
      </Grid>
        {dataTableRows.length > 1 && (
          <Grid container>
            <MUIDataTable title={"File Processing Logs"} data={dataTableRows} columns={columns} options={muioptions} />
          </Grid>
        )}
    </AppFrame>
  );
};

export default Logs;
// function as<T>(arg0: () => void, as: any, DynamoScanRows: any): [any, any] {
//   throw new Error('Function not implemented.');
// }

