import { Auth } from 'aws-amplify';
import { CognitoUserSession } from 'amazon-cognito-identity-js';

export const getCurrentJwt = async () => {
  const getAuth = async (): Promise<CognitoUserSession> => {
    try {
      const currentUserSession = await Auth.currentSession();
      return currentUserSession;
    } catch (err) {
      // console.log('ERROR', err);
      Auth.signOut();
      throw err;
    }
  };

  const cognitoUserSession: CognitoUserSession = await getAuth();
  if (cognitoUserSession) {
    return cognitoUserSession.getIdToken().getJwtToken();
  } else {
    return null;
  }
};
