// Lifted from: https://github.com/feross/clipboard-copy and made TS compliant

export const clipboardCopy = (text: string | undefined) => {
  // Use the Async Clipboard API when available. Requires a secure browsing
  // context (i.e. HTTPS)
  if (navigator.clipboard) {
    return navigator.clipboard.writeText(text ? text : '').catch(function (err) {
      throw err !== undefined ? err : new DOMException('The request is not allowed', 'NotAllowedError');
    });
  }

  // ...Otherwise, use document.execCommand() fallback

  // Put the text to copy into a <span>
  const span = document.createElement('span');
  span.textContent = text ? text : '';

  // Preserve consecutive spaces and newlines
  span.style.whiteSpace = 'pre';

  // Add the <span> to the page
  document.body.appendChild(span);

  let success = false;

  // Make a selection object representing the range of text selected by the user
  const selection = window.getSelection();
  if (selection !== null) {
    const range = window.document.createRange();
    selection.removeAllRanges();
    range.selectNode(span);
    selection.addRange(range);

    // Copy text to the clipboard
    try {
      success = window.document.execCommand('copy');
    } catch (err) {
      console.log('error', err);
    }

    // Cleanup
    selection.removeAllRanges();
    window.document.body.removeChild(span);
  }

  return success
    ? Promise.resolve()
    : Promise.reject(new DOMException('The request is not allowed', 'NotAllowedError'));
};
