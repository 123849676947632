import * as React from 'react';
import { Chip } from '@material-ui/core';
import { Edit, RadioButtonUnchecked, CheckCircleOutline, Warning } from '@material-ui/icons';
import classes from 'classnames';
import css from './mui-chip.module.scss';

interface IChipProps {
  type?:
    | 'draft'
    | 'incomplete'
    | 'pending'
    | 'approved'
    | 'complete'
    | 'issue'
    | 'rejected'
    | 'admin'
    | 'payer'
    | 'developer'
    | 'dev'
    | 'prod';
  customLabel?: string;
  className?: string;
}

export const MUIChip: React.FC<IChipProps> = (props) => {
  const { type, customLabel, className } = props;

  let icon: any = null;
  let label = '';
  switch (type) {
    case 'draft':
      label = customLabel !== undefined && customLabel.length > 0 ? customLabel : 'Draft';
      icon = <Edit />;
      break;
    case 'incomplete':
      label = customLabel !== undefined && customLabel.length > 0 ? customLabel : 'Open';
      icon = <RadioButtonUnchecked />;
      break;
    case 'pending':
      label = customLabel !== undefined && customLabel.length > 0 ? customLabel : 'Pending';
      icon = <RadioButtonUnchecked />;
      break;
    case 'complete':
    case 'approved':
      label = customLabel !== undefined && customLabel.length > 0 ? customLabel : 'Approved';
      icon = <CheckCircleOutline />;
      break;
    case 'rejected':
    case 'issue':
      label = customLabel !== undefined && customLabel.length > 0 ? customLabel : 'Issue';
      icon = <Warning />;
      break;
    case 'admin':
      label = customLabel !== undefined && customLabel.length > 0 ? customLabel : 'Admin';
      break;
    case 'payer':
      label = customLabel !== undefined && customLabel.length > 0 ? customLabel : 'Payer';
      break;
    case 'developer':
      label = customLabel !== undefined && customLabel.length > 0 ? customLabel : 'Developer';
      break;
    case 'dev':
      label = customLabel !== undefined && customLabel.length > 0 ? customLabel : 'Dev';
      break;
    case 'prod':
      label = customLabel !== undefined && customLabel.length > 0 ? customLabel : 'Prod';
      break;
    default:
      label = customLabel !== undefined && customLabel.length > 0 ? customLabel : '';
  }

  return (
    <Chip
      icon={icon}
      className={classes(
        css.chip,
        { [css.draft]: type === 'draft' || type === 'incomplete' },
        { [css.pending]: type === 'pending' },
        { [css.approved]: type === 'approved' || type === 'complete' },
        { [css.issue]: type === 'issue' || type === 'rejected' },
        { [css.admin]: type === 'admin' },
        { [css.payer]: type === 'payer' },
        { [css.developer]: type === 'developer' },
        { [css.dev]: type === 'dev' },
        { [css.prod]: type === 'prod' },
        className,
      )}
      label={label}
    />
  );
};
