import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Formik, ErrorMessage } from 'formik';
import { Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Button } from 'components/controls/Button';
import { IOAuthModalProps } from 'views/OAuthManagement/interfaces/IOAuthModalProps';
import { IOAuthApp } from 'views/OAuthManagement/interfaces/IOAuthApp';
import { IOAuthAppCredential } from 'views/OAuthManagement/interfaces/IOAuthAppCredential';
import { IAuthClient, client_type_map, grant_type_map } from 'views/OAuthManagement/interfaces/IAuthClient';
import { trimAllStringProperties } from 'common/utils/trimAllStringProperties';
import { registerToProdValidationSchema } from 'views/OAuthManagement/schemas/registerToProdValidationSchema';
import {
  registerAppToProd as registerAppToProdAction,
  getOAuthAppCredentials as getOAuthAppCredentialsAction,
  getAuthClients as getAuthClientsAction,
  getOAuthAppCredential as getOAuthAppCredentialAction,
} from 'views/OAuthManagement/redux/actions';
import classes from 'classnames';
import css from 'components/AppFrame/app-frame.module.scss';

interface IRegisterToProductionValues {
  name: string;
  redirectURIs: string;
}

const RegisterToProduction: React.FC<IOAuthModalProps> = ({ bindComplete }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentCredential = useSelector((state) => state.oauthMngmt.currentCredential) as IOAuthAppCredential;
  const currentApp = useSelector((state) => state.oauthMngmt.currentApp) as IOAuthApp;
  const currentClient = useSelector((state) => state.oauthMngmt.currentClient) as IAuthClient;

  const initialValues: IRegisterToProductionValues = { name: currentClient.name || '', redirectURIs: '' };
  const [isSending, setIsSending] = React.useState(false);
  const [sendError, setSendError] = React.useState('');

  const handleSubmit = (values: IRegisterToProductionValues, setSubmitting: any) => {
    setIsSending(true);
    const trimmedValues = trimAllStringProperties(values);

    const defaultRedirectURI =
      trimmedValues.redirectURIs.indexOf(',') === -1
        ? trimmedValues.redirectURIs
        : trimmedValues.redirectURIs.split(',')[0];

    const clientApp: IAuthClient = {
      name: trimmedValues.name,
      client_type: currentClient.client_type,
      grant_type: currentClient.grant_type,
      redirect_uris: trimmedValues.redirectURIs,
      default_redirect_uri: defaultRedirectURI,
      scope: currentClient.scope,
      client_category: 'production',
      organization_id: currentClient.organization_id,
    };

    dispatch(registerAppToProdAction({ authApp: currentApp, authClient: clientApp })).then((response) => {
      if (response.error) {
        setIsSending(false);
        setSendError(response.error.message);
      } else {
        setIsSending(false);
        const newAppCredId = response.payload;

        dispatch(getOAuthAppCredentialAction(newAppCredId));
        dispatch(getOAuthAppCredentialsAction());
        dispatch(getAuthClientsAction());

        bindComplete();
        history.push(`/applications/${newAppCredId}/edit`);
      }
    });

    setSubmitting(false);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        {currentCredential && currentClient && currentApp && (
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={(values, { setSubmitting }) => handleSubmit(values, setSubmitting)}
            validationSchema={registerToProdValidationSchema}
          >
            {({ values, handleChange, handleBlur, handleSubmit, isSubmitting }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Grid container>
                    {!!sendError && (
                      <Grid item xs={12}>
                        <Alert severity="error">{sendError}</Alert>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <h4 className={css.modalHeader}>Register Your Application in Production</h4>
                    </Grid>
                    <Grid item xs={12} className={css.formRow}>
                      <label htmlFor="name">Application Name</label>
                      <input name="name" type="text" value={values.name} onChange={handleChange} onBlur={handleBlur} />
                      <ErrorMessage name="name">{(msg) => <div className="input-feedback">{msg}</div>}</ErrorMessage>
                    </Grid>
                    <Grid container className={css.formRow}>
                      <Grid item xs={4}>
                        <label htmlFor="clientType">Client Type</label>
                        <div className={css.subtitle}>{client_type_map[currentClient.client_type || 0]}</div>
                      </Grid>
                      <Grid item xs={4}>
                        <label htmlFor="grantType">Grant Type</label>
                        <div className={css.subtitle}>{grant_type_map[currentClient.grant_type || 0]}</div>
                      </Grid>
                      <Grid item xs={4}>
                        <label htmlFor="grantType">Application Scope(s)</label>
                        <div className={css.subtitle}>{currentClient.scope}</div>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} className={css.formRow}>
                      <small style={{ float: 'right', color: 'GrayText' }}>Separate each entry with a comma.</small>
                      <label htmlFor="redirectURIs">Callback URLs / Redirect URIs</label>
                      <textarea
                        name="redirectURIs"
                        style={{ height: '17rem' }}
                        // className={formCSS.redirectURIs}
                        value={values.redirectURIs}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <ErrorMessage name="redirectURIs">
                        {(msg) => <div className="input-feedback">{msg}</div>}
                      </ErrorMessage>
                    </Grid>
                    <Grid item xs={12} className={classes('alignTextRight', css.modalActionButtons)}>
                      <Button type="button" secondary onClick={bindComplete} disabled={isSending}>
                        Cancel
                      </Button>
                      <Button type="submit" disabled={isSending}>
                        {isSending ? <span>Registering...</span> : <span>Register App</span>}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              );
            }}
          </Formik>
        )}
      </Grid>
    </Grid>
  );
};

export default RegisterToProduction;
