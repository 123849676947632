import { ISubMenuItem } from 'components/controls/SideMenu/interfaces/ISubMenuItem';

interface IDevLibrarySideMenuBuilderParams {
  active: string;
  history: any;
}

export const devLibrarySideMenuBuilder = (params: IDevLibrarySideMenuBuilderParams): ISubMenuItem[] => {
  return [
    {
      label: `InterOP Station® Overview`,
      active: false,
      action: null,
    },
    {
      label: `Overview`,
      active: params.active === 'overview',
      action: () => params.history.push('/document-library/'),
    },
    {
      label: `FHIR® API`,
      active: params.active === 'fhir',
      action: () => params.history.push('/document-library/fhir'),
    },
    {
      label: `CDA as Compared to FHIR®`,
      active: params.active === 'cdavfhir',
      action: () => params.history.push('/document-library/cda-compared-to-fhir'),
    },
    {
      label: `InterOp Station® API Endpoints`,
      active: params.active === 'endpoints',
      action: () => params.history.push('/document-library/endpoints'),
    },
    {
      label: `IOS User Guide`,
      active: params.active === 'user-guide',
      action: () => window.open("https://mihin.org/interop-station", "_blank"),
    },
  ];
};
