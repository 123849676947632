export const RECOVERY_QUESTIONS = [
  { value: 'What is the food you least liked as a child?', label: 'What is the food you least liked as a child?' },
  { value: 'What is the name of your first stuffed animal?', label: 'What is the name of your first stuffed animal?' },
  {
    value: 'What did you earn your first medal or award for?',
    label: 'What did you earn your first medal or award for?',
  },
  { value: 'What is your favorite security question?', label: 'What is your favorite security question?' },
  {
    value: 'What is the toy/stuffed animal you liked the most as a kid?',
    label: 'What is the toy/stuffed animal you liked the most as a kid?',
  },
  { value: 'What was the first computer game you played?', label: 'What was the first computer game you played?' },
  { value: 'What is your favorite movie quote?', label: 'What is your favorite movie quote?' },
  {
    value: 'What was the mascot of the first sports team you played on?',
    label: 'What was the mascot of the first sports team you played on?',
  },
  {
    value: 'What music album or song did you first purchase?',
    label: 'What music album or song did you first purchase?',
  },
  { value: 'What is your favorite piece of art?', label: 'What is your favorite piece of art?' },
  { value: "What was your grandmother's favorite dessert?", label: "What was your grandmother's favorite dessert?" },
  { value: 'What was the first thing you learned to cook?', label: 'What was the first thing you learned to cook?' },
  { value: 'What was your dream job as a child?', label: 'What was your dream job as a child?' },
  {
    value: 'Where did you meet your spouse/significant other?',
    label: 'Where did you meet your spouse/significant other?',
  },
  { value: 'Where did you go for your favorite vacation?', label: 'Where did you go for your favorite vacation?' },
  {
    value: "Where were you on New Year's Eve in the year 2000?",
    label: "Where were you on New Year's Eve in the year 2000?",
  },
  { value: 'Who is your favorite speaker/orator?', label: 'Who is your favorite speaker/orator?' },
  { value: 'Who is your favorite book/movie character?', label: 'Who is your favorite book/movie character?' },
  { value: 'Who is your favorite sports player?', label: 'Who is your favorite sports player?' },
];
