import * as yup from 'yup';
export const passwordSchema = () =>
  yup
    .string()
    .min(10, 'Must be at least ten (10) characters long')
    .matches(/\d/, 'Must contain at least one (1) number')
    .matches(/[a-z]/, 'Must contain at least one (1) lowercase letter')
    .matches(/[A-Z]/, 'Must contain at least one (1) uppercase letter')
    .matches(/\W/, 'Must contain at least one (1) special character')
    .required('Password required');
