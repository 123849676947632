import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import OAuthFrame from 'views/OAuthManagement/components/OAuthFrame';
import { Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { IOAuthApp } from 'views/OAuthManagement/interfaces/IOAuthApp';
import { IOAuthAppCredential } from 'views/OAuthManagement/interfaces/IOAuthAppCredential';
import { IAuthClient, credential_type_map } from 'views/OAuthManagement/interfaces/IAuthClient';
import { IAppPrivacyPolicyAttestation } from 'views/OAuthManagement/interfaces/IAppPrivacyPolicyAttestation';
import { IOAuthAppDetailRouteProps } from 'views/OAuthManagement/interfaces/IOAuthAppDetailRouteProps';
import { Delete } from '@material-ui/icons';
import { Button } from 'components/controls/Button';
import {
  getOAuthApp as getOAuthAppAction,
  getOAuthAppCredential as getOAuthAppCredentialAction,
  getAuthClient as getAuthClientAction,
  deleteOAuthAppCredential as deleteOAuthAppCredentialAction,
  deleteAuthClient as deleteAuthClientAction,
  getOAuthAppPrivacyPolicyAttestation as getOAuthAppPrivacyPolicyAttestationAction,
} from 'views/OAuthManagement/redux/actions';
import { getAttestations as getAttestationsAction } from 'views/DeveloperAttestation/redux/actions';
import css from './oauth-app-detail.module.scss';
import classes from 'classnames';
import { MUIModal } from 'components/controls/MUI/MUIModal';
import RegisterToProduction from 'views/OAuthManagement/components/RegisterToProduction';
import DeleteAppConfirm from 'views/OAuthManagement/components/DeleteAppConfirm';
import AppBaseForm from 'views/OAuthManagement/components/AppBaseForm';
import AppDetailsForm from 'views/OAuthManagement/components/AppDetailsForm';
import AppCredentials from 'views/OAuthManagement/components/AppCredentials';
import AppPrivacyPolicy from 'views/OAuthManagement/components/AppPrivacyPolicy';
import AppInfoForm from 'views/OAuthManagement/components/AppInfoForm';
import AppSecurityAttestation from 'views/OAuthManagement/components/AppSecurityAttestation';

const OAuthAppDetail: React.FC<RouteComponentProps<IOAuthAppDetailRouteProps>> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const appCredId = props.match.params.id;

  const currentCredential = useSelector((state) => state.oauthMngmt.currentCredential) as IOAuthAppCredential;
  const currentApp = useSelector((state) => state.oauthMngmt.currentApp) as IOAuthApp;
  const currentClient = useSelector((state) => state.oauthMngmt.currentClient) as IAuthClient;
  const currentAttestation = useSelector((state) =>
    _.find(state.developerAttestations?.attestations, (attestation) => attestation.app_id === currentApp.id),
  );
  const currentPrivacyPolicyAttestation = useSelector(
    (state) => state.oauthMngmt.currentPrivacyPolicyAttestation,
  ) as IAppPrivacyPolicyAttestation;

  const [regToProdModal, setRegToProdModal] = React.useState(false);
  const [deleteConfirmModal, setDeleteConfirmModal] = React.useState(false);
  const [sendError, setSendError] = React.useState('');

  React.useEffect(() => {
    dispatch(getOAuthAppCredentialAction(appCredId));
    dispatch(getAttestationsAction());
  }, [dispatch]);

  React.useEffect(() => {
    if (currentCredential.application_id > 0 && currentCredential.auth_client_id > 0) {
      dispatch(getOAuthAppAction(currentCredential.application_id));
      dispatch(getAuthClientAction(currentCredential.auth_client_id));
      dispatch(getOAuthAppPrivacyPolicyAttestationAction(currentCredential.application_id));
    }
  }, [currentCredential]);

  const toggleDeleteConfirmation = () => {
    setDeleteConfirmModal(!deleteConfirmModal);
  };
  const handleDeleteClick = () => {
    dispatch(deleteOAuthAppCredentialAction(currentCredential)).then((response) => {
      if (response.error) {
        setSendError(response.error.message);
      } else {
        dispatch(deleteAuthClientAction(currentClient)).then(() => {
          history.push(
            `/applications/${currentCredential.credential_type === credential_type_map['DEV'] ? 'dev' : 'prod'}`,
          );
        });
      }
    });
  };

  const toggleRegToProd = () => {
    setRegToProdModal(!regToProdModal);
  };
  const handleRegToProdComplete = () => {
    toggleRegToProd();
  };

  return (
    <OAuthFrame {...props}>
      {currentCredential && currentClient && currentApp && (
        <Grid container>
          {!!sendError && (
            <Grid item xs={12}>
              <Alert severity="error">{sendError}</Alert>
            </Grid>
          )}
          <Grid item xs={6} className={css.actionHeader}>
            <Button tertiary className={css.deleteButton} onClick={toggleDeleteConfirmation}>
              <Delete /> Delete Application
            </Button>
          </Grid>
          <Grid item xs={6} className={classes('alignTextRight', css.actionHeader)}>
            {currentCredential.credential_type === credential_type_map['DEV'] && (
              <Button
                onClick={toggleRegToProd}
                disabled={
                  !currentApp.privacy_policy_url ||
                  currentAttestation?.status !== 'complete' ||
                  !currentPrivacyPolicyAttestation
                }
              >
                Register in Production
              </Button>
            )}
          </Grid>
          <Grid item xs={12}>
            <AppBaseForm currentApp={currentApp} currentClient={currentClient} currentCredential={currentCredential} />
          </Grid>
          <Grid container className={classes(css.mainDetailsContainer, css.topDetails)}>
            <AppCredentials currentClient={currentClient} currentCredential={currentCredential} />
            <AppPrivacyPolicy currentApp={currentApp} currentCredential={currentCredential} />
            <AppSecurityAttestation currentApp={currentApp} currentAttestation={currentAttestation} />
          </Grid>
          <Grid container className={classes(css.mainDetailsContainer, css.bottomDetails)}>
            <AppDetailsForm
              currentApp={currentApp}
              currentClient={currentClient}
              currentCredential={currentCredential}
            />
            <AppInfoForm currentApp={currentApp} currentCredential={currentCredential} />
          </Grid>
        </Grid>
      )}
      <MUIModal width="lg" allowClose open={regToProdModal} handleClose={toggleRegToProd}>
        <RegisterToProduction bindComplete={handleRegToProdComplete} bindCancel={handleRegToProdComplete} />
      </MUIModal>
      <MUIModal width="md" allowClose open={deleteConfirmModal} handleClose={toggleDeleteConfirmation}>
        <DeleteAppConfirm bindComplete={handleDeleteClick} bindCancel={toggleDeleteConfirmation} />
      </MUIModal>
    </OAuthFrame>
  );
};

export default OAuthAppDetail;
