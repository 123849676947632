import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_URL } from 'settings';
import { getCurrentAuthHeaders } from 'common/utils/getCurrentAuthHeaders';
import { IOrganization } from 'common/interfaces/IOrganization';

export const getOrgs = createAsyncThunk('OrgMgmt/orgs', async () => {
  const headers = await getCurrentAuthHeaders('json');
  const response = await fetch(`${API_URL}/org`, { method: 'GET', headers });
  return (await response.json()) as IOrganization[];
});

export const createOrg = createAsyncThunk<void, IOrganization>('OrgMgmt/new-org', async (org) => {
  try {
    const headers = await getCurrentAuthHeaders('json');
    const request = {
      method: 'POST',
      headers,
      body: JSON.stringify(org),
    };
    const response = await fetch(`${API_URL}/org/create`, request);

    if (response.status === 200) {
      const orgId = response.json();
      return
    } else {
      const responseJSON = await response.json();
      throw (responseJSON.message || 'Unknown Error')
    }
  } catch (error) {
    throw new Error(error);
  }
});
