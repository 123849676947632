import * as React from 'react';
import { Grid } from '@material-ui/core';
import { IAppFrameProps } from './interfaces/IAppFrameProps';
import { SideMenu } from 'components/controls/SideMenu';
import classes from 'classnames';
import css from './app-frame.module.scss';
import logo from 'assets/interoperability-station-logo.svg';
import { TopMenu } from 'components/controls/TopMenu';

const AppFrame: React.FC<IAppFrameProps> = (props) => {
  const { layoutType, selectedMenuItem, subMenuItems, hideLogo } = props;

  let isPortalView = true;
  let wrapperCSS = '';
  switch (layoutType) {
    case 'adminPortal':
      wrapperCSS = css.appAdminPortal;
      break;
    case 'payerPortal':
      wrapperCSS = css.appPayerPortal;
      break;
    case 'developerPortal':
      wrapperCSS = css.appDevPortal;
      break;
    default:
      wrapperCSS = css.appAuth;
      isPortalView = false;
      break;
  }

  return (
    <div className={wrapperCSS}>
      <Grid container className={css.header}>
        {isPortalView && <TopMenu />}
      </Grid>
      <Grid
        container
        className={classes(
          css.mainContainer,
          { [css.fullFrame]: layoutType === 'authFullFrame' },
          { [css.leftRight]: layoutType === 'authLeftRight' },
          { [css.centerFrame]: layoutType === 'authCenterFrame' },
          { [css.centerFrame]: layoutType === 'registrationFrame' },
          { [css.centerFrame]: layoutType === 'completeSignupFrame' },
        )}
      >
        <Grid item className={css.secondaryContent}>
          {isPortalView && (
            <SideMenu presetSelectedMenuItem={selectedMenuItem} presetSelectedMenuSubItems={subMenuItems} />
          )}
        </Grid>
        <Grid
          item
          className={classes(
            css.primaryContent,
            { [css.registrationFrame]: layoutType === 'registrationFrame' },
            { [css.completeSignUpFrame]: layoutType === 'completeSignupFrame' },
          )}
        >
          {hideLogo !== true && <img src={logo} alt="InterOp Station" className={css.logoInternal} />}
          <div className={css.contentSection}>{props.children}</div>
        </Grid>
      </Grid>
    </div>
  );
};

export default AppFrame;
