import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, ClickAwayListener, Tooltip } from '@material-ui/core';
import { FileCopy, Delete, InfoOutlined } from '@material-ui/icons';
import classes from 'classnames';
import { credential_type_map } from 'views/OAuthManagement/interfaces/IAuthClient';
import { MUIChip } from 'components/controls/MUI/MUIChip';
import css from 'views/Dashboard/dashboard.module.scss';
import { IUser } from 'common/interfaces/IUser';
import { IListDataItem } from 'views/OAuthManagement/interfaces/IListDataItem';
import { clipboardCopy } from 'common/utils/clipboardCopy';
import { Tile } from 'components/controls/Tile';
import { MUIModal } from 'components/controls/MUI/MUIModal';
import { Button } from 'components/controls/Button';
import DeleteAppConfirm from 'views/OAuthManagement/components/DeleteAppConfirm';
import AppInfo from 'views/Dashboard/components/AppInfo';
import {
  getOAuthApps as getOAuthAppsAction,
  getOAuthAppCredentials as getOAuthAppCredentialsAction,
  getAuthClients as getAuthClientsAction,
  deleteOAuthAppCredential as deleteOAuthAppCredentialAction,
  deleteAuthClient as deleteAuthClientAction,
} from 'views/OAuthManagement/redux/actions';

interface IAppTileProps {
  item: IListDataItem;
}

const AppTile: React.FC<IAppTileProps> = ({ item }) => {
  const currentUser = useSelector((state) => state.auth.user) as IUser;
  const dispatch = useDispatch();
  const [openIDToolTop, setOpenIDToolTip] = React.useState(false);
  const [openSecretToolTop, setOpenSecretToolTip] = React.useState(false);
  const [deleteConfirmModal, setDeleteConfirmModal] = React.useState(false);
  const [appInfoModal, setAppInfoModal] = React.useState(false);

  const handleIDTooltipClose = () => {
    setOpenIDToolTip(false);
  };
  const handleSecretTooltipClose = () => {
    setOpenSecretToolTip(false);
  };
  const handleDeleteClick = () => {
    dispatch(deleteOAuthAppCredentialAction(item.credential)).then(() => {
      dispatch(deleteAuthClientAction(item.client)).then(() => {
        dispatch(getOAuthAppCredentialsAction()).then(() => {
          dispatch(getOAuthAppsAction());
          dispatch(getAuthClientsAction());
          toggleDeleteConfirmation();
        });
      });
    });
  };
  const toggleDeleteConfirmation = () => {
    setDeleteConfirmModal(!deleteConfirmModal);
  };
  const toggleAppInfo = () => {
    setAppInfoModal(!appInfoModal);
  };

  const getAppAvatarChip = () => {
    if (item.credential.credential_type === credential_type_map.DEV) {
      return <MUIChip type="dev" />;
    } else if (item.credential.credential_type === credential_type_map.PROD) {
      return <MUIChip type="prod" />;
    }

    return null;
  };
  const copyID = () => {
    clipboardCopy(item.client.client_id).then(() => {
      setOpenIDToolTip(true);
    });
  };
  const copySecret = () => {
    clipboardCopy(item.client.client_secret).then(() => {
      setOpenSecretToolTip(true);
    });
  };

  const copyIDAction = (
    <ClickAwayListener onClickAway={handleIDTooltipClose}>
      <div className={css.copyButton}>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleIDTooltipClose}
          open={openIDToolTop}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          arrow
          title="Copied!"
        >
          <FileCopy onClick={copyID} />
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
  const copySecretAction = (
    <ClickAwayListener onClickAway={handleSecretTooltipClose}>
      <div className={css.copyButton}>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleSecretTooltipClose}
          open={openSecretToolTop}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          arrow
          title="Copied!"
        >
          <FileCopy onClick={copySecret} />
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
  const actionsButton = () => {
    if (currentUser.user_type === 'developer') {
      return (
        <Button className={css.tileActionButton} tertiary onClick={toggleDeleteConfirmation}>
          <Delete /> Delete App
        </Button>
      );
    } else if (currentUser.user_type === 'mihin-admin') {
      return (
        <Button className={css.tileActionButton} tertiary onClick={toggleAppInfo}>
          <InfoOutlined /> View App
        </Button>
      );
    } else {
      return null;
    }
  };

  const bottomContent = () => {
    if (currentUser.user_type === 'developer') {
      return (
        <>
          <Grid item xs={12}>
            <div className={css.clientToken}>
              <span>Client ID:</span>
              <input type="text" value={item.client?.client_id} disabled />
              {copyIDAction}
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={css.clientToken}>
              <span>Client Secret:</span>
              <input type="text" value={item.client?.client_secret} disabled />
              {copySecretAction}
            </div>
          </Grid>
        </>
      );
    } else if (currentUser.user_type === 'payer') {
      return (
        <Button tertiary onClick={toggleAppInfo}>
          <InfoOutlined /> View Info
        </Button>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <Tile
        className={classes(css.tile, css.appTile)}
        title={item.client?.name}
        description={item.app?.app_descr}
        avatarChip={getAppAvatarChip()}
        actions={actionsButton()}
        bottomContentClassName={css.tileMetaInfoRegion}
        bottomContent={
          <Grid container className={css.tileMetaInfoBody}>
            {bottomContent()}
          </Grid>
        }
      />

      <MUIModal width="md" allowClose open={deleteConfirmModal} handleClose={toggleDeleteConfirmation}>
        <DeleteAppConfirm bindComplete={handleDeleteClick} bindCancel={toggleDeleteConfirmation} />
      </MUIModal>
      <MUIModal width="md" allowClose open={appInfoModal} handleClose={toggleAppInfo}>
        <AppInfo item={item} bindClose={toggleAppInfo} />
      </MUIModal>
    </>
  );
};

export default AppTile;
