import * as React from 'react';
import Select from 'react-select';
import _ from 'lodash';
import { OAuthScopes } from 'common/types/OAuthScopes';

interface IOAuthScopeSelectorProps {
  className?: string;
  defaultValue?: string;
  placeholder?: string;
  onChange: any;
}

export const OAuthScopeSelector: React.FC<IOAuthScopeSelectorProps> = ({
  onChange,
  className,
  defaultValue,
  placeholder,
}) => {
  const oauthScopeOptions = OAuthScopes.map((scope) => {
    return {
      value: scope,
      label: scope,
    };
  });
  const formatScopesForProperty = (selectedValues) => {
    const optionsValue: string[] = [];
    _.each(selectedValues, (selectedValue) => {
      optionsValue.push(selectedValue.value);
    });
    return optionsValue.join(' ');
  };
  const formatScopesForControl = (optionsValue: string) => {
    if (optionsValue.length > 0) {
      const options = optionsValue.split(' ');
      return options.map((option) => {
        return {
          value: option,
          label: option,
        };
      });
    }
  };
  const handleScopeChange = (selectedValue) => {
    if (selectedValue) {
      onChange(formatScopesForProperty(selectedValue));
    } else {
      onChange('');
    }
  };

  return (
    <Select
      classNamePrefix="react-select"
      className={className}
      placeholder={placeholder}
      isMulti
      options={oauthScopeOptions}
      defaultValue={formatScopesForControl(defaultValue || '')}
      onChange={handleScopeChange}
    />
  );
};
