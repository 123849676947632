import { combineReducers } from 'redux';
import authSlice from 'views/Auth/redux';
import oauthMngmtSlice from 'views/OAuthManagement/redux';
import orgSlice from 'views/Organization/redux';
import monitorSlice from 'views/Monitoring/redux';
import orgMgmtSlice from 'views/OrganizationManagement/redux';
import devAttestationSlice from 'views/DeveloperAttestation/redux';
import registrationSlice from 'views/Registration/redux';

import { purgeStoredState } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
  key: 'root',
  storage,
};

const appReducer = combineReducers({
  auth: authSlice.reducer,
  org: orgSlice.reducer,
  monitor: monitorSlice.reducer,
  orgMgmnt: orgMgmtSlice.reducer,
  oauthMngmt: oauthMngmtSlice.reducer,
  developerAttestations: devAttestationSlice.reducer,
  registration: registrationSlice.reducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'Auth/logout/fulfilled') {
    purgeStoredState(persistConfig);
    state = {};
  }
  return appReducer(state, action);
};

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
