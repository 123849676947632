import * as React from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
  Grid,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Stepper,
  Step,
  StepLabel,
  CircularProgress,
} from '@material-ui/core';
import { Button } from 'components/controls/Button';
import { clearSession, submit, setRegistrationError } from 'views/Registration/redux/actions';
import { IQuestionChoice } from 'views/Registration/interfaces/IQuestionChoice';
import { IQuestion } from 'views/Registration/interfaces/IQuestion';
import css from 'components/AppFrame/app-frame.module.scss';
import { IIdentityQuestionsProps } from 'views/Registration/interfaces/IIdentityQuestionsProps';

const IdentityQuestions: React.FC<IIdentityQuestionsProps> = ({ session, org }) => {
  const dispatch = useDispatch();

  const [questionChoices, setQuestionChoices] = React.useState<IQuestionChoice[]>([]);
  const [choiceSelected, setChoiceSelected] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    if (activeStep === session.questions.length - 1) {
      handleSubmitClick();
    } else {
      setChoiceSelected(false);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleQuestionChoiceChange = (event) => {
    const questionIndex = Number(event.target.name);
    const choices = questionChoices.filter((choice) => {
      return choice.index !== questionIndex;
    });

    setChoiceSelected(true);
    setQuestionChoices([
      ...choices,
      {
        index: questionIndex,
        choice: event.target.value,
      },
    ]);
  };

  const handleSubmitClick = () => {
    setIsSubmitting(true);
    dispatch(
      submit({
        session_id: session.session_id,
        org_id: org.id,
        question_choices: questionChoices.sort((a, b) => {
          return a.index - b.index;
        }),
        demographics: session.demographics,
        ck: session.ck,
      }),
    ).then((resp) => {
      setIsSubmitting(false);
      if (resp.error) {
        dispatch(setRegistrationError({ session_id: session.session_id, code: resp.error.message }));
      }
    });
  };

  const handleStartOverClick = () => {
    dispatch(clearSession());
  };

  return (
    <Grid container>
      {isSubmitting ? (
        <Grid item xs={12}>
          <h4 className="alignTextCenter">
            <CircularProgress size={'8rem'} />
            <br /> One moment...
          </h4>
        </Grid>
      ) : (
        <>
          <Grid item xs={12}>
            <h2>To continue, please answer a few questions.</h2>
          </Grid>
          <Grid item xs={12}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {session.questions.map((question, index) => (
                <Step key={`progress-step-${index}`}>
                  <StepLabel>{index + 1}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>
          <Grid item xs={12}>
            {activeStep === session.questions.length ? (
              <div>
                <p>All questions completed</p>
                <Button onClick={handleReset}>Reset</Button>
              </div>
            ) : (
              <div>
                <FormControl component="fieldset">
                  <h5>{session.questions[activeStep].text}</h5>
                  <RadioGroup
                    key={`question-${activeStep}`}
                    name={`${activeStep}`}
                    onChange={handleQuestionChoiceChange}
                  >
                    {_.map(session.questions[activeStep].choices, (choice, choiceIndex) => (
                      <FormControlLabel
                        key={`choice-${choiceIndex + 1}`}
                        value={`${choiceIndex + 1}`}
                        control={<Radio />}
                        label={choice}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
                <div className="alignTextRight">
                  {activeStep !== 0 && <Button onClick={handleBack}>Back</Button>}
                  <Button onClick={handleNext} disabled={!choiceSelected}>
                    {activeStep === session.questions.length - 1 ? 'Submit' : 'Next'}
                  </Button>
                </div>
              </div>
            )}
          </Grid>
          <Grid item xs={12}>
            <hr />
          </Grid>
          <Grid item xs={12} className="alignTextCenter">
            <Button type="link" onClick={handleStartOverClick}>
              Start Over
            </Button>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default IdentityQuestions;
