import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Grid } from '@material-ui/core';
import { Visibility, Forward } from '@material-ui/icons';
import { IListDataItem } from 'views/OAuthManagement/interfaces/IListDataItem';
import { IDeveloperAttestation } from 'views/DeveloperAttestation/interfaces/IDeveloperAttestation';
import { IUser } from 'common/interfaces/IUser';
import { Tile } from 'components/controls/Tile';
import { MUIChip } from 'components/controls/MUI/MUIChip';
import { MUIModal } from 'components/controls/MUI/MUIModal';
import { Button } from 'components/controls/Button';
import AttestationFormModal from 'views/DeveloperAttestation/AttestationFormModal';
import ReviewAttestation from 'views/ApproveAttestation/components/ReviewAttestation';
import css from 'views/Dashboard/dashboard.module.scss';
import * as attestationActions from 'views/DeveloperAttestation/redux/actions';

interface IAttestationTileProps {
  attestation: IDeveloperAttestation;
  secondaryData?: IListDataItem;
}

const AttestationTile: React.FC<IAttestationTileProps> = ({ attestation, secondaryData }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.user) as IUser;
  const attestationStatus = attestation.status || 'incomplete';
  const [attestationModal, setAttestationModal] = React.useState(false);

  const toggleAttestationModal = () => {
    setAttestationModal(!attestationModal);
  };

  const handleReviewComplete = () => {
    dispatch(attestationActions.getAttestations());
    toggleAttestationModal();
  };

  const getAttestationAvatarChip = (item: IDeveloperAttestation) => {
    if (item.status === 'complete') {
      return <MUIChip type="approved" />;
    } else if (item.status === 'incomplete') {
      return <MUIChip type="draft" />;
    } else if (item.status === 'pending') {
      return <MUIChip type="pending" />;
    } else if (item.status === 'rejected') {
      return <MUIChip type="issue" />;
    }

    return null;
  };
  return (
    <>
      <Tile
        className={css.tile}
        title={secondaryData?.client?.name}
        description={secondaryData?.app?.app_descr}
        avatarChip={getAttestationAvatarChip(attestation)}
        actions={
          <Button className={css.tileActionButton} tertiary onClick={toggleAttestationModal}>
            {currentUser.user_type === 'developer' && (
              <>
                {attestationStatus === 'complete' && (
                  <>
                    <Visibility /> View Attestation
                  </>
                )}
                {attestationStatus === 'incomplete' && (
                  <>
                    <Forward /> Submit
                  </>
                )}
                {(attestationStatus === 'rejected' || attestationStatus === 'pending') && (
                  <>
                    <Forward /> Resubmit
                  </>
                )}
              </>
            )}
            {currentUser.user_type === 'mihin-admin' && (
              <>
                <Visibility /> View Attestation
              </>
            )}
          </Button>
        }
        bottomContentClassName={css.tileMetaInfoRegion}
        bottomContent={
          <Grid container className={css.tileMetaInfoBody}>
            <Grid item xs={12}>
              <span>Submitted:</span> <span>{moment(attestation.created_date).format('MMM DD, YYYY')}</span>
            </Grid>
          </Grid>
        }
      />
      <MUIModal width="lg" allowClose open={attestationModal} handleClose={toggleAttestationModal}>
        {attestationStatus === 'complete' || currentUser.user_type === 'mihin-admin' ? (
          <ReviewAttestation
            bindComplete={handleReviewComplete}
            bindCancel={toggleAttestationModal}
            forApproval={currentUser.user_type === 'mihin-admin'}
            attestation={attestation}
            application={secondaryData?.app}
            developer={secondaryData?.user}            
          />
        ) : (
          <AttestationFormModal bindComplete={toggleAttestationModal} attestationData={attestation} />
        )}
      </MUIModal>
    </>
  );
};

export default AttestationTile;
