import { createAsyncThunk } from '@reduxjs/toolkit';
import { API_URL, AUTH_SERVER_API_URL, GUID_REGEX } from 'settings';
import { Auth as awsAmplifyAuth } from 'aws-amplify';
import _ from 'lodash';
import { getCurrentAuthHeaders } from 'common/utils/getCurrentAuthHeaders';
import { IUser } from 'common/interfaces/IUser';
import { ICognitoUser } from 'common/interfaces/ICognitoUser';
import { ILoginValues } from 'views/Auth/Login/interfaces/ILoginValues';
import { ISignupValues } from 'views/Auth/Signup/interfaces/ISignupValues';
import { IForgotPasswordValues } from 'views/Auth/ForgotPassword/interfaces/IForgotPasswordValues';
import { IResetPasswordValues } from 'views/Auth/ResetPassword/interfaces/IResetPasswordValues';
import { IOAuthAppCredential } from 'views/OAuthManagement/interfaces/IOAuthAppCredential';

interface ValidationErrors {
  errorMessage: string;
  field_errors: Record<string, string>;
}

interface ICurrentUser {
  currentUser: IUser;
}

interface IForgotPasswordSubmit {
  username: string;
  code: string;
  password: string;
}

const normalizeUser = (user: ICognitoUser, tempPassword = '') => {
  if (user) {
    return {
      username: user.username,
      challengeName: user.challengeName,
      user_type: user.challengeParam
        ? user.challengeParam.userAttributes['custom:user_type']
        : user.attributes['custom:user_type'],
      organization_id: user.challengeParam
        ? user.challengeParam.userAttributes['custom:organization_id']
        : user.attributes['custom:organization_id'],
      given_name: user.challengeParam ? user.challengeParam.userAttributes.given_name : user.attributes.given_name,
      family_name: user.challengeParam ? user.challengeParam.userAttributes.family_name : user.attributes.family_name,
      preferred_username: user.challengeParam ? user.username : user.attributes['preferred_username'],
      email: user.challengeParam ? user.challengeParam.userAttributes.email : user.attributes.email,
      phone_number: user.challengeParam
        ? user.challengeParam.userAttributes.phone_number
        : user.attributes.phone_number,
      challengeTempPassword: user.challengeParam ? tempPassword : '',
      terms_accepted: user.challengeParam ? 0 : user.attributes['custom:terms_accepted'],
    } as IUser;
  }
  return user;
};

export const login = createAsyncThunk<
  IUser,
  ILoginValues,
  {
    rejectValue: ValidationErrors;
  }
>('Auth/login', async ({ username, password }, { rejectWithValue }) => {
  try {
    const response = await awsAmplifyAuth.signIn(username, password);
    return normalizeUser(response, password);
  } catch (err) {
    throw err;
  }
});

export const logout = createAsyncThunk<boolean>('Auth/logout', async () => {
  try {
    const response = await awsAmplifyAuth.signOut();
    return true;
  } catch (err) {
    throw err;
  }
});

export const forgotPassword = createAsyncThunk<
  any,
  IForgotPasswordValues,
  {
    rejectValue: ValidationErrors;
  }
>('Auth/forgot-password', async (userData, { rejectWithValue }) => {
  const response = await awsAmplifyAuth.forgotPassword(userData.username);
  return response;
});

export const forgotPasswordSubmit = createAsyncThunk<any, IForgotPasswordSubmit>(
  'Auth/forgot-password-submit',
  async ({ username, code, password }) => {
    const response = await awsAmplifyAuth.forgotPasswordSubmit(username, code, password);
    return response;
  },
);

export const resetPassword = createAsyncThunk<void, IResetPasswordValues & ICurrentUser>(
  'Auth/reset-password',
  async ({ new_password }) => {
    awsAmplifyAuth.currentUserPoolUser().then((user) => {
      awsAmplifyAuth.completeNewPassword(user, new_password, []);
    });
  },
);

export const getAuth = createAsyncThunk<IUser>('Auth/get-auth', async () => {
  try {
    const user = await awsAmplifyAuth.currentUserPoolUser();
    return normalizeUser(user);
  } catch (err) {
    throw err;
  }
});

export const signup = createAsyncThunk<
  void,
  ISignupValues,
  {
    rejectValue: ValidationErrors;
  }
>('Auth/signup', async (userData, { rejectWithValue }) => {
  try {
    const headers = await getCurrentAuthHeaders('json', false);
    const request = {
      method: 'POST',
      headers,
      body: JSON.stringify({
        username: userData.username,
        email: userData.email,
        password: userData.password,
        'custom:user_type': 'developer',
        'custom:terms_accepted': `${userData.terms_accepted === 1}`,
      }),
    };

    const response = await fetch(`${API_URL}/user/signup`, request);
    const userSub = await response.text();
    if (userSub.indexOf('InvalidPasswordException') != -1) {
      throw {
        code: 'InvalidPasswordException',
      };
    }
    if (userSub.indexOf('UsernameExistsException') != -1) {
      throw {
        code: 'UsernameExistsException',
      };
    }
    if (!userSub.match(GUID_REGEX)) {
      if (userSub.toLowerCase().indexOf('invalid length') != -1) {
        throw {
          code: 'InvalidPasswordException',
        };
      } else {
        throw {
          code: 'GeneralError',
        };
      }
    }
  } catch (err) {
    if ('code' in err) {
      throw new Error(err.code);
    } else {
      throw err;
    }
  }
});

export const acceptTermsOfService = createAsyncThunk<void>('Auth/update-user-attributes', async () => {
  const currentUser = await awsAmplifyAuth.currentAuthenticatedUser();
  const response = await awsAmplifyAuth.updateUserAttributes(currentUser, {
    ['custom:terms_accepted']: 'true',
  });
});

export const completeSignup = createAsyncThunk<void, ISignupValues & ICurrentUser>(
  'Auth/complete-signup',
  async ({ email, username, password, currentUser }) => {
    try {
      const user = await awsAmplifyAuth.signIn(email, currentUser.challengeTempPassword);
      const updatedUser = await awsAmplifyAuth.completeNewPassword(user, password);
      const headers = await getCurrentAuthHeaders('json');
      const request = {
        method: 'PUT',
        headers,
        body: JSON.stringify({
          email: email,
          username: username,
        }),
      };
      await fetch(`${API_URL}/org/${currentUser.organization_id}/user/${currentUser.username}`, request);
      await awsAmplifyAuth.updateUserAttributes(updatedUser, {
        ['custom:terms_accepted']: 'true',
      });
      window.location.href = '/';
    } catch (ex) {
      throw ex;
    }
  },
);

export const updateUser = createAsyncThunk<void, IUser>('Auth/update-user', async (updateUser) => {
  try {
    // validate user data is alphanumeric
    if (updateUser.preferred_username && !updateUser.preferred_username.match(/^[a-zA-Z0-9]+$/)) {
      throw new Error('Username is invalid.');
    }
    if (updateUser.given_name && !updateUser.given_name.match(/^[a-zA-Z0-9'-\s]+$/)) {
      throw new Error('First name is invalid.');
    }
    if (updateUser.family_name && !updateUser.family_name.match(/^[a-zA-Z0-9'-\s]+$/)) {
      throw new Error('Last name is invalid.');
    }

    const currentUser = await awsAmplifyAuth.currentAuthenticatedUser();
    const response = await awsAmplifyAuth.updateUserAttributes(currentUser, {
      given_name: updateUser.given_name,
      family_name: updateUser.family_name,
      email: updateUser.email,
      preferred_username: updateUser.preferred_username,
    });
  } catch (ex) {
    throw ex;
  }
});

export const deleteCurrentAccount = createAsyncThunk<boolean>('Auth/delete-current-user', async () => {
  try {
    const currentUser = await awsAmplifyAuth.currentUserPoolUser();
    if (currentUser) {
      const headers = await getCurrentAuthHeaders('json');

      // pull in the current user's app credentials, this gets us a list of the auth server clients
      const credsResponse = await fetch(`${API_URL}/application/credentials`, { method: 'GET', headers });
      const credentials = (await credsResponse.json()) as IOAuthAppCredential[];

      const request = {
        method: 'DELETE',
        headers,
      };

      const appDataResponse = await fetch(`${API_URL}/user/purge_account_data`, request).then(() => {
        // after deleting data from the portal API, delete the auth clients
        _.each(credentials, (cred) => {
          fetch(`${AUTH_SERVER_API_URL}/client/${cred.auth_client_id}`, request);
        });
      });

      // delete the Cognito user
      const success = await currentUser.deleteUser((error, data) => {
        if (error) {
          console.log(error);
        }
      });

      return success;
    } else {
      return false;
    }
  } catch (err) {
    throw err;
  }
});

export const getUser = async (username) => {
  const headers = await getCurrentAuthHeaders('json');

  const request = {
    method: 'GET',
    headers,
  };

  const response = await fetch(`${API_URL}/user/${username}`, request);
  return (await response.json()) as IUser;
};
