import React, { useState, useEffect } from 'react';
import { Card, CardContent, Grid } from '@material-ui/core';
import classes from 'classnames';

import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'components/controls/Button';
import { saveDevAttestation } from './redux/actions';
import { Publish } from '@material-ui/icons';
import { ISubMenuItem } from 'components/controls/SideMenu/interfaces/ISubMenuItem';
import { IOAuthApp } from 'views/OAuthManagement/interfaces/IOAuthApp';
import { IDeveloperAttestation } from 'views/DeveloperAttestation/interfaces/IDeveloperAttestation';
import AppFrame from 'components/AppFrame';
import _ from 'lodash';
import * as attestationActions from './redux/actions';
import AttestationFormModal from './AttestationFormModal';
import { MUIModal } from 'components/controls/MUI/MUIModal';
import styles from './attestation-list.module.scss';
import { MUIChip } from 'components/controls/MUI/MUIChip';
import { IListDataItem } from 'views/OAuthManagement/interfaces/IListDataItem';
import moment from 'moment';
import { listDataBuilder } from 'common/utils/listDataBuilder';

const muiChipCustomLabels = {
  incomplete: 'Open',
  pending: 'Pending',
  rejected: 'Action Required',
  complete: 'Approved',
};

const openModalButtonProps = {
  pending: {
    label: 'Resubmit',
    extraProps: { secondary: true },
  },
  incomplete: {
    label: 'Submit',
    extraProps: {},
  },
  complete: {
    label: 'View Attestation',
    extraProps: {},
  },
  rejected: {
    label: 'Resubmit',
    extraProps: {},
  },
};

const AttestationList = (props) => {
  const history = useHistory();
  const oauthApps = useSelector((state) => state.oauthMngmt.oauthApps);
  const oauthAppCredentials = useSelector((state) => state.oauthMngmt.oauthAppCredentials);
  const authClients = useSelector((state) => state.oauthMngmt.authClients);
  const devAttestations = useSelector((state) => state.developerAttestations.attestations);
  const [listData, setListData] = React.useState<IListDataItem[]>([]);
  const [newAppModal, setNewAppModal] = React.useState(false);

  const [currentAttestation, setCurrentAttestation] = useState<IDeveloperAttestation>();
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (oauthApps.length > 0 && oauthAppCredentials.length > 0 && authClients.length > 0) {
      const appData = listDataBuilder({
        credentials: oauthAppCredentials,
        applications: oauthApps,
        clients: authClients,
        attestations: devAttestations,
      });
      setListData(appData);
    }
  }, [oauthApps, oauthAppCredentials, authClients]);

  const toggleAttestationSubmitModal = () => setAttestationModalVisible(!attestationModalVisible);

  const subMenuItems: ISubMenuItem[] = [];

  const renderListItem = (attestation: IDeveloperAttestation) => {
    const secondaryData: IListDataItem | undefined = _.find(
      listData,
      (listItem) => listItem.app.id === attestation.app_id,
    );
    const attestationStatus = attestation?.status || 'incomplete';
    const buttonProps = openModalButtonProps[attestationStatus];
    const handleOpenAttestationClick = () => {
      setCurrentAttestation(attestation);
      setAttestationModalVisible(true);
    };
    return (
      !!attestation &&
      !!secondaryData &&
      !!secondaryData.client &&
      !!secondaryData.app && (
        <Card className={classes(styles.listItem)} key={`attestation_list_item_${attestation.id}`}>
          <CardContent>
            <Grid container>
              <Grid item xs={1}>
                <MUIChip type={attestationStatus} customLabel={muiChipCustomLabels[attestationStatus]} />
              </Grid>
              <Grid item xs={8}>
                <h4>{secondaryData.client.name}</h4>
              </Grid>
              <Grid item xs={3} className="alignTextRight">
                <p>
                  <Button {...buttonProps.extraProps} type="button" onClick={handleOpenAttestationClick}>
                    {buttonProps.label}
                  </Button>
                </p>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={1}></Grid>
              <Grid item xs={4}>
                <div className={styles.clientToken}>APP LAST UPDATED:</div>
                <div>
                  {moment(secondaryData.app.updated_date || secondaryData.app.created_date).format('MMM DD, YYYY')}
                </div>
              </Grid>
              <Grid item xs={4}>
                <div className={styles.clientToken}>SUBMITTED ON:</div>
                <div>{moment(attestation.created_date).format('MMM DD, YYYY')}</div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )
    );
  };

  const [attestationModalVisible, setAttestationModalVisible] = useState(false);

  useEffect(() => {
    dispatch(attestationActions.getAttestations());
  }, [oauthApps]);

  return (
    <AppFrame layoutType="developerPortal" selectedMenuItem="DevAttestation">
      {_.map(devAttestations, renderListItem)}
      <MUIModal width="lg" allowClose open={attestationModalVisible} handleClose={toggleAttestationSubmitModal}>
        <AttestationFormModal bindComplete={toggleAttestationSubmitModal} attestationData={currentAttestation} />
      </MUIModal>
    </AppFrame>
  );
};

export default AttestationList;
