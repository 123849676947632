import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { Grid, Radio, RadioGroup, FormControl, FormControlLabel } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Button } from 'components/controls/Button';
import { IOAuthApp } from 'views/OAuthManagement/interfaces/IOAuthApp';
import { IUser } from 'common/interfaces/IUser';
import { IAppPrivacyPolicyAttestation } from 'views/OAuthManagement/interfaces/IAppPrivacyPolicyAttestation';
import { IOAuthModalProps } from 'views/OAuthManagement/interfaces/IOAuthModalProps';
import { trimAllStringProperties } from 'common/utils/trimAllStringProperties';
import { getUserDisplayName } from 'common/utils/getUserDisplayName';
import {
  submitOAuthAppPrivacyPolicyAttestation as submitOAuthAppPrivacyPolicyAttestationAction,
  getOAuthAppPrivacyPolicyAttestation as getOAuthAppPrivacyPolicyAttestationAction,
} from 'views/OAuthManagement/redux/actions';
import css from 'components/AppFrame/app-frame.module.scss';
import formCSS from 'views/DeveloperAttestation/submit-attestation.module.scss';
import classes from 'classnames';
import _ from 'lodash';

const AppPrivacyPolicyAttestation: React.FC<IOAuthModalProps> = ({ bindComplete, bindCancel }) => {
  const dispatch = useDispatch();

  const currentApp = useSelector((state) => state.oauthMngmt.currentApp) as IOAuthApp;
  const currentUser = useSelector((state) => state.auth.user) as IUser;

  const currentPrivacyPolicyAttestation = useSelector(
    (state) => state.oauthMngmt.currentPrivacyPolicyAttestation,
  ) as IAppPrivacyPolicyAttestation;

  const initialValues: IAppPrivacyPolicyAttestation = {
    attest_publicly_available: false,
    attest_includes_minimum_information: false,
    status: 'pending',
  };

  const [formValues, setFormValues] = useState(initialValues);

  const [sendError, setSendError] = React.useState('');

  useEffect(() => {
    if (!!currentPrivacyPolicyAttestation?.id) {
      setFormValues({
        ...formValues,
        ...currentPrivacyPolicyAttestation,
      });
    } else {
      // bindComplete();
    }
  }, [currentPrivacyPolicyAttestation]);

  const formPreprocessor = () => {
    const trimmedValues = trimAllStringProperties(formValues);
    const attestationData = {
      ...trimmedValues,
      app_id: currentPrivacyPolicyAttestation?.app_id || currentApp.id,
      developer_id: currentPrivacyPolicyAttestation?.developer_id || currentApp.username,
    };
    return attestationData;
  };

  const handleSubmit = (values: IAppPrivacyPolicyAttestation, setSubmitting: any) => {
    const attestationData = formPreprocessor();
    dispatch(submitOAuthAppPrivacyPolicyAttestationAction(attestationData)).then(() => {
      dispatch(getOAuthAppPrivacyPolicyAttestationAction(attestationData.app_id || 0));
      bindComplete();
    });
    setSubmitting(false);
  };

  const parseRadioValue = (e) => {
    // This little bit of weirdness is brought to you by a formik quirk
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value === 'true',
    });
  };

  const AttestationRadio = ({ label, value, name, onChange }) => (
    <div style={{ width: '100%' }}>
      <FormControl component="fieldset" style={{ width: '100%' }}>
        {/* FIXME: this isn't correct MUI structure */}
        <div className={classes(formCSS.radioFieldSet)}>
          <RadioGroup aria-label={label} value={value} style={{ minWidth: '15%' }} row>
            <FormControlLabel
              name={name}
              onChange={onChange}
              value={true}
              control={<Radio color="default" />}
              label="Yes"
              labelPlacement="top"
            />
            <FormControlLabel
              name={name}
              onChange={onChange}
              value={false}
              control={<Radio color="default" />}
              label="No"
              labelPlacement="top"
            />
          </RadioGroup>
          <div className={classes(formCSS.radioFieldSetLabel)}>{label}</div>
        </div>
      </FormControl>
    </div>
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        <Formik
          initialValues={formValues}
          enableReinitialize={true}
          onSubmit={(values, { setSubmitting }) => handleSubmit(values, setSubmitting)}
        >
          {({ values, handleChange, handleBlur, handleSubmit, isSubmitting }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Grid container>
                  {!!sendError && (
                    <Grid item xs={12}>
                      <Alert severity="error">{sendError}</Alert>
                    </Grid>
                  )}
                  <>
                    <Grid item xs={12}>
                      <h4 className={css.modalHeader}>Privacy Policy Attestation</h4>
                    </Grid>
                    <Grid item xs={12}>
                      <p>
                        To ensure that your application is ready to be registered in production, please look at your
                        application information below carefully and complete the attestation checklist below.
                      </p>
                    </Grid>

                    <Grid item xs={12}>
                      <label>Application Information</label>
                    </Grid>
                    <Grid container>
                      <Grid item xs={4} container>
                        <Grid item xs={12}>
                          Developed By:
                        </Grid>
                        <Grid item xs={12}>
                          {getUserDisplayName(currentUser)}
                        </Grid>
                        {currentApp.app_contacts && (
                          <Grid item xs={12}>
                            {currentApp.app_contacts}
                          </Grid>
                        )}
                      </Grid>
                      {currentApp.submission_date && (
                        <Grid item xs={4} container>
                          <Grid item xs={12}>
                            Submitted On:
                          </Grid>
                          <Grid item xs={12}>
                            {currentApp.submission_date}
                          </Grid>
                        </Grid>
                      )}
                    </Grid>

                    <Grid item xs={12}>
                      <hr />
                    </Grid>
                    <Grid item xs={12}>
                      <p>
                        Blue Cross* asks that any App developer planning to access Blue Cross Patient Access API attest
                        that it complies with the following statements. If you do not attest to compliance with these
                        statements, we will notify any member that requests his/her protected health information using
                        your App of that fact and suggest that the member select an App that has attested to complying
                        with these statements.
                      </p>
                    </Grid>
                    <AttestationRadio
                      name="attest_publicly_available"
                      label='The App has a publicly available privacy policy, written in plain language, that has been affirmatively shared with the member prior to the member authorizing the App access to their health information. To "affirmatively share" means that the member must take an action to indicate s/he saw the privacy policy, such as click or check a box.'
                      value={formValues.attest_publicly_available}
                      onChange={parseRadioValue}
                    />
                    <AttestationRadio
                      name="attest_includes_minimum_information"
                      label="The App's privacy policy includes, at a minimum, the following important information:"
                      value={formValues.attest_includes_minimum_information}
                      onChange={parseRadioValue}
                    />
                    <Grid container>
                      <Grid item xs={2}></Grid>
                      <Grid item xs={10}>
                        <ul className={formCSS.checklistItems}>
                          <li>
                            How a member's health information may be accessed, exchanged, or used by any person or other
                            entity, including whether the member's health information may be shared or sold at any time
                            (including in the future);
                          </li>
                          <li>
                            A requirement for express consent from a member before the member's health information is
                            accessed, exchanged, orused, including receiving express consent before a member's health
                            information is shared or sold (other than disclosures required by law or disclosures
                            necessary in connection with the sale of the application or asimilar transaction);
                          </li>
                          <li>If an App will access any other information from a member's device; and</li>
                          <li>
                            How a member can discontinue the App’s access to his/her data and what the App's policy and
                            process is for disposing of a member's data once the member has withdrawn consent.
                          </li>
                        </ul>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} className={classes('alignTextRight', css.modalActionButtons, css.formRow)}>
                      <Button type="button" secondary onClick={bindComplete}>
                        Cancel
                      </Button>
                      <Button type="submit">Submit Attestation</Button>
                    </Grid>
                    <Grid item xs={12}>
                      <small>* "Blue Cross" refers to Blue Cross Blue Shield of Michigan, Blue Care Network</small>
                    </Grid>
                  </>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default AppPrivacyPolicyAttestation;
