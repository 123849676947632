import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Grid } from '@material-ui/core';
import { HighlightOff, CheckCircleOutline, MailOutline } from '@material-ui/icons';
import classes from 'classnames';
import moment from 'moment';
import { Button } from 'components/controls/Button';
import css from 'components/AppFrame/app-frame.module.scss';
import attestCSS from 'views/ApproveAttestation/approve-attestation.module.scss';
import { IAttestationModalProps } from 'views/ApproveAttestation/interfaces/IAttestationModalProps';
import denyIcon from 'assets/icons/icon-no-attestation.svg';
import * as attestationActions from 'views/DeveloperAttestation/redux/actions';
import { getUserDisplayName } from 'common/utils/getUserDisplayName';

const ReviewAttestation: React.FC<IAttestationModalProps> = ({
  attestation,
  application,
  developer,
  bindComplete,
  bindCancel,
  forApproval,
}) => {
  const dispatch = useDispatch();
  const [disableApprove, setDisableApprove] = React.useState<boolean>(true);
  const [denyNotification, setDenyNotification] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (attestation) {
      setDisableApprove(
        !attestation.attest_not_malicious ||
          !attestation.attest_data_security_safeguards ||
          !attestation.attest_passwords_hidden ||
          !attestation.attest_minimum_scopes ||
          !attestation.attest_transmissions_encrypted ||
          !attestation.attest_credentials_confidential_safeguards ||
          !attestation.attest_user_restrict_authorized_devices ||
          !attestation.attest_info_stored_elsewhere_notification ||
          !attestation.attest_only_device_stored_tokens ||
          !attestation.attest_qualified_tech_security_assessments ||
          !attestation.attest_discovered_vulnerabilities_remediated,
      );
    }
  }, [attestation]);

  const handleDenyClick = () => {
    if (attestation) {
      dispatch(
        attestationActions.updateDevAttestationStatus({
          ...attestation,
          dev_email: developer?.email,
          status: 'rejected',
        }),
      ).then(() => {
        setDenyNotification(true);
      });
    }
  };
  const handleApproveClick = () => {
    if (attestation) {
      dispatch(
        attestationActions.updateDevAttestationStatus({
          ...attestation,
          dev_email: developer?.email,
          status: 'complete',
        }),
      ).then(() => {
        bindComplete();
      });
    }
  };
  const handleEmailDeveloperClick = () => {
    if (developer) {
      window.location.href = `mailto:${developer.email}`;
    }
  };

  return (
    <>
      {attestation && application && developer && (
        <Grid container>
          {denyNotification ? (
            <>
              <Grid item xs={12} className="alignTextCenter">
                <img src={denyIcon} alt="You've denied attestation for this application." />
                <h3>You've denied attestation for this application.</h3>
                <div className={attestCSS.denyMessage}>
                  We would recommend that you contact this application’s developer by email to give them a short
                  explanation for the application not meeting attestation requirements so that the developer can quickly
                  resolve their attestation issues.
                </div>
                <div>
                  <Button tertiary onClick={handleEmailDeveloperClick}>
                    <MailOutline /> Email the Developer
                  </Button>
                </div>
              </Grid>
              <Grid container>
                <Grid item xs={12} className={classes('alignTextRight', css.modalActionButtons)}>
                  <Button type="button" secondary onClick={bindComplete}>
                    Close
                  </Button>
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <h4 className={css.modalHeader}>Application Attestation</h4>
              </Grid>
              <Grid item xs={3} className={attestCSS.attestDetails}>
                <div className={attestCSS.detailLabel}>DEVELOPED BY:</div>
                <div>{getUserDisplayName(developer)}</div>
                <div>
                  <a href={`mailto:${developer.email}`}>{developer.email}</a>
                </div>
              </Grid>
              <Grid item xs={9} className={attestCSS.attestDetails}>
                {!!attestation.created_date && (
                  <>
                    <div className={attestCSS.detailLabel}>SUBMITTED ON:</div>
                    <div>{moment(attestation.created_date).format('MMM DD, YYYY')}</div>
                  </>
                )}
              </Grid>
              <Grid item xs={12}>
                <hr />
              </Grid>
              <Grid item xs={12}>
                <h4 className={attestCSS.checklistHeader}>Attestation Checklist</h4>
              </Grid>
              <Grid container>
                <Grid item xs={1} className={classes(attestCSS.attestRow, 'alignTextCenter')}>
                  {attestation.attest_credentials_confidential_safeguards ? (
                    <CheckCircleOutline className={css.success} />
                  ) : (
                    <HighlightOff className={css.error} />
                  )}
                </Grid>
                <Grid item xs={11} className={attestCSS.attestRow}>
                  Technical and procedural safeguards are in place to maintain the confidentiality of any developer
                  keys, app keys, or other confidential information used for authentication (e.g. passwords, tokens,
                  keys, etc).
                </Grid>

                <Grid item xs={1} className={classes(attestCSS.attestRow, 'alignTextCenter')}>
                  {attestation.attest_data_security_safeguards ? (
                    <CheckCircleOutline className={css.success} />
                  ) : (
                    <HighlightOff className={css.error} />
                  )}
                </Grid>
                <Grid item xs={11} className={attestCSS.attestRow}>
                  Data security safeguards are consistent with the responsible stewardship associated with protection of
                  a user's health information against risks such as unauthorized access, use, alteration, or disclosure.
                </Grid>
                <Grid item xs={1} className={classes(attestCSS.attestRow, 'alignTextCenter')}>
                  {attestation.attest_transmissions_encrypted ? (
                    <CheckCircleOutline className={css.success} />
                  ) : (
                    <HighlightOff className={css.error} />
                  )}
                </Grid>
                <Grid item xs={11} className={attestCSS.attestRow}>
                  Personal information, health information, keys, credentials, tokens, and other sensitive information
                  is protected through a combination of mechanisms including, at a minimum: secure storage, use of a
                  strong encryption algorithm both in transit and at rest (e.g. TLS 1.2+, AES 256), and accountability
                  measures (e.g., access controls and audit logs).
                </Grid>

                <Grid item xs={1} className={classes(attestCSS.attestRow, 'alignTextCenter')}>
                  {attestation.attest_minimum_scopes ? (
                    <CheckCircleOutline className={css.success} />
                  ) : (
                    <HighlightOff className={css.error} />
                  )}
                </Grid>
                <Grid item xs={11} className={attestCSS.attestRow}>
                  Access scopes requested by the app support the principle of minimum use.
                </Grid>
                <Grid item xs={1} className={classes(attestCSS.attestRow, 'alignTextCenter')}>
                  {attestation.attest_user_restrict_authorized_devices ? (
                    <CheckCircleOutline className={css.success} />
                  ) : (
                    <HighlightOff className={css.error} />
                  )}
                </Grid>
                <Grid item xs={11} className={attestCSS.attestRow}>
                  A user can restrict app usage to authorized devices.
                </Grid>
                <Grid item xs={1} className={classes(attestCSS.attestRow, 'alignTextCenter')}>
                  {attestation.attest_info_stored_elsewhere_notification ? (
                    <CheckCircleOutline className={css.success} />
                  ) : (
                    <HighlightOff className={css.error} />
                  )}
                </Grid>
                <Grid item xs={11} className={attestCSS.attestRow}>
                  App notifies user if personal and health information is stored in locations other than their device
                  where the app is installed.
                </Grid>
                <Grid item xs={1} className={classes(attestCSS.attestRow, 'alignTextCenter')}>
                  {attestation.attest_only_device_stored_tokens ? (
                    <CheckCircleOutline className={css.success} />
                  ) : (
                    <HighlightOff className={css.error} />
                  )}
                </Grid>
                <Grid item xs={11} className={attestCSS.attestRow}>
                  Authentication tokens are never stored in a location other than the device where the app is installed.
                </Grid>
                <Grid item xs={1} className={classes(attestCSS.attestRow, 'alignTextCenter')}>
                  {attestation.attest_qualified_tech_security_assessments ? (
                    <CheckCircleOutline className={css.success} />
                  ) : (
                    <HighlightOff className={css.error} />
                  )}
                </Grid>
                <Grid item xs={11} className={attestCSS.attestRow}>
                  Technical security assessments are performed by a qualified and experienced party and are incorporated
                  into the Software Development Life Cycle (SDLC) for the app.
                </Grid>
                <Grid item xs={1} className={classes(attestCSS.attestRow, 'alignTextCenter')}>
                  {attestation.attest_discovered_vulnerabilities_remediated ? (
                    <CheckCircleOutline className={css.success} />
                  ) : (
                    <HighlightOff className={css.error} />
                  )}
                </Grid>
                <Grid item xs={11} className={attestCSS.attestRow}>
                  Internally and publicly discovered and reported app security vulnerabilities are acknowledged,
                  prioritized, and remediated within a documented timeframe based on severity.
                </Grid>
                <Grid item xs={1} className={classes(attestCSS.attestRow, 'alignTextCenter')}>
                  {attestation.attest_not_malicious ? (
                    <CheckCircleOutline className={css.success} />
                  ) : (
                    <HighlightOff className={css.error} />
                  )}
                </Grid>
                <Grid item xs={11} className={attestCSS.attestRow}>
                  Technical and procedural safeguards are in place to ensure app does not transmit viruses, files,
                  computer code, or programs that may harm or disrupt the normal operation of InterOp Station.
                </Grid>
                <Grid item xs={1} className={classes(attestCSS.attestRow, 'alignTextCenter')}>
                  {attestation.attest_passwords_hidden ? (
                    <CheckCircleOutline className={css.success} />
                  ) : (
                    <HighlightOff className={css.error} />
                  )}
                </Grid>
                <Grid item xs={11} className={attestCSS.attestRow}>
                  App does not store or surreptitiously discover user’s passwords for accessing InterOp Station.
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} className={classes('alignTextRight', css.modalActionButtons)}>
                  <Button type="button" secondary onClick={bindCancel}>
                    Cancel
                  </Button>
                  {forApproval && (
                    <>
                      <Button type="button" notice onClick={handleDenyClick}>
                        Deny
                      </Button>
                      {attestation.status !== 'complete' && (
                        <Button type="button" disabled={disableApprove} onClick={handleApproveClick}>
                          Approve
                        </Button>
                      )}
                    </>
                  )}
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      )}
    </>
  );
};

export default ReviewAttestation;
