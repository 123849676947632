import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Formik, ErrorMessage } from 'formik';
import { Grid, CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Button } from 'components/controls/Button';
import { IUser } from 'common/interfaces/IUser';
import { IOAuthApp } from 'views/OAuthManagement/interfaces/IOAuthApp';
import { IAuthClient, clientOptions, grantOptions } from 'views/OAuthManagement/interfaces/IAuthClient';
import { IOAuthModalProps } from 'views/OAuthManagement/interfaces/IOAuthModalProps';
import { INewAppValues } from 'views/OAuthManagement/interfaces/INewAppValues';
import { trimAllStringProperties } from 'common/utils/trimAllStringProperties';
import Select from 'react-select';
import { OAuthScopeSelector } from 'components/controls/OAuthScopeSelector';
import css from 'components/AppFrame/app-frame.module.scss';
import formCSS from './new-payer.module.scss';
import classes from 'classnames';
import {
  createNewApp as createNewAppAction,
  getOAuthApps as getOAuthAppsAction,
  getOAuthAppCredentials as getOAuthAppCredentialsAction,
  getAuthClients as getAuthClientsAction,
} from 'views/OAuthManagement/redux/actions';

const NewApp: React.FC<IOAuthModalProps> = ({ bindComplete }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentUser = useSelector((state) => state.auth.user) as IUser;

  const initialValues: INewAppValues = { name: '', clientType: '', grantType: '', redirectURIs: '', scope: '' };
  const [clientType, setClientType] = React.useState('');
  const [grantType, setGrantType] = React.useState(grantOptions[0].value);
  const [appScope, setScope] = React.useState('');
  const [formCompleted, setFormCompleted] = React.useState(false);
  const [isSending, setIsSending] = React.useState(false);
  const [sendError, setSendError] = React.useState('');

  const handleClientTypeChange = (selectedValue) => {
    setClientType(selectedValue.value);
  };
  const handleGrantTypeChange = (selectedValue) => {
    setGrantType(selectedValue.value);
  };
  const handleScopeChange = (selectedValue) => {
    setScope(selectedValue);
  };

  const handleSubmit = (values: INewAppValues, setSubmitting: any) => {
    setIsSending(true);
    const trimmedValues = trimAllStringProperties(values);

    const oauthApp: IOAuthApp = {
      status: 1,
      image_url: '',
      documentation_url: '',
      org_website: '',
      app_descr: '',
      app_contacts: '',
      support_email: '',
      external_payer: true,
    };

    const defaultRedirectURI =
      trimmedValues.redirectURIs.indexOf(',') === -1
        ? trimmedValues.redirectURIs
        : trimmedValues.redirectURIs.split(',')[0];

    const clientApp: IAuthClient = {
      name: trimmedValues.name,
      client_type: +clientType,
      grant_type: +grantType,
      redirect_uris: trimmedValues.redirectURIs,
      default_redirect_uri: defaultRedirectURI,
      scope: appScope,
      client_category: 'production',
      organization_id: currentUser.organization_id || 1,
      pdex_client_flag: true,
      seek_pdex_consent: true,
    };

    dispatch(
      createNewAppAction({
        authClient: clientApp,
        authApp: oauthApp,
      }),
    ).then((response) => {
      if (response.error) {
        setIsSending(false);
        setSendError(response.error.message);
      } else {
        setIsSending(false);
        const newAppCredId = response.payload;
        history.push('/external-payer');
      }
    });

    setSubmitting(false);
  };

  const checkStepOne = (values: INewAppValues, callEvent: any) => {
    setFormCompleted(!!values.name && !!clientType && !!grantType && !!appScope && !!values.redirectURIs);
    return callEvent;
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={(values, { setSubmitting }) => handleSubmit(values, setSubmitting)}
        >
          {({ values, handleChange, handleBlur, handleSubmit, isSubmitting }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Grid container>
                  {!!sendError && (
                    <Grid item xs={12}>
                      <Alert severity="error">{sendError}</Alert>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <h4 className={css.modalHeader}>Register a new payer</h4>
                  </Grid>
                  <Grid item xs={12} className={css.formRow}>
                    <label htmlFor="name">Payer Name</label>
                    <input
                      name="name"
                      placeholder="Enter a Name"
                      type="text"
                      value={values.name}
                      onChange={checkStepOne(values, handleChange)}
                      onBlur={checkStepOne(values, handleBlur)}
                    />
                    <ErrorMessage name="name">{(msg) => <div className="input-feedback">{msg}</div>}</ErrorMessage>
                  </Grid>
                  <Grid container className={css.formRow} spacing={2}>
                    <Grid item xs={6}>
                      <label htmlFor="clientType">Client Type</label>
                      <Select
                        classNamePrefix="react-select"
                        placeholder="Select a Client Type"
                        options={clientOptions}
                        inputValue={values.clientType}
                        onChange={handleClientTypeChange}
                      />
                      <ErrorMessage name="userType">
                        {(msg) => <div className="input-feedback">{msg}</div>}
                      </ErrorMessage>
                    </Grid>
                    <Grid item xs={6}>
                      <label htmlFor="grantType">Grant Type</label>
                      <Select
                        classNamePrefix="react-select"
                        placeholder="Select a Grant Type"
                        options={grantOptions}
                        inputValue={values.grantType}
                        value={grantOptions[0]}
                        onChange={handleGrantTypeChange}
                      />
                      <ErrorMessage name="grantType">
                        {(msg) => <div className="input-feedback">{msg}</div>}
                      </ErrorMessage>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} className={css.formRow}>
                    <label htmlFor="scope">Scope(s)</label>
                    <OAuthScopeSelector
                      placeholder="Select Scope(s)"
                      defaultValue={values.scope}
                      onChange={handleScopeChange}
                    />
                    <ErrorMessage name="scope">{(msg) => <div className="input-feedback">{msg}</div>}</ErrorMessage>
                  </Grid>
                  <Grid item xs={12} className={css.formRow}>
                    <label htmlFor="redirectURIs">Callback URLs / Redirect URIs</label>
                    <textarea
                      name="redirectURIs"
                      className={formCSS.redirectURIs}
                      value={values.redirectURIs}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage name="redirectURIs">
                      {(msg) => <div className="input-feedback">{msg}</div>}
                    </ErrorMessage>
                  </Grid>
                  <Grid item xs={12} className={classes('alignTextRight', css.modalActionButtons)}>
                    <Button type="button" secondary onClick={bindComplete}>
                      Cancel
                    </Button>
                    <Button type="submit" disabled={!formCompleted || isSending}>
                      {isSending ? (
                        <span>
                          <CircularProgress size="15px" /> Saving...
                        </span>
                      ) : (
                        <span>Save Payer</span>
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default NewApp;
