import React from 'react';
import { Grid } from '@material-ui/core';
import { Button } from 'components/controls/Button';
import { TermsOfServiceContent } from './TermsOfServiceContent';
import { ITermsOfServiceModalProps } from './interfaces/ITermsOfServiceModalProps';
import classes from 'classnames';

import css from 'components/AppFrame/app-frame.module.scss';

const TermsOfServiceModal: React.FC<ITermsOfServiceModalProps> = ({ bindComplete, bindCancel }) => {
  return (
    <Grid>
      <Grid item xs={12} style={{ maxHeight: 600, overflowY: 'scroll' }}>
        <TermsOfServiceContent />
      </Grid>
      <Grid container xs={12} className={classes('alignTextRight', css.modalActionButtons, css.formRow)}>
        <Grid item xs={8} />
        {!!bindCancel && (
          <Grid item xs={2}>
            <Button onClick={bindCancel} secondary>
              Cancel
            </Button>
          </Grid>
        )}
        <Grid item xs={2}>
          <Button onClick={bindComplete}>I Accept</Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TermsOfServiceModal;
