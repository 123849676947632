import * as React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { monitoringySideMenuBuilder } from 'common/utils/monitoringSideMenuBuilder';
import { IUser } from 'common/interfaces/IUser';
import { TLayoutType } from 'components/AppFrame/interfaces/TLayoutType';
import AppFrame from 'components/AppFrame';
import 'components/AppFrame/app-frame.module.scss';

const MonitoringOverview: React.FC = (props) => {
  const history = useHistory();
  const currentUser = useSelector((state) => state.auth.user) as IUser;

  const layoutType: TLayoutType =
    currentUser?.user_type === 'developer'
      ? 'developerPortal'
      : currentUser?.user_type === 'payer'
      ? 'payerPortal'
      : 'adminPortal';

  const sideMenuItems = monitoringySideMenuBuilder({ active: 'monitoring-overview', history });

  return (
    <AppFrame layoutType={layoutType} selectedMenuItem="Monitoring" subMenuItems={sideMenuItems}>
      <Grid container>
        <Grid item xs={12}>
          <h2>Monitoring Overview</h2>
          <p>
            The MiHIN Group’s InterOp Station® is a fully FHIR-enabled platform that facilitates broad healthcare data
            interoperability. Use this section to monitor ongoing data operations. 
          </p>
        </Grid>
      </Grid>
    </AppFrame>
  );
};

export default MonitoringOverview;
