import { createAsyncThunk, createSlice, unwrapResult, createEntityAdapter } from '@reduxjs/toolkit';
import { IUser } from 'common/interfaces/IUser';
import { IAuthClient } from '../interfaces/IAuthClient';
import { IOAuthApp } from '../interfaces/IOAuthApp';
import { IOAuthAppCredential } from '../interfaces/IOAuthAppCredential';
import { IAppPrivacyPolicyAttestation } from '../interfaces/IAppPrivacyPolicyAttestation';
import * as oauthMngmtActions from './actions';

export interface IOAuthMgmntState {
  oauthApps: IOAuthApp[];
  oauthAppCredentials: IOAuthAppCredential[];
  oauthAppDevelopers: IUser[];
  authClients: IAuthClient[];
  currentApp: IOAuthApp;
  currentClient: IAuthClient;
  currentCredential: IOAuthAppCredential;
  currentPrivacyPolicyAttestation: IAppPrivacyPolicyAttestation;
  loading: string;
  error?: string | null;
}

const initialState: IOAuthMgmntState = {
  oauthApps: [],
  oauthAppCredentials: [],
  oauthAppDevelopers: [],
  authClients: [],
  currentApp: {},
  currentClient: {},
  currentCredential: { application_id: 0, auth_client_id: 0 },
  currentPrivacyPolicyAttestation: {
    attest_publicly_available: false,
    attest_includes_minimum_information: false,
  },
  loading: 'idle',
  error: null,
};

const oauthMngmtSlice = createSlice({
  name: 'OAuthMngmt',
  initialState: initialState,
  reducers: {},
  // reducers: authReducers,
  extraReducers: (builder) => {
    builder.addCase(oauthMngmtActions.getOAuthApps.fulfilled, (state, { payload }) => {
      state.oauthApps = payload;
      state.loading = 'idle';
    });
    builder.addCase(oauthMngmtActions.getOAuthApps.pending, (state, { payload }) => {
      state.loading = 'pending';
    });
    builder.addCase(oauthMngmtActions.getOAuthApps.rejected, (state, { payload }) => {
      state.loading = 'idle';
    });

    builder.addCase(oauthMngmtActions.getOAuthApp.fulfilled, (state, { payload }) => {
      state.currentApp = payload;
      state.loading = 'idle';
    });
    builder.addCase(oauthMngmtActions.getOAuthApp.pending, (state, { payload }) => {
      state.loading = 'pending';
    });
    builder.addCase(oauthMngmtActions.getOAuthApp.rejected, (state, { payload }) => {
      state.loading = 'idle';
    });

    builder.addCase(oauthMngmtActions.getOAuthAppCredentials.fulfilled, (state, { payload }) => {
      state.oauthAppCredentials = payload;
      state.loading = 'idle';
    });
    builder.addCase(oauthMngmtActions.getOAuthAppCredentials.pending, (state, { payload }) => {
      state.loading = 'pending';
    });
    builder.addCase(oauthMngmtActions.getOAuthAppCredentials.rejected, (state, { payload }) => {
      state.loading = 'idle';
    });

    builder.addCase(oauthMngmtActions.getOAuthAppDevelopers.fulfilled, (state, { payload }) => {
      state.oauthAppDevelopers = payload;
      state.loading = 'idle';
    });
    builder.addCase(oauthMngmtActions.getOAuthAppDevelopers.pending, (state, { payload }) => {
      state.loading = 'pending';
    });
    builder.addCase(oauthMngmtActions.getOAuthAppDevelopers.rejected, (state, { payload }) => {
      state.loading = 'idle';
    });

    builder.addCase(oauthMngmtActions.getOAuthAppCredential.fulfilled, (state, { payload }) => {
      state.currentCredential = payload;
      state.loading = 'idle';
    });
    builder.addCase(oauthMngmtActions.getOAuthAppCredential.pending, (state, { payload }) => {
      state.loading = 'pending';
    });
    builder.addCase(oauthMngmtActions.getOAuthAppCredential.rejected, (state, { payload }) => {
      state.loading = 'idle';
    });

    builder.addCase(oauthMngmtActions.deleteOAuthAppCredential.fulfilled, (state, { payload }) => {
      state.currentCredential = { application_id: -1, auth_client_id: -1 };
      state.currentApp = {};
      state.currentClient = {};
      state.loading = 'idle';
    });
    builder.addCase(oauthMngmtActions.deleteOAuthAppCredential.pending, (state, { payload }) => {
      state.loading = 'pending';
    });
    builder.addCase(oauthMngmtActions.deleteOAuthAppCredential.rejected, (state, { payload }) => {
      state.loading = 'idle';
    });

    builder.addCase(oauthMngmtActions.getAuthClients.fulfilled, (state, { payload }) => {
      state.authClients = payload;
      state.loading = 'idle';
    });
    builder.addCase(oauthMngmtActions.getAuthClients.pending, (state, { payload }) => {
      state.loading = 'pending';
    });
    builder.addCase(oauthMngmtActions.getAuthClients.rejected, (state, { payload }) => {
      state.loading = 'idle';
    });

    builder.addCase(oauthMngmtActions.getAuthClient.fulfilled, (state, { payload }) => {
      state.currentClient = payload;
      state.loading = 'idle';
    });
    builder.addCase(oauthMngmtActions.getAuthClient.pending, (state, { payload }) => {
      state.loading = 'pending';
    });
    builder.addCase(oauthMngmtActions.getAuthClient.rejected, (state, { payload }) => {
      state.loading = 'idle';
    });

    builder.addCase(oauthMngmtActions.getOAuthAppPrivacyPolicyAttestation.fulfilled, (state, { payload }) => {
      state.currentPrivacyPolicyAttestation = payload;
      state.loading = 'idle';
    });
    builder.addCase(oauthMngmtActions.getOAuthAppPrivacyPolicyAttestation.pending, (state, { payload }) => {
      state.loading = 'pending';
    });
    builder.addCase(oauthMngmtActions.getOAuthAppPrivacyPolicyAttestation.rejected, (state, { payload }) => {
      state.loading = 'idle';
    });
    builder.addCase(oauthMngmtActions.getPayerCredential.fulfilled, (state, { payload }) => {
      state.currentCredential = payload;
      state.loading = 'idle';
    });
    builder.addCase(oauthMngmtActions.getPayerCredential.pending, (state, { payload }) => {
      state.loading = 'pending';
    });
    builder.addCase(oauthMngmtActions.getPayerCredential.rejected, (state, { payload }) => {
      state.loading = 'idle';
    });
  },
});

export default oauthMngmtSlice;
