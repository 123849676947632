import { ISubMenuItem } from 'components/controls/SideMenu/interfaces/ISubMenuItem';

interface MonitoringSideMenuBuilderParams {
  active: string;
  history: any;
}

export const monitoringySideMenuBuilder = (params: MonitoringSideMenuBuilderParams): ISubMenuItem[] => {
  return [
    {
      label: `Monitoring Overview`,
      active: params.active === 'monitoring-overview',
      action: () => params.history.push('/monitoring/'),
    },
    {
      label: `Logs`,
      active: params.active === 'logs',
      action: () => params.history.push('/monitoring/logs'),
    },
  ];
};
