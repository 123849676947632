import * as React from 'react';
import Select from 'react-select';
import _ from 'lodash';
import { GENDERS } from 'common/constants/genders';
import { useField } from 'formik';

interface GenderSelectorProps {
  className?: string;
  defaultValue?: string;
  inputValue?: string;
  placeholder?: string;
  onChange?: Function;
  multipleSelect?: boolean;
  name: string;
}

export const GenderSelector: React.FC<GenderSelectorProps> = ({
  onChange,
  className,
  defaultValue,
  inputValue,
  placeholder,
  multipleSelect = false,
  ...componentProps
}) => {
  const [field, meta, helpers] = useField(componentProps.name);
  const formatDefaultValueForControl = (optionsValue: string) => {
    if (optionsValue.length > 0) {
      const options = optionsValue.split(' ');
      return options.map((option) => {
        return {
          value: option,
          label: option,
        };
      });
    }
  };
  const handleScopeChange = (selectedValue) => {
    const newValue = selectedValue.value || '';

    helpers.setValue(newValue);
    if (!!onChange) {
      onChange(newValue);
    }
  };

  return (
    <Select
      classNamePrefix="react-select"
      className={className}
      isMulti={multipleSelect}
      placeholder={placeholder}
      options={GENDERS}
      defaultValue={formatDefaultValueForControl(defaultValue || '')}
      inputValue={inputValue || ''}
      onChange={handleScopeChange}
      {...componentProps}
    />
  );
};
