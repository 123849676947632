import { object, string, ref } from 'yup';
import { IChangePasswordValues } from 'views/AccountSettings/interfaces/IChangePasswordValues';

export const userChangePasswordValidationSchema = object<IChangePasswordValues>({
  currentPassword: string().required('Current Password is Required'),
  newPassword: string().required('New Password is Required'),
  confirmPassword: string()
    .oneOf([ref('newPassword')], 'Passwords do not match')
    .required('Confirm New Password is Required'),
});

export default userChangePasswordValidationSchema;
