import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Card, CardContent } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import {
  client_type_map,
  clientOptions,
  grant_type_map,
  grantOptions,
  credential_type_map,
} from 'views/OAuthManagement/interfaces/IAuthClient';
import { Save, Edit } from '@material-ui/icons';
import Select from 'react-select';
import { Button } from 'components/controls/Button';
import css from 'views/OAuthManagement/OAuthAppDetail/oauth-app-detail.module.scss';
import classes from 'classnames';
import { OAuthScopeSelector } from 'components/controls/OAuthScopeSelector';
import {
  getOAuthApp as getOAuthAppAction,
  getAuthClient as getAuthClientAction,
  updateAuthClient as updateAuthClientAction,
  updateOAuthApp as updateOAuthAppAction,
} from 'views/OAuthManagement/redux/actions';
import { IAppDetailsFormProps } from 'views/OAuthManagement/interfaces/IAppDetailsFormProps';

const AppDetailsForm: React.FC<IAppDetailsFormProps> = ({ currentClient, currentApp, currentCredential }) => {
  const dispatch = useDispatch();
  const [openRequiredEdit, setOpenRequiredEdit] = React.useState(false);
  const [sendError, setSendError] = React.useState('');

  // required values
  const [clientType, setClientType] = React.useState<number>(currentClient.client_type || 0);
  const [grantType, setGrantType] = React.useState<number>(currentClient.grant_type || 0);
  const [appScope, setScope] = React.useState<string>('');
  const [appDocumentationURL, setDocumentationURL] = React.useState<string>(currentApp.documentation_url || '');
  const [appRedirectURIs, setRedirectURIs] = React.useState<string>(currentClient.redirect_uris || '');

  const [clientTypeLabel, setClientTypeLabel] = React.useState<string>(
    client_type_map[currentClient?.client_type || 0],
  );
  const [grantTypeLabel, setGrantTypeLabel] = React.useState<string>(grant_type_map[currentClient?.grant_type || 0]);

  React.useEffect(() => {
    setClientType(currentClient?.client_type || 0);
    setGrantType(currentClient?.grant_type || 0);
    setScope(currentClient.scope || '');
    setRedirectURIs(currentClient?.redirect_uris || '');
    setClientTypeLabel(client_type_map[currentClient?.client_type || 0]);
    setGrantTypeLabel(grant_type_map[currentClient?.grant_type || 0]);
    setDocumentationURL(currentApp.documentation_url || '');
  }, [currentClient, currentApp]);

  const toggleRequiredEdit = () => {
    setOpenRequiredEdit(!openRequiredEdit);
  };

  const handleClientTypeChange = (selectedValue) => {
    setClientType(selectedValue.value);
  };
  const handleGrantTypeChange = (selectedValue) => {
    setGrantType(selectedValue.value);
  };
  const handleScopeChange = (selectedValue) => {
    setScope(selectedValue);
  };

  const handleRequiredSave = () => {
    // save the auth client data
    dispatch(
      updateAuthClientAction({
        ...currentClient,
        client_type: clientType,
        grant_type: grantType,
        scope: appScope,
        redirect_uris: appRedirectURIs,
      }),
    ).then((response) => {
      if (response.error) {
        setSendError(response.error.message);
      } else {
        // reload the auth client
        dispatch(getAuthClientAction(currentCredential.auth_client_id));
        // save the doc url to the application
        dispatch(
          updateOAuthAppAction({
            ...currentApp,
            documentation_url: appDocumentationURL,
          }),
        ).then((response) => {
          if (response.error) {
            setSendError(response.error.message);
          } else {
            dispatch(getOAuthAppAction(currentCredential.application_id)).then((response) => {
              toggleRequiredEdit();
            });
          }
        });
      }
    });
  };

  return (
    <Grid item xs={6} className={css.detailRegion}>
      <Card className={classes(css.detailCard, { [css.focused]: openRequiredEdit })}>
        <CardContent>
          {!!sendError && <Alert severity="error">{sendError}</Alert>}
          {currentCredential.credential_type === credential_type_map['DEV'] && (
            <div className={css.detailActions}>
              {openRequiredEdit ? (
                <>
                  <Button type="link" className={css.cancel} onClick={toggleRequiredEdit}>
                    Cancel
                  </Button>
                  <Button tertiary onClick={handleRequiredSave}>
                    <Save /> Save
                  </Button>
                </>
              ) : (
                <Button type="link" onClick={toggleRequiredEdit}>
                  <Edit className={css.detailsEditLink} />
                </Button>
              )}
            </div>
          )}
          <h3>App Details (Required)</h3>

          <label>OAuth - Client Type</label>
          {openRequiredEdit ? (
            <Select
              classNamePrefix="react-select"
              className={css.miniSelect}
              options={clientOptions}
              placeholder={clientTypeLabel}
              onChange={handleClientTypeChange}
            />
          ) : (
            <div className={css.value}>{clientTypeLabel}</div>
          )}

          <label>OAuth - Grant Type</label>
          {openRequiredEdit ? (
            <Select
              classNamePrefix="react-select"
              className={css.miniSelect}
              options={grantOptions}
              placeholder={grantTypeLabel}
              onChange={handleGrantTypeChange}
            />
          ) : (
            <div className={css.value}>{grantTypeLabel}</div>
          )}

          <label>OAuth - Scope(s)</label>
          {openRequiredEdit ? (
            <OAuthScopeSelector className={css.miniSelect} defaultValue={appScope} onChange={handleScopeChange} />
          ) : (
            <div className={css.value}>{currentClient.scope}</div>
          )}

          <label>Documentation URL</label>
          {openRequiredEdit ? (
            <input
              name="documentation_url"
              type="text"
              value={appDocumentationURL}
              onChange={(e) => setDocumentationURL(e.target.value)}
            />
          ) : (
            <div className={css.value}>{currentApp.documentation_url}</div>
          )}

          <label>Callback URLs/ Redirect URIS</label>
          {openRequiredEdit ? (
            <textarea name="redirect_uris" onChange={(e) => setRedirectURIs(e.target.value)}>
              {appRedirectURIs}
            </textarea>
          ) : (
            <div className={css.value}>{currentClient.redirect_uris}</div>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
};

export default AppDetailsForm;
