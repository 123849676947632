import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_URL } from 'settings';
import { getCurrentAuthHeaders } from 'common/utils/getCurrentAuthHeaders';
import { IUser } from 'common/interfaces/IUser';
import { IOrganization } from 'common/interfaces/IOrganization';

interface ValidationErrors {
  errorMessage: string;
  field_errors: Record<string, string>;
}

interface ICreateOrgUserParams {
  orgId: string | number;
  user: IUser;
}

interface IResendInviteParams {
  orgId?: string | number;
  username: string;
}

export const getUsersByOrgId = createAsyncThunk('Org/org-users', async (orgId?: number | string) => {
  const headers = await getCurrentAuthHeaders('json');
  const response = await fetch(`${API_URL}/org/${orgId}/user`, { method: 'GET', headers });
  return (await response.json()) as IUser[];
});

export const getOrgById = createAsyncThunk('Org/org', async (orgId?: number | string) => {
  const headers = await getCurrentAuthHeaders('json');
  const response = await fetch(`${API_URL}/org/${orgId}`, { method: 'GET', headers });
  return (await response.json()) as IOrganization;
});

export const completeCurrentOrg = createAsyncThunk('Org/complete-org', async () => {
  try {
    const headers = await getCurrentAuthHeaders('json');
    const request = {
      method: 'POST',
      headers,
    };
    const response = await fetch(`${API_URL}/org/complete`, request);
  } catch (error) {
    throw error;
  }
});

export const createOrgUser = createAsyncThunk<IUser, ICreateOrgUserParams>('Org/new-user', async ({ orgId, user }) => {
  try {
    const headers = await getCurrentAuthHeaders('json');
    const request = {
      method: 'POST',
      headers,
      body: JSON.stringify({ user_data: { ...user } }),
    };
    const response = await fetch(`${API_URL}/org/${orgId}/user/create`, request);
    const newUser = await response.json();
    if (typeof newUser === 'object') {
      return newUser as IUser;
    } else {
      if (newUser.indexOf('UsernameExistsException') != -1) {
        throw {
          code: 'UsernameExistsException',
        };
      } else {
        throw {
          code: newUser,
        };
      }
    }
  } catch (ex) {
    if ('code' in ex) {
      throw new Error(ex.code);
    } else {
      throw ex;
    }
  }
});

export const resendInvite = createAsyncThunk<boolean, IResendInviteParams>(
  'Auth/resend-invite',
  async ({ orgId, username }) => {
    const headers = await getCurrentAuthHeaders('json');
    const request = {
      method: 'POST',
      headers,
    };
    const response = await fetch(`${API_URL}/org/${orgId}/user/${username}/resend_invite`, request);
    return (await response.json()) as boolean;
  },
);
